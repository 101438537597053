import React from "react";

import {  interiorclean } from "../../assets";

import TestimonialCarousel from "../Testimonials";
import Footer from "../../Components/Footer";
import Benefits from "../Benefits";


import CleaningServiceModal from "./CleaningServicesModel";

import FaqServices from "../../Components/FaqServices";
import Description from "../../Components/Description";



const InteriorCar = () => {

    const faqData = [
        {
          question: 'What is included in an interior car cleaning service?',
          answer: "Our interior car cleaning service includes thorough cleaning of the car's interior surfaces, such as seats, dashboard, door panels, and windows. We also vacuum and sanitize the interior for a fresh and hygienic environment.",
        },
        {
          question: 'Can interior car cleaning remove odors from my car?',
          answer: 'Yes, our interior car cleaning process includes the removal of odors. We use deodorizers and cleaning agents to eliminate unwanted smells and leave your car smelling fresh.',
        },
        {
          question: 'Do you clean leather and fabric seats during interior car cleaning?',
          answer: 'Absolutely! Our interior car cleaning covers both leather and fabric seats. We use appropriate cleaners and conditioners to rejuvenate and protect the seats.',
        },
        {
          question: 'Is the cleaning process safe for electronic components inside the car?',
          answer: 'Yes, our cleaning process is safe for electronic components. We take precautions to ensure that electronic systems, including audio systems and navigation consoles, are not damaged during the cleaning process.',
        },
        {
          question: 'How often should I schedule interior car cleaning?',
          answer: 'The frequency of interior car cleaning depends on factors such as usage, spills, and personal preference. For a well-maintained interior, scheduling a cleaning every 3 to 6 months is recommended.',
        },
        {
          question: 'Do you offer interior car cleaning services for commercial vehicles and fleets?',
          answer: 'Yes, we provide interior car cleaning services for various types of commercial vehicles and fleets, including cars, vans, trucks, and buses. Our services can be customized to meet the specific needs of businesses with large vehicle fleets.',
        },
        {
          question: 'Can interior car cleaning help improve air quality inside the vehicle?',
          answer: 'Absolutely! By removing dust, dirt, and allergens from the interior surfaces of your car, our cleaning process can contribute to better air quality inside the vehicle. This can be especially beneficial for individuals with respiratory issues or allergies.',
        },
        {
          question: 'Do you offer stain removal services for interior car upholstery?',
          answer: 'Yes, we specialize in stain removal for interior car upholstery, including fabric and leather seats. Our cleaning technicians use professional-grade stain removers and techniques to effectively treat and remove stains from car upholstery surfaces.',
        },
        {
          question: 'Can interior car cleaning help extend the lifespan of my vehicle?',
          answer: 'Yes, regular interior car cleaning can help extend the lifespan of your vehicle by maintaining the cleanliness and condition of interior components. By removing dirt, debris, and contaminants, interior cleaning can prevent premature wear and deterioration of upholstery, carpets, and other surfaces.',
        },
        {
          question: 'Do you offer interior car cleaning packages for detailing enthusiasts?',
          answer: 'Yes, we offer specialized interior car cleaning packages for detailing enthusiasts who want to go beyond basic cleaning and restoration. Our detailing packages include additional services such as leather conditioning, fabric protection, and interior trim restoration to enhance the appearance and comfort of your vehicle.',
      },
      ];
      
  const data = {
    headingFirst: "Interior Car",
    headingSecond: "Interior Car Cleaning",
    price: "₹4999",
    descriptionMeta: "Treat your car to a makeover with our Interior Car Cleaning Service. From upholstery to dashboard, we'll refresh every surface, leaving your vehicle looking and feeling brand new. Schedule your clean today!",
  description: [
    "Thorough cleaning and vacuuming of your car's interior to remove dust, dirt, and debris",
    "Deep cleaning of seats, carpets, and floor mats to eliminate stains and odors",
    "Detailed cleaning of windows, mirrors, and dashboard for a spotless and clear view",
    "Sanitization of high-touch areas, including the steering wheel, gear shift, and door handles",
    "Dusting and cleaning of electronic components, such as the audio system and navigation console",
    "De-greasing and cleaning of vents and air ducts for fresh and clean air circulation",
    "Conditioning and treatment of leather surfaces to maintain a supple and luxurious feel",
    "Careful cleaning of delicate interior features, including buttons, knobs, and switches",
    "Removal of stubborn stains and spills from cup holders, storage compartments, and upholstery",
    "Freshening up the car's interior with a pleasant and long-lasting fragrance",
    "Personalized interior detailing services to meet your specific needs and preferences",
      ],
    image: interiorclean,
    space: "car space",
    size: "car",
    
  
  }

  const descriptionDataInteriorCarCleaning = {
    headingDescription_1: "Why Interior Car Cleaning Service is Essential",
    paraDescription_1: "Maintaining the interior cleanliness of your car is essential not only for comfort but also for ensuring a healthy and pleasant driving experience. Over time, dirt, dust, food particles, and other contaminants can accumulate on surfaces, leading to unpleasant odors, stains, and potential health hazards. Neglecting proper interior cleaning can also contribute to the deterioration of upholstery, carpets, and other materials, affecting the overall aesthetics and resale value of your vehicle. Professional interior car cleaning service plays a crucial role in removing dirt, allergens, and bacteria from every nook and cranny of your car's interior, leaving it fresh, sanitized, and inviting. With specialized cleaning techniques and high-quality products, our service effectively removes stains, odors, and germs, restoring the interior to its pristine condition and enhancing your driving experience. Investing in professional interior car cleaning service is not just about appearance; it's about creating a clean and healthy environment for you and your passengers.",
    headingDescription_2: "What We Do in Interior Car Cleaning Service",
    paraDescription_2: [
        "Thorough vacuuming of carpets, floor mats, and upholstery to remove dirt, dust, and debris.",
        "Cleaning and conditioning of leather, vinyl, and fabric surfaces to restore their appearance and texture.",
        "Spot treatment and stain removal to eliminate stubborn stains and spills from seats and carpets.",
        "Cleaning and disinfecting of dashboard, door panels, and center console to remove dirt, grime, and bacteria.",
        "Detailing of air vents, cup holders, and other hard-to-reach areas to ensure thorough cleanliness.",
        "Odor elimination treatments to neutralize unpleasant smells and leave the interior smelling fresh.",
        "Steam cleaning of upholstery and carpets to deep-clean and sanitize surfaces.",
        "Application of protectants and sealants to preserve and enhance the appearance of interior surfaces.",
        "Final inspection to ensure all areas are cleaned to high standards and customer satisfaction.",
        "Customized add-on services available upon request to address specific cleaning needs or concerns."
    ]
}

  
 
  return (
    <div className="">
     <CleaningServiceModal {...data} />
     <FaqServices faqData={faqData} />
     <Description {...descriptionDataInteriorCarCleaning} />
     <Benefits />
     <TestimonialCarousel />
     <Footer />
    </div>
  );
};

export default InteriorCar;
