import React, { useState } from 'react';
import { useAsyncValue } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import {

    FaAngleDown,
  
  } from "react-icons/fa";
function RequestFormFlat({
    headingFirst,
    options,
}) {


    const flatOptions = [
        "1 BHK",
        "2 BHK",
        "3 BHK",
        "4 BHK+",
        
        "Bungalow",
      ];

      const flatRates = {
        "1 BHK": "3999",
        "2 BHK": "4999",
        "3 BHK": "5499",
        "4 BHK+": "On inspection",
        "2000-3000 sq. ft. Villa": "On inspection",
        "4000-6000 sq. ft. Villa": "On inspection",
        "6000-7000 sq. ft. Villa": "On inspection",
        "Bungalow": "On inspection",
      };

      const handleOptionChange = (e) => {
        const selectedOption = e.target.value;
        setSelectedFlat(selectedOption);
        setRate(flatRates[selectedOption]);
        setState({
          ...state,
          selectedFlat: selectedOption, // Update selectedFlat in the state object
        });
      };
      
  const formArray = [1, 2];
  const [rate, setRate] = useState("3999");
  const [selectedFlat, setSelectedFlat] = useState(flatOptions[0]);
  const [formNo, setFormNo] = useState(formArray[0])
  const [state, setState] = useState({
    name: '',
   email: '',
    mobile: '',
    selectedFlat: `${flatOptions[0]}`,
    location: '',
    service: `${headingFirst} Service`,
    message: '',
   
  })
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [mobileError, setMobileError] = useState('');

  const inputHandle = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    let isValid = true;

    // Name validation
    if (state.name.length < 3) {
      setNameError('Name must be at least 3 characters long');
      isValid = false;
    } else {
      setNameError('');
    }

    // Email validation
    if (!state.email.includes('@') || state.email.length < 5) {
      setEmailError('Please enter a valid email address');
      isValid = false;
    } else {
      setEmailError('');
    }

    // Mobile number validation
    if (!/^\d+$/.test(state.mobile) || state.mobile.length < 10 || state.mobile.length > 12) {
      setMobileError('Please enter a valid mobile number ');
      isValid = false;
    } else {
      setMobileError('');
    }

    return isValid;
  };

  const next = () => {
    if (formNo === 1 && validateForm()) {
      setFormNo(formNo + 1);
    } else {
      toast.error('Please fill up all input fields correctly');
    }
  };

  const pre = () => {
    setFormNo(formNo - 1);
  };
  const baseUrl = "https://api.kbshomeservice.com";
  const navigate = useNavigate();


  const finalSubmit = async () => {
    if (state.name &&state.selectedFlat && state.email  && state.message && state.location && state.service && state.mobile) {
        navigate('/responsepage')
      try {
        const response = await fetch(`${baseUrl}/email/sendEmail`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(state),
        });
        

        if (response.ok) {
          toast.success('Form submitted successfully');
        } else {
          toast.error('Failed to submit form');
        }
      } catch (error) {
        console.error('Error:', error);
       
      }
    } else {
      toast.error('Please fill up all input fields');
    }
  };
  return (
    <div className="mt-8   rounded-xl bg-gradient-to-br   text-white from-secondary to-black via-secondary mx-[30px] sm:mx-[15%] md:mx-0 relative shadow-xl  w-[300px] xs:w-[350px] md:w-[400px] xl:w-[450px] lg:ml-8 sm:w-[550px] px-[20px] justify-center items-center  pt-5 ">
      <ToastContainer />
      <div className="card font-poppins flex flex-col items-center justify-center  gap-3  px-2 py-5">
      <div className="font-semibold font-poppins w-full text-lg  text-center ">
            Fill the form for free callback
          </div>
        <div className='flex justify-center items-center'>
          {
            formArray.map((v, i) => <><div className={`w-[35px] my-3 text-white rounded-full ${formNo - 1 === i || formNo - 1 === i + 1 || formNo === formArray.length ? 'bg-orange-600' : 'bg-slate-400'} h-[35px] flex justify-center items-center`}>
              {v}
            </div>
              {
                i !== formArray.length - 1 && <div className={`w-[85px] h-[2px] ${formNo === i + 2 || formNo === formArray.length ? 'bg-orange-600' : 'bg-slate-400'}`}></div>
              }
            </>)
          }
        </div>
        {
          formNo === 1 && <div className=' flex flex-col gap-3'>
           
            <div className='flex flex-col  mb-2'>
            <div className='flex flex-row gap-2'>
             
              <input value={state.name} onChange={inputHandle}  className="bg-gray-100 h-5 py-5  rounded-md xl:w-[260px] sm:w-[231px]  xs:w-[180px] w-[150px]  shadow-slate-300 shadow-inner px-2 text-black" type="text" name='name' placeholder='Name' id='name' />
              <div className="font-poppins">
                  <div className="flex flex-row relative justify-center items-start">
                    <select
                      className="block appearance-none font-poppins rounded-md text-[15px] bg-gray-100  shadow-slate-300 shadow-inner w-[90px] xs:w-[130px] px-3 h-10  text-black  leading-tight focus:outline-none focus:ring"
                      onChange={handleOptionChange}
                      value={selectedFlat}
                    >
                      <option value="" disabled>
                        {options ? "Select Office Size" : "Flat Option"}
                      </option>
                      {options
                        ? options.map((size, index) => (
                          <option key={index} value={size}>
                            {size}
                          </option>
                        ))
                        : flatOptions.map((flat, index) => (
                          <option key={index} value={flat} >
                            {flat}
                          </option>
                        ))}
                    </select>
                    <div className="pointer-events-none absolute hidden xs:flex inset-y-0 right-4  items-center px-1 text-black">
                      <FaAngleDown />
                    </div>
                  </div>
                </div>
              </div>
              {nameError && <p className="text-white text-xs mt-1">{nameError}</p>}
            </div>
            <div className='flex flex-col mb-2'>
             
              <input value={state.email} onChange={inputHandle} className='bg-gray-100 h-5 py-5  rounded-md xl:w-[400px] sm:w-[370px]  xs:w-[320px]  w-[250px]  shadow-slate-300 shadow-inner px-2 text-black' type="email" name='email' placeholder='Email' id='email' />
              {emailError && <p className="text-white text-xs mt-1">{emailError}</p>}
            </div>
            <div className='flex flex-col mb-2'>
              
              <input value={state.mobile} onChange={inputHandle} className='bg-gray-100 h-5 py-5  rounded-md xl:w-[400px] sm:w-[370px]  xs:w-[320px]  w-[250px]  shadow-slate-300 shadow-inner px-2 text-black' type="tel" name='mobile' placeholder='Mobile Number' />
              {mobileError && <p className="text-white text-xs mt-1">{mobileError}</p>}
            </div>
            <div className='mt-4 flex justify-center items-center'>
              <button onClick={next} className='px-3 py-2 text-lg rounded-md w-full text-white bg-orange-600'>Next</button>
            </div>
          </div>
        }

        {
          formNo === 2 && <div>
            <div className='flex flex-col my-2'>
              
              <input value={state.location} onChange={inputHandle} className='bg-gray-100 h-5 py-5  rounded-md xl:w-[400px] sm:w-[370px]  xs:w-[320px]  w-[250px]  shadow-slate-300 shadow-inner px-2 text-black' type="text" name='location' placeholder='Location' id='location' />
            </div>
            <div className='flex flex-col my-2'>
             
              <input value={state.service} onChange={inputHandle} className='bg-gray-100 h-5 py-5  rounded-md xl:w-[400px] sm:w-[370px]  xs:w-[320px]  w-[250px]  shadow-slate-300 shadow-inner px-2 text-black' type="text" name='service' placeholder={`${headingFirst} Service`} readOnly id='service' />
            </div>
            <div className='flex flex-col my-2'>
              
              <textarea value={state.message} onChange={inputHandle} row={5}  className="bg-gray-100 pb-10 pt-2 rounded-md   xl:w-[400px] sm:w-[370px]  xs:w-[320px]   w-[250px] shadow-slate-300	 shadow-inner px-2 text-black" type="text" name='message' placeholder='Enter your Message ' ></textarea>
            </div>
            <div className='mt-4 gap-3 flex justify-center items-center'>
              <button onClick={pre} className='px-3 py-2 text-lg rounded-md w-full text-white bg-orange-600'>Previous</button>
              <button onClick={finalSubmit} className='px-3 py-2 text-lg rounded-md w-full text-white bg-orange-600'>Submit</button>
            </div>
          </div>
        }

       

      </div>
    </div>
  );
}

export default RequestFormFlat;