import React from 'react'

import {  terraceproof } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';

import WaterproofModal from './WaterproofModal';
import FaqServices from '../../Components/FaqServices';
import Description from '../../Components/Description';

const TerraceProofing = () => {
  const data = {
    headingFirst: " Terrace Proofing",
    headingSecond: " Terrace Proofing",
    price: "₹6999",
    descriptionModal: "Shield your terrace from moisture and weather damage with our effective Terrace Proofing Service. Our treatments will create a protective barrier, keeping your terrace sturdy and leak-free. Enhance durability—schedule your terrace proofing service today!",
    description: [
      "Surface inspection for cracks and damages",
      "Repair of existing cracks and damages",
      "Application of waterproofing coatings",
      "Sealing of joints and gaps",
      "Installation of drainage systems if necessary",
      "Protection against UV radiation",
      "Ensuring proper slope for water runoff",
      "Prevention of water leakage into the structure",
      "Cleaning and removal of debris from the terrace",
      "Application of anti-fungal and anti-algae treatments",
    ],
    image: terraceproof,
    space: "living space",
    size: "terrace",
    
  
  }
  const terraceProofingFAQ = [
    {
      question: 'What is terrace proofing, and why is it necessary?',
      answer: 'Terrace proofing is a process that involves applying waterproofing solutions to protect the terrace from water damage. It is necessary to prevent leaks, dampness, and structural issues.',
    },
    {
      question: 'How often should I consider terrace proofing for my property?',
      answer: 'The frequency of terrace proofing depends on factors such as weather conditions and the age of your terrace. It is generally recommended to conduct terrace proofing every 3-5 years.',
    },
    {
      question: 'Can terrace proofing prevent water seepage and leaks?',
      answer: 'Yes, terrace proofing is designed to create a waterproof barrier, preventing water seepage and leaks into the underlying structure of the building.',
    },
    {
      question: 'What are the signs that my terrace needs proofing?',
      answer: 'Signs that your terrace may need proofing include water stains on the ceiling, damp spots on walls, and visible cracks or damage on the terrace surface.',
    },
    {
      question: 'Do you provide a warranty for your terrace proofing services?',
      answer: 'Yes, we provide a warranty for our terrace proofing services to ensure the effectiveness and durability of the waterproofing treatment. The warranty period may vary.',
    },
    {
      question: 'What is terrace proofing, and why is it necessary?',
      answer: 'Terrace proofing is a process that involves applying waterproofing solutions to protect the terrace from water damage. It is necessary to prevent leaks, dampness, and structural issues.',
    },
    {
      question: 'How often should I consider terrace proofing for my property?',
      answer: 'The frequency of terrace proofing depends on factors such as weather conditions and the age of your terrace. It is generally recommended to conduct terrace proofing every 3-5 years.',
    },
    {
      question: 'Can terrace proofing prevent water seepage and leaks?',
      answer: 'Yes, terrace proofing is designed to create a waterproof barrier, preventing water seepage and leaks into the underlying structure of the building.',
    },
    {
      question: 'What are the signs that my terrace needs proofing?',
      answer: 'Signs that your terrace may need proofing include water stains on the ceiling, damp spots on walls, and visible cracks or damage on the terrace surface.',
    },
    {
      question: 'Do you provide a warranty for your terrace proofing services?',
      answer: 'Yes, we provide a warranty for our terrace proofing services to ensure the effectiveness and durability of the waterproofing treatment. The warranty period may vary.',
    },
  ];
  const terraceProofingDescriptionData = {
    headingDescription_1: "Why Terrace Proofing Service is Essential",
    paraDescription_1: "Your terrace is constantly exposed to harsh weather conditions, including rain, sunlight, and temperature fluctuations, making it susceptible to damage and deterioration. Over time, cracks, leaks, and structural weaknesses can develop, leading to water seepage, leakage, and structural damage. Terrace proofing service is essential to protect your terrace from water ingress and structural issues, ensuring its longevity and durability. By applying advanced waterproofing solutions and sealants, our service creates a seamless barrier against moisture, preventing water damage and preserving the integrity of your terrace. Investing in terrace proofing service is a proactive measure to safeguard your property and maintain its value.",
    headingDescription_2: "What We Do in Terrace Proofing Service",
    paraDescription_2: [
        "Comprehensive inspection of the terrace to identify areas of concern, such as cracks, gaps, and deterioration.",
        "Surface preparation, including cleaning, repairing, and leveling of the terrace substrate.",
        "Application of waterproofing membranes, coatings, and sealants to seal and protect the terrace surface.",
        "Installation of drainage systems and slope correction measures to ensure proper water runoff and prevent pooling.",
        "Reinforcement of vulnerable areas, such as expansion joints and parapet walls, to enhance structural stability.",
        "Final inspection and quality assurance to verify the effectiveness of the terrace proofing treatment.",
        "Customized maintenance plans and periodic inspections available to prolong the lifespan of the terrace and maintain waterproofing integrity.",
        "Utilization of cutting-edge technologies, such as infrared thermography and moisture mapping, for accurate assessment and diagnosis of terrace conditions.",
        "Inclusion of preventative measures, such as crack bridging systems and elastomeric coatings, to mitigate future water intrusion and structural damage.",
        "Collaboration with structural engineers and architects to implement integrated waterproofing solutions that align with building codes and regulations.",
        "Offering eco-friendly waterproofing options, such as sustainable materials and water-based sealants, to minimize environmental impact and promote sustainability.",
        "Customer education and engagement initiatives to raise awareness about terrace maintenance best practices and empower property owners to make informed decisions."
    ]
};

  
   
  return (
    <div className='main'>
       <WaterproofModal {...data} />
       <FaqServices faqData={terraceProofingFAQ} />
       <Description {...terraceProofingDescriptionData} />
       <Benefits />
       <TestimonialCarousel />
       <Footer />
    </div>
  )
}
        

export default TerraceProofing
