import React from 'react'

import {  woodboarer } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';

import PestControlModal from './PestControlModal';
import FaqServices from '../../Components/FaqServices';
import Description from '../../Components/Description';

const Woodboarer = () => {

   
  const data = {
    headingFirst: "Woodboarer Pest",
    headingSecond: "Woodboarer Pest",
    price: "₹3599",
    description: [
      "Comprehensive inspection for termite infestations",
  "Application of specialized termite control treatments",
  "Treatment of wooden structures and vulnerable areas",
  "Soil treatment to create a protective barrier against termites",
  "Identification and elimination of termite nests",
  "Preventive measures to discourage future termite activity",
  "Monitoring and follow-up inspections for long-term effectiveness",
  "Use of environmentally friendly and safe termite control products",
  "Protection of furniture, wooden fixtures, and valuables from termite damage",
  "Expert advice on minimizing conditions conducive to termite infestation",
  "Transparent reporting and documentation of termite control procedures",

    ],
    image: woodboarer,
    space: "living space",
    size: "house",
    descriptionMeta: "Preserve the integrity of your property with our specialized Termite Pest Control Service. Our treatments will eradicate termites and prevent costly damage to your home or business. Don't let termites destroy your investment—schedule your service now! ",
    
  
  }
  const woodBorerPestControlFAQ = [
    {
      question: 'What are the signs of a wood borer infestation?',
      answer: 'Common signs of a wood borer infestation include small exit holes in wood, fine powdery dust near wooden items, and the presence of adult beetles around the affected area.',
    },
    {
      question: 'Is wood borer treatment safe for my furniture and home?',
      answer: 'Yes, our wood borer pest control treatments are safe for furniture and homes. We use approved and safe pesticides that effectively eliminate wood borers without causing harm to humans or pets.',
    },
    {
      question: 'How long does a wood borer treatment take?',
      answer: 'The duration of a wood borer treatment depends on the extent of the infestation. On average, it may take a few hours for the treatment to be completed.',
    },
    {
      question: 'Do I need to vacate my home during the wood borer treatment?',
      answer: 'In most cases, it is not necessary to vacate your home during the wood borer treatment. However, it is recommended to keep children, pets, and sensitive individuals away from the treated area until it is safe.',
    },
    {
      question: 'Is wood borer treatment a one-time process, or do I need regular treatments?',
      answer: 'The need for regular wood borer treatments depends on the severity of the infestation and preventive measures taken. In some cases, a one-time treatment may be sufficient, while others may require periodic treatments.',
    },
    {
      question: 'What types of wood are susceptible to wood borer infestations?',
      answer: 'Wood borers can infest various types of wood, including hardwoods and softwoods. Commonly affected items include furniture, wooden beams, flooring, and structural wood.',
    },
    {
      question: 'Can wood borer infestations cause structural damage to buildings?',
      answer: 'Yes, severe wood borer infestations can weaken wooden structures, leading to structural damage and compromising the integrity of buildings.',
    },
    {
      question: 'Are there any natural remedies to control wood borer infestations?',
      answer: 'While some natural remedies like freezing or heating infested items may help control wood borers to some extent, professional pest control treatment is often necessary for effective eradication.',
    },
    {
      question: 'How do I prevent wood borer infestations in my home?',
      answer: 'To prevent wood borer infestations, ensure proper ventilation, address moisture issues, inspect and treat wooden items regularly, and apply preventive coatings or treatments to vulnerable wood surfaces.',
    },
    {
      question: 'What should I do if I suspect a wood borer infestation?',
      answer: 'If you suspect a wood borer infestation, contact a professional pest control service for a thorough inspection and appropriate treatment. Avoid DIY methods that may exacerbate the problem.',
    },
  ];
  const woodborerDescriptionData = {
    headingDescription_1: "Why Woodborer Pest Control Service is Essential",
    paraDescription_1: "Protecting your wooden furniture and structures from woodborer infestations is crucial for preserving their integrity and longevity. Woodborers are destructive pests that tunnel through wood, causing extensive damage and weakening the structural integrity of your valuable possessions. Left unchecked, woodborer infestations can lead to irreversible harm, resulting in costly repairs or replacements. Professional woodborer pest control service is essential for detecting and eliminating these pests, preventing further damage, and safeguarding your investments. Our specialized treatment methods target woodborers at every stage of their lifecycle, ensuring thorough eradication and long-term protection. Investing in professional woodborer pest control service is a proactive measure to preserve the beauty and durability of your wooden assets for generations to come.",
    headingDescription_2: "What We Do in Woodborer Pest Control Service",
    paraDescription_2: [
        "Comprehensive inspection of wooden structures and furniture to assess the extent of woodborer infestation.",
        "Identification of woodborer species and evaluation of damage to determine the appropriate treatment approach.",
        "Application of targeted insecticides or fumigation techniques to eradicate woodborers and their larvae.",
        "Injection of specialized wood preservatives to protect against future infestations and prevent reinfestation.",
        "Sealing of woodborer entry points and cracks to prevent further access and minimize the risk of future infestations.",
        "Monitoring and follow-up inspections to ensure the effectiveness of the treatment and detect any signs of reinfestation.",
        "Recommendations for preventive measures and maintenance practices to reduce the risk of woodborer infestations in the future.",
        "Educational resources and guidance on identifying early signs of woodborer activity and taking proactive measures to protect wooden assets.",
        "Customized treatment plans tailored to the specific needs and requirements of your property and belongings.",
        "Ongoing support and assistance to address any concerns or questions related to woodborer pest control and prevention."
    ]
};

  
  return (
    <div className='main'>
      <PestControlModal {...data} />
      <FaqServices faqData={woodBorerPestControlFAQ} />
      <Description {...woodborerDescriptionData} />
      <Benefits />
      <TestimonialCarousel />
      <Footer />
      
    </div>
  )
}

export default Woodboarer
