import React from 'react'

import {  exteriorpaint } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';

import PaintModal from './PaintModal';

import FaqServices from '../../Components/FaqServices';
import Description from '../../Components/Description';

const ExteriorPaint = () => {

  const data = {
    headingFirst: " Exterior",
    headingSecond: "Exterior",
    price: "₹3999",
    descriptionMeta: "Boost your property's curb appeal with our reliable Exterior Paint Service. From weather-resistant coatings to vibrant colors, we'll revitalize your exterior surfaces and protect them against the elements. Make a lasting impression—schedule your exterior paint service now!",
    description: [
      "Surface preparation, including filling cracks and holes",
      "Priming of walls and surfaces",
      "Application of multiple coats of paint",
      "Color consultation and selection",
      "Trim and molding painting",
      "Ceiling painting",
      "Door and window frame painting",
      "Repairing and smoothing of surfaces",
      "Protection of furniture and floors during painting",
      "Clean and neat paint job with sharp lines",
      "Cleanup and removal of painting debris",
      "Quality assurance and final inspection",
      "Exterior and interior painting services",
      "Specialized finishes and textures upon request",
      "Professional guidance on paint types and finishes",
      "Timely and efficient completion of the painting project",
  ],
  
    image: exteriorpaint,
    space: "living space",
    size: "house",
    
  
  }
  const exteriorPaintFAQ = [
    {
      question: 'How often should I repaint the exterior of my house?',
      answer: 'The frequency of repainting the exterior of your house depends on factors such as the type of paint used, weather conditions, and the quality of the previous paint job. In general, it is recommended to repaint every 5 to 10 years.',
    },
    {
      question: 'Can you match the existing color of my house during exterior painting?',
      answer: 'Yes, our professional painters can match the existing color of your house or help you choose a new color that complements your preferences and the surrounding environment.',
    },
    {
      question: 'Do you provide pressure washing services before exterior painting?',
      answer: 'Yes, we offer pressure washing services to thoroughly clean the exterior surfaces before painting. This ensures proper adhesion of the paint and a longer-lasting finish.',
    },
    {
      question: 'What type of paint do you use for exterior painting?',
      answer: 'We use high-quality exterior paints that are specifically designed to withstand various weather conditions, resist fading, and provide long-lasting protection for your home.',
    },
    {
      question: 'How long does it take to complete an exterior paint job?',
      answer: 'The duration of an exterior paint job depends on factors like the size of the house, the complexity of the project, and weather conditions. On average, it may take a few days to a week.',
    },
    {
      question: 'How often should I clean my gutters?',
      answer: 'The frequency of gutter cleaning depends on factors such as surrounding trees, weather conditions, and the presence of debris. It is generally recommended to clean gutters at least twice a year, typically in the spring and fall.',
    },
    {
      question: 'Can you repair damaged gutters during cleaning?',
      answer: 'Yes, our gutter cleaning service includes inspection for damage, and we can perform minor repairs such as sealing leaks or reattaching loose sections. For significant damage, we may recommend additional repair or replacement.',
    },
    {
      question: 'Do you remove all debris from the gutters during cleaning?',
      answer: 'Yes, our gutter cleaning process involves removing all debris, including leaves, twigs, and sediment, to ensure proper water flow and prevent clogs. We use specialized tools and techniques for thorough cleaning.',
    },
    {
      question: 'Is gutter cleaning necessary if I have gutter guards installed?',
      answer: 'While gutter guards can help reduce the amount of debris that enters your gutters, they are not foolproof. Periodic gutter cleaning is still necessary to remove any debris that accumulates on top of or around the guards.',
    },
    {
      question: 'How long does it take to clean gutters?',
      answer: 'The time required to clean gutters varies depending on factors such as the size of the house, the extent of debris buildup, and accessibility. On average, gutter cleaning can take a few hours to half a day.',
    },
  ];
  const descriptionData = {
    headingDescription_1: "Why Exterior Painting Service is Essential",
    paraDescription_1: "The exterior of your home is not only the first thing visitors notice but also serves as a protective barrier against the elements. Over time, exposure to sunlight, rain, wind, and temperature fluctuations can cause paint to fade, peel, and deteriorate, compromising both the aesthetics and structural integrity of your property. Neglecting exterior maintenance can lead to costly repairs and decrease property value. Professional exterior painting service is essential for revitalizing your home's appearance, enhancing curb appeal, and safeguarding it from weather-related damage. Our expert painters utilize high-quality materials and advanced techniques to ensure durable, long-lasting results that withstand the test of time. Investing in professional exterior painting is an investment in the beauty, value, and longevity of your home.",
    headingDescription_2: "What We Do in Exterior Painting Service",
    paraDescription_2: [
        "Comprehensive surface preparation, including power washing and scraping to remove dirt, mildew, and loose paint.",
        "Repair and patching of damaged areas, such as cracks, holes, and wood rot, to ensure a smooth and even surface.",
        "Priming of surfaces to promote adhesion and provide a uniform base for paint application.",
        "Selection of high-quality exterior paint in the desired color and finish to complement your home's architecture and style.",
        "Professional application of paint using brushes, rollers, or sprayers for optimal coverage and finish.",
        "Multiple coats of paint for enhanced durability and longevity, with attention to detail on trim, siding, and accents.",
        "Protection of surrounding areas, including landscaping, windows, and fixtures, from paint splatter and damage.",
        "Cleanup of work areas to ensure a tidy and safe environment throughout the painting process.",
        "Final inspection and walkthrough to address any touch-ups or concerns and ensure customer satisfaction.",
        "Customized services available, such as deck staining or waterproofing, to meet specific needs and preferences."
    ]
}

  

  
  return (
    <div className='main'>
       <PaintModal {...data} />
       <FaqServices faqData={exteriorPaintFAQ} />
       <Description {...descriptionData} />
       <Benefits />
       <TestimonialCarousel />
       <Footer />
    </div>
  )
}

export default ExteriorPaint
