import React from 'react'

import {  corporate } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';

import DecorationModal from './DecorationModal';
import FaqServices from '../../Components/FaqServices';
import Description from '../../Components/Description';

const CorporateDecoration = () => {

  const data = {
    headingFirst: "Corporate Party",
    headingSecond: "Corporate Party",
    price: "₹7999",
    description: [
      "Professional corporate decor and design services",
  "Tailored decoration plans to align with brand identity and corporate culture",
  "Selection and placement of furniture, artworks, and decor elements",
  "Incorporation of corporate branding and colors in the decor scheme",
  "Space optimization for enhanced functionality and productivity",
  "Use of high-quality and durable materials for long-lasting aesthetics",
  "Integration of modern and ergonomic furniture for employee comfort",
  "Customized lighting solutions to create a conducive work environment",
  "Green and sustainable decor options for eco-friendly corporate spaces",
  "Collaboration with interior designers and architects for cohesive designs",
  "Thorough project management ensuring timely and efficient execution",
    ],
    image: corporate,
    space: "party space",
    size: "compound",
    descriptionMeta: "Impress your guests and colleagues with our sophisticated Corporate Party Decoration Service. From sleek and modern to elegant and professional, we'll design a decor that reflects your brand and vision. Make your corporate event a success—contact us for custom decoration solutions!",
    
  
  }
  const corporatePartyDecorationFAQ = [
    {
      question: 'What does the corporate party decoration service include?',
      answer: 'Our corporate party decoration service includes a comprehensive decoration setup tailored to your corporate event theme. This may include table arrangements, backdrop designs, lighting, and other decor elements.',
    },
    {
      question: 'Can you customize the decorations based on our company colors and branding?',
      answer: 'Yes, we can customize the decorations to match your company colors and branding, creating a cohesive and branded look for your corporate event.',
    },
    {
      question: 'How far in advance should I book the corporate party decoration service?',
      answer: 'It is advisable to book the corporate party decoration service well in advance to ensure availability. Booking at least 4-6 weeks before the event is recommended.',
    },
    {
      question: 'Do you provide on-site supervision during the event for the decoration setup?',
      answer: 'Yes, our team will provide on-site supervision to ensure the smooth setup of decorations and address any last-minute adjustments or requirements during the event.',
    },
    {
      question: 'Are there additional charges for removing decorations after the event?',
      answer: 'The removal of decorations is typically included in the service package. However, if there are specific teardown requirements or additional cleanup needed, it may incur extra charges.',
    },
    {
      question: 'Can you accommodate large corporate events with extensive decoration needs?',
      answer: 'Yes, we have experience in handling large corporate events with extensive decoration requirements. Our team is equipped to scale up the decoration setup to suit the size and scope of your event.',
    },
    {
      question: 'Do you offer themed decoration options for corporate parties?',
      answer: "Absolutely! We offer themed decoration options for corporate parties to align with the event's theme or purpose. Whether it's a holiday-themed party, a product launch, or a milestone celebration, we can create themed decorations to enhance the atmosphere.",
    },
    {
      question: 'Can you provide customized signage or branding elements as part of the decoration?',
      answer: "Yes, we can incorporate customized signage, logos, and branding elements into the decoration setup to reinforce your company's identity and messaging during the corporate event.",
    },
    {
      question: 'Are there any restrictions on the venue for setting up corporate party decorations?',
      answer: 'While we strive to accommodate decorations in various venues, there may be certain restrictions or limitations imposed by the venue management. We recommend consulting with us beforehand to ensure a smooth setup process.',
    },
    {
      question: 'Do you offer package options for corporate party decorations?',
      answer: 'Yes, we offer package options for corporate party decorations to suit different budgets and event requirements. Our packages may include basic setups, premium designs, and add-on options for customization.',
    },
  ];
  const descriptionData = {
    headingDescription_1: "Why Corporate Party Decoration Service is Essential",
    paraDescription_1: "Corporate events and parties play a significant role in fostering relationships, boosting morale, and promoting brand image. The ambiance and decor of these events set the tone and leave a lasting impression on attendees. Neglecting proper decoration can undermine the professionalism and impact of the event, potentially affecting guest experience and overall success. Professional corporate party decoration service is essential for creating a memorable and engaging atmosphere that reflects your company's values and enhances the event's purpose. With meticulous attention to detail and creative expertise, our service transforms ordinary spaces into extraordinary settings, elevating the overall experience for guests and stakeholders alike.",
    headingDescription_2: "What We Do in Corporate Party Decoration Service",
    paraDescription_2: [
        "Initial consultation and theme brainstorming to align decor with event objectives and company branding.",
        "Customized decor design and proposal presentation, including mood boards and visual references.",
        "Selection and procurement of high-quality decor elements, including furniture, lighting, linens, and floral arrangements.",
        "Coordination with venue management and vendors to ensure seamless setup and installation.",
        "Creation of captivating focal points, such as stage backdrops, entrance displays, and photo booths.",
        "Installation and arrangement of decor elements according to the approved design plan, ensuring visual coherence and flow.",
        "Integration of branded elements, logos, and messaging to reinforce corporate identity and event branding.",
        "On-site supervision and coordination during the event to oversee decor maintenance and address any last-minute adjustments.",
        "Post-event teardown and cleanup to ensure the venue is returned to its original condition.",
        "Tailored add-on services available, such as entertainment booking, AV rental, and custom signage, to enhance the overall event experience."
    ]
}

  
  return (
    <div className='main'>
      <DecorationModal {...data} />
       <FaqServices faqData={corporatePartyDecorationFAQ} />
       <Description {...descriptionData} />
      <Benefits />
      <TestimonialCarousel />
      <Footer />
    </div>
  )
}

export default CorporateDecoration
