import React from 'react'

import {  mosquito } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';

import PestControlModal from './PestControlModal';

import FaqServices from '../../Components/FaqServices';
import Description from '../../Components/Description';

const MosquitoControl = () => {
  const data = {
    headingFirst: "Mosquito Pest",
    headingSecond: "Mosquito Pest",
    price: "₹3999",
    description: [
      "Comprehensive mosquito pest control service",
      "Identification and targeted treatment of mosquito breeding sites",
      "Application of safe and effective mosquito repellents",
      "Indoor and outdoor mosquito treatment for complete coverage",
      "Use of eco-friendly and non-toxic mosquito control products",
      "Fogging and misting to eliminate adult mosquitoes",
      "Regular inspections to assess and address mosquito infestations",
      "Customized solutions based on the specific needs of the premises",
      "Advice on preventive measures to reduce mosquito breeding areas",
      "Safe and thorough treatment in living spaces, bedrooms, and common areas",
      "Post-treatment follow-up to ensure long-lasting mosquito control",
    ],
    image: mosquito,
    space: "living space",
    size: "house",
    descriptionMeta: "Enjoy your outdoor spaces again with our reliable Mosquito Pest Control Service. We'll reduce mosquito populations and provide long-lasting relief from these pesky insects. Take back your yard—schedule your service today! ",
    
  
  }

  const mosquitoPestControlFAQ = [
    {
      question: 'How does mosquito pest control work?',
      answer: 'Mosquito pest control typically involves the application of insecticides or repellents to target mosquito breeding areas and resting spots. This helps reduce mosquito populations in and around your property.',
    },
    {
      question: 'Is mosquito pest control safe for humans and pets?',
      answer: 'Yes, the mosquito pest control products we use are safe for humans and pets when applied by trained professionals. We follow industry standards to ensure the safety of your family and pets.',
    },
    {
      question: 'How long does the effect of mosquito pest control last?',
      answer: 'The duration of mosquito pest control effectiveness varies. Factors such as weather conditions, mosquito species, and the treatment method can impact the longevity of the control. Regular treatments may be needed for ongoing protection.',
    },
    {
      question: 'When is the best time to schedule mosquito pest control?',
      answer: 'The best time to schedule mosquito pest control is during the warmer months when mosquitoes are most active. It is advisable to plan treatments in advance to prevent mosquito infestations.',
    },
    {
      question: 'Do you offer preventive mosquito pest control services?',
      answer: 'Yes, we provide preventive mosquito pest control services to help minimize the risk of mosquito-borne diseases and create a more comfortable outdoor environment for you and your family.',
    },
    {
      question: 'Can mosquito pest control also target other insects?',
      answer: 'While mosquito pest control primarily targets mosquitoes, some treatments may also have effects on other flying insects. However, specific insect control may require different approaches.',
    },
    {
      question: 'Do I need to be present during mosquito pest control treatment?',
      answer: 'In most cases, you do not need to be present during mosquito pest control treatment. Our technicians can perform the treatment while ensuring safety and effectiveness.',
    },
    {
      question: 'How soon after treatment can I use my outdoor space?',
      answer: "After mosquito pest control treatment, it's typically safe to use your outdoor space once any applied products have dried. This usually takes a few hours, but our technicians will provide specific guidance.",
    },
    {
      question: 'Are there any natural alternatives to chemical mosquito pest control?',
      answer: 'Yes, there are natural alternatives to chemical mosquito pest control, such as planting mosquito-repelling plants, using mosquito traps, and employing natural repellents like citronella oil.',
    },
    {
      question: 'Can mosquito pest control help reduce the risk of mosquito-borne diseases?',
      answer: 'Yes, effective mosquito pest control can help reduce the population of disease-carrying mosquitoes, thus lowering the risk of mosquito-borne diseases such as West Nile virus, Zika virus, and dengue fever.',
    },
  ];
  const descriptionData = {
    headingDescription_1: "Why Mosquito Pest Control Service is Essential",
    paraDescription_1: "Protecting your home and family from mosquitoes is crucial not only for comfort but also for health and safety. Mosquitoes are not just annoying pests; they also pose significant health risks by transmitting diseases such as malaria, dengue fever, Zika virus, and West Nile virus. With their ability to breed rapidly in stagnant water and thrive in warm temperatures, mosquitoes can quickly become a nuisance and a threat to your well-being. Neglecting proper mosquito control measures can lead to increased mosquito populations, higher risk of disease transmission, and potential health hazards for you and your loved ones. Professional mosquito pest control service plays a vital role in eliminating mosquito breeding grounds, reducing mosquito populations, and protecting your home from infestation. With targeted treatments and preventive measures, our service effectively controls mosquito populations, providing you with peace of mind and a safer living environment.",
    headingDescription_2: "What We Do in Mosquito Pest Control Service",
    paraDescription_2: [
        "Thorough inspection of the property to identify mosquito breeding sites and high-risk areas.",
        "Application of larvicides to standing water sources, such as ponds, birdbaths, and gutters, to prevent mosquito breeding.",
        "Spraying of insecticides in outdoor areas, including yards, gardens, and patio areas, to kill adult mosquitoes and disrupt their breeding cycle.",
        "Installation of mosquito traps and barriers to intercept and capture adult mosquitoes, reducing their population over time.",
        "Treatment of vegetation and foliage where mosquitoes rest and breed, such as bushes, shrubs, and dense vegetation.",
        "Application of residual insecticides to outdoor surfaces, such as walls, fences, and eaves, to create a protective barrier against mosquitoes.",
        "Educational resources and recommendations for homeowners to minimize mosquito breeding sites and prevent future infestations.",
        "Follow-up inspections and treatments to ensure long-term effectiveness and continued protection against mosquitoes.",
        "Customized solutions and ongoing maintenance plans available to address specific needs and concerns.",
        "Regular monitoring and surveillance to detect and address any new mosquito activity or breeding sites.",
    ]
}

  
  return(
  <div>
    <PestControlModal {...data} />
    <FaqServices faqData={mosquitoPestControlFAQ} />
    <Description {...descriptionData} />
    <Benefits />
    <TestimonialCarousel />
    <Footer />
    </div>
  )
  
}

export default MosquitoControl
