import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { quotation, testimonial1, testimonial2, testimonial3, testimonial4, testimonial5, testimonial6 } from "../assets";

const testimonials = [
  {
    id: 1,
    image: testimonial2,
    text: 'I have used a services from Different providers and these were the best so far. They have done a very clean job and was done in a very quick time came in short time cleaned nicely. Boys were polite and gentle',
    heading: "Vaibhav DG",
    duration: "7 months ago",
  },
  {
    id: 2,
    image:testimonial3 ,
    text: 'Exceptional service that truly goes above and beyond expectations. Raju provided undivided attention, and the deep cleaning staff were outstanding. Subscribed to their yearly package for amazing discounts—highly recommend giving them a try',
    heading: "Shwetha",
    duration: "3 months ago",
  },
  {
    id: 3,
    image: testimonial5,
    text: 'Team has done an excellent job. Have used the deep cleaning service and they did amazing work for my 2 BHK. Good work and would always recommend KBS Home Service to my friends and family. Highly Recommended.',
    heading: "Promod",
    duration: "1 week ago",
  },
  {
    id: 4,
    image: testimonial1,
    text: 'Highly effective cockroach and rat treatment in our kitchen and garden area. Surprisingly efficient! Recommended every 3 to 6 months for lasting results. Also opted for kitchen deep cleaning—a great preventive measure for homeowners',
    heading: " Manjula Gowda",
    duration: "5 months ago",
  },
  {
    id: 5,
    image: testimonial4,
    text: 'KBS Service provided excellent deep cleaning for our empty 2BHK flat. Guru and the team were kind, hardworking, and arrived early as requested. Also opted for their regular kitchen and bathroom cleaning service. Thanks, KBS Home Service!',
    heading: " Aradhana R",
    duration: "8 months ago",
  },
  {
    id: 6,
    image: testimonial6,
    text: 'We are using Kbs general pest control for our community since 2 years , I need not to remind them they usually schedule and give a call back before arriving everytime and does their job and leaves.',
    heading: " Mansoor",
    duration: "4 months ago",
  },
];

const TestimonialCarousel = () => {
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //       },
  //     },
  //   ],
  // };
  const settings = {
    dots: true,
    infinite: true,
    autoplay : true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="lg:m-10 m-4 relative flex flex-col text-center lg:mt-10 ">
      <h1 className="font-poppins sm:text-4xl text-2xl sm:font-semibold text-center font-bold sm:mt-4 sm:mb-4 md:mb-8">
        Voices of Trust <br /> What Our Clients Say
      </h1>
      <Slider className="w-auto pb-5" {...settings} arrows={false}>
        {testimonials.map((testimonial) => (
          <div
            key={testimonial.id}
            className="outline-none focus:outline-none w-[300px] mb-3 sm:mb-8 "
          >
            <div className="flex flex-col items-center justify-center  ">
              <div className="m-2 relative  md:w-[300px] lg:w-[350px] h-[350px]  flex flex-col  lg:shadow-xl shadow-inner bg-secondary/5 rounded-2xl p-4">
                <img src={quotation} alt="quotation" className="w-7 h-7 items-start" /> 

                <div className="mt-4 text-black font-poppins text-justify">
                  {testimonial.text}
                </div>

                <div className="bg-slate-300 w-full items-center h-[1px] px-10 mt-5"></div>

                
                  <div className="flex flex-row items-center  mt-4 gap-2">
                  <img src={testimonial.image} alt={`Testimonial ${testimonial.id}`} className="w-10 h-10 object-cover rounded-full" />
                  <div className="flex flex-col justify-start items-start">
                  <p className=" text-black font-poppins font-normal text-xl ">
                    {testimonial.heading}
                  </p>
                  <p className="mt-1 text-slate-600 font-poppins font-normal text-xs ">
                    {testimonial.duration}
                  </p>
                  </div>
                  
                </div>
                <div className="stars ">
                  <ul className="flex flex-row gap-1 mt-2 justify-startitems-start ml-11 ">
                    <li className="text-amber-400 text-md ">
                      <img
                        width="12"
                        height="12"
                        src="https://img.icons8.com/fluency/20/star--v1.png"
                        alt="star--v1"
                      />
                    </li>
                    <li className="text-amber-400 text-lg">
                      <img
                        width="12"
                        height="12"
                        src="https://img.icons8.com/fluency/20/star--v1.png"
                        alt="star--v1"
                      />
                    </li>
                    <li className="text-amber-400 text-lg">
                      <img
                        width="12"
                        height="12"
                        src="https://img.icons8.com/fluency/20/star--v1.png"
                        alt="star--v1"
                      />
                    </li>
                    <li className="text-amber-400 text-lg">
                      <img
                        width="12"
                        height="12"
                        src="https://img.icons8.com/fluency/20/star--v1.png"
                        alt="star--v1"
                      />
                    </li>
                    <li className="text-amber-400 text-lg">
                      <img
                        width="12"
                        height="12"
                        src="https://img.icons8.com/fluency/20/star--v1.png"
                        alt="star--v1"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <style>
      {`
          .slick-dots {
            position: absolute;
            margin-bottom: 10px;
            
            left: 50%;

            transform: translateX(-50%);
          }

          .slick-dots li {
            margin: 0 4px; /* Adjust the horizontal spacing between dots */
          }
        `}
      </style>
    </div>
  );
};

export default TestimonialCarousel;
