import React, { useState, useEffect } from "react";
import styles from "../style";

import { footerLinks, socialMedia } from "../constants";
import { Link, useNavigate, useParams } from "react-router-dom";
// import { MdOutlineLocationOn } from "react-icons/md";

import {
  // cityOptionsObj,
  // serviceOptionsObj,
  cityOptions,
  // serviceOptions,
} from "../constants";

const Footer = () => {
  const city = useParams();
  const service = useParams();
  const navigate = useNavigate();

  let [selectedCity, setSelectedCity] = useState();

  const handleLocationSelection = (selectedLocation) => {
    localStorage.setItem("city", selectedLocation);
    setSelectedCity(selectedLocation);
    navigate(`/${selectedLocation.toLowerCase()}`);
  };
  selectedCity = localStorage.getItem("city");
  // console.log(selectedCity)
  // useEffect(() => {
  //   // !city?.city && localStorage.setItem("city", "Bangalore");
  //   if(cityOptionsObj[city.city]){
  //     "bangalore"
  //   }
  // }, [selectedCity]);

  const redirectUrl_cleaning = `/cleaning-services-in-${selectedCity}`;
  const redirectUrl_pest = `/pest-control-services-in-${selectedCity}`;
  const redirectUrl_paint = `/paint-service-in-${selectedCity}`;
  const redirectUrl_watertank = `/water-tank-and-sump-cleaning-in-${selectedCity}`;
  const redirectUrl_waterproofing = `/water-proofing-service-in-${selectedCity}`;
  const redirectUrl_disinfect = `/disinfection-service-in-${selectedCity}`;
  const redirectUrl_decoration = `/decoration-service-in-${selectedCity}`;
  const redirectUrl_cctv = `/cctv-service-in-${selectedCity}`;
  const redirectUrl_packers = `/packers-and-movers-service-in-${selectedCity}`;
  const redirectUrl_interiodesign = `/interior-designing-service-in-${selectedCity}`;

  const [selectedService, setSelectedService] = useState("");

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <section className={`${styles.flexCenter} flex-col bg-Isblack`}>
      <div className="flex justify-center items-start flex-col mb-4 w-full md:flex-row  '">
        <div className="flex-[1.5] w-full sm:flex flex-row justify-around grid grid-cols-2  gap-5 px-5 flex-wrap pt-8">
          <div className="cityoptions my-3 ss:my-0">
            <h4 className="font-poppins font-medium text-[18px] leading-[27px]  text-white">
              Serving in India
            </h4>
            <ul className="grid grid-cols-1 gap-3   mt-4">
              {cityOptions.map((location) => (
                <>
                  <div className="citiname     ${selectedCity === location ? 'active' : ''}`}">
                    <li
                      key={location}
                      className={`font-poppins font-normal text-[14px] leading-[20px] text-dimWhite hover:text-white cursor-pointer`}
                      onClick={() => {
                        handleLocationSelection(location);
                        scrollToTop();
                      }}
                    >
                      {location}
                    </li>
                  </div>
                </>
              ))}
            </ul>
          </div>
          <div className="cityoptions my-3 ss:my-0">
            <h4 className="font-poppins font-medium text-[18px] leading-[27px]  text-white">
              Our Services
            </h4>
            <ul className="grid grid-cols-1 gap-3   mt-4">
              <li
                className={`font-poppins font-normal text-[14px] leading-[20px] text-dimWhite hover:text-white cursor-pointer`}
              >
                <Link to={redirectUrl_cleaning} onClick={scrollToTop}>
                  Cleaning Services
                </Link>
              </li>
              <li
                className={`font-poppins font-normal text-[14px] leading-[20px] text-dimWhite hover:text-white cursor-pointer`}
              >
                <Link to={redirectUrl_pest} onClick={scrollToTop}>
                  Pest Control Services
                </Link>
              </li>
              <li
                className={`font-poppins font-normal text-[14px] leading-[20px] text-dimWhite hover:text-white cursor-pointer`}
              >
                <Link to={redirectUrl_paint} onClick={scrollToTop}>
                  Painting Services
                </Link>
              </li>
              <li
                className={`font-poppins font-normal text-[14px] leading-[20px] text-dimWhite hover:text-white cursor-pointer`}
              >
                <Link to={redirectUrl_disinfect} onClick={scrollToTop}>
                  Disinfection Services
                </Link>
              </li>
              <li
                className={`font-poppins font-normal text-[14px] leading-[20px] text-dimWhite hover:text-white cursor-pointer`}
              >
                <Link to={redirectUrl_decoration} onClick={scrollToTop}>
                  Decoration Services
                </Link>
              </li>
              <li
                className={`font-poppins font-normal text-[14px] leading-[20px] text-dimWhite hover:text-white cursor-pointer`}
              >
                <Link to={redirectUrl_waterproofing} onClick={scrollToTop}>
                  Waterproofing Services
                </Link>
              </li>
              <li
                className={`font-poppins font-normal text-[14px] leading-[20px] text-dimWhite hover:text-white cursor-pointer`}
              >
                <Link to={redirectUrl_watertank} onClick={scrollToTop}>
                  Water Tank Services
                </Link>
              </li>
              <li
                className={`font-poppins font-normal text-[14px] leading-[20px] text-dimWhite hover:text-white cursor-pointer`}
              >
                <Link to={redirectUrl_cctv} onClick={scrollToTop}>
                  CCTV Services
                </Link>
              </li>
              <li
                className={`font-poppins font-normal text-[14px] leading-[20px] text-dimWhite hover:text-white cursor-pointer`}
              >
                <Link to={redirectUrl_packers} onClick={scrollToTop}>
                  Packers and Movers
                </Link>
              </li>
              <li
                className={`font-poppins font-normal text-[14px] leading-[20px] text-dimWhite hover:text-white cursor-pointer`}
              >
                <Link to={redirectUrl_interiodesign} onClick={scrollToTop}>
                  Interior Designing
                </Link>
              </li>
            </ul>
          </div>

          {footerLinks.map((footerlink) => (
            <div
              key={footerlink.title}
              className={`flex flex-col ss:my-0 my-3 min-w-[150px]`}
            >
              <h4 className="font-poppins font-medium text-[18px] leading-[27px]  text-white">
                {footerlink.title}
              </h4>
              <ul className="list-none mt-4">
                {footerlink.links.map((link, index) => (
                  <li
                    key={link.name}
                    className={`font-poppins font-normal text-[13px] leading-[20px] text-dimWhite hover:text-white cursor-pointer ${
                      index !== footerlink.links.length - 1 ? "mb-4" : "mb-0"
                    }`}
                  >
                    <Link to={link.link} onClick={scrollToTop}>
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      <div className="w-full  flex justify-between items-center md:flex-row flex-col lg:mb-0 mb-12 bg-darkBlack ">
      <div className="flex flex-row mt-5 lg:hidden lg:mr-16">
          {socialMedia.map((social, index) => (
            <img
              key={social.id}
              src={social.icon}
              alt={social.id}
              className={`w-[21px] h-[21px] object-contain cursor-pointer ${
                index !== socialMedia.length - 1 ? "mr-6" : "mr-6"
              }`}
              onClick={() => {
                window.open(social.link);
                scrollToTop();
              }}
            />
          ))}
        </div>
        <p className="font-poppins font-normal text-center text-[14px]  leading-[27px] lg:ml-16 m-5 text-white  ">
          Ⓒ Copyright 2023 KBS Home Services 
          {/* <Link to={"https://nouviex.com"} className="text-yellow">Nouviex Technologies</Link> */}
          {/* <Link to="https://nouviex.com" className="text-yellow">Nouviex Technologies</Link> */}

        </p>
        <div className="lg:flex flex-row  hidden lg:mr-16">
          {socialMedia.map((social, index) => (
            <img
              key={social.id}
              src={social.icon}
              alt={social.id}
              className={`w-[21px] h-[21px] object-contain cursor-pointer ${
                index !== socialMedia.length - 1 ? "mr-6" : "mr-6"
              }`}
              onClick={() => {
                window.open(social.link);
                scrollToTop();
              }}
            />
          ))}
        </div>
        
      </div>
    </section>
  );
};

export default Footer;
