import React from "react";

import WhyUs from "./WhyUs";
import Footer from "../Components/Footer";
import MetaData from "../Metadata";

const AboutUs = () => {
  return (
    <>
    <MetaData title="About Us - KBS Home Services" />
      <div className=" flex justify-center items-center">
        {/* <div className="backgroundImage w-full h-[60vh] "></div> */}
        <div className=" flex justify-center items-center lg:h-[180vh] sm:ml-40 sm:mr-40 px-1 w-full sm:mt-20  ">
        <div className=" lg:w-[1200px] mt-8 w-[400px] lg:h-[162vh] justify-center items-center rounded-[30px]  bg-secondary/40  absolute z-1  blur-[5px]"></div>
        
          <div className="main flex flex-col relative z-10 bg-white/70 rounded-[30px] w-[400px] lg:w-[1200px] justify-center items-center mt-6">
            <h1 className="sm:text-5xl text-4xl mt-10 font-poppins sm:font-semibold font-bold about-us-heading">
              About Us
              <div className="orange-line"></div>
            </h1>
            <div className="firstparagraph font-poppins mt-5 lg:px-[80px] px-[40px] sm:text-xl text-md text-justify">
              <p>
                Welcome to KBS Home Services, your first choice for house
                cleaning services in namma Bengaluru. At KBS home services we go
                beyond cleaning we are committed to creating an environment
                where quality meets housekeeping. Established in 2019 in
                Bengaluru, our journey began with a commitment to redefine
                living space and improve the overall well-being of ourcustomers.
              </p>
            </div>
            <div className="points flex font-poppins flex-col lg:px-[80px] px-[40px] sm:text-xl  mt-5">
              <span className="sm:font-semibold font-bold sm:text-[25px] text-[18px]  ">
                Our Mission and Values:
              </span>
              <p className="text-justify">
                At the heart of KBS Home Services is the service of providing
                more than just cleaning; Our goal is to improve the quality of
                your home. Our values revolve around integrity, trust and the
                pursuit of customer satisfaction. We believe that a clean home
                contributes to a healthy and happy life.
              </p>

              <span className="sm:font-semibold font-bold sm:text-[25px] text-[18px]  mt-5">
                Our services are different:
              </span>
              <p className="text-justify">
                From bathroom and kitchen cleaning to basic house cleaning, deep
                cleaning and sofa refinishing, we offer a full range of services
                tailored to your unique needs. Our trained professionals bring
                their expertise and dedication to every job, ensuring a smooth
                and efficient cleaning experience.
              </p>

              <span className="sm:font-semibold font-bold sm:text-[25px] text-[18px] mt-5">
                Knowledge and Customer Satisfaction:
              </span>
              <p className="text-justify">
                {" "}
                Our team of qualified professionals are not just cleaners but
                housekeeping staff. We pride ourselves on our craftsmanship,
                attention to detail and commitment to exceed your expectations.
                Your satisfaction is our driving force and we work hard to
                transform a home into a home that exudes comfort and style.
              </p>

              <span className="sm:font-semibold font-bold sm:text-[25px] text-[18px] mt-5">
                Why choose KBS Home Services?
              </span>
              <p className="flex flex-col gap-1 mt-2 text-justify">
                <span>
                  {" "}
                  1. Quality Assurance: Achieve a new level of well-being with
                  our efficient cleaning process and sophisticated service.
                </span>
                <span>
                  {" "}
                  2. Trust: We understand the importance of trust in home
                  projects. Hit us up when you need us.
                </span>
                <span>
                  3. Customized Solutions: Every home is unique with its
                  cleaning needs. Our services are designed to meet the needs of
                  your home.
                </span>
              </p>

              <span className="sm:font-semibold font-bold sm:text-[25px] text-[18px] mt-5">
                Our Travel Distance:
              </span>
              <p className="text-justify">
                {" "}
                Since our inception in 2019, we have become a trusted partner
                for many families in Bangalore. Our journey is based on happy
                homes and satisfied customers who have seen the transformation
                of their homes.
              </p>

              <span className="sm:font-semibold font-bold sm:text-[25px] text-[18px] mt-5">
                Join us in defining housekeeping:
              </span>
              <p className="text-justify">
                Discover the difference of choosing KBS Home Services as your
                trusted partner to create spaces that match comfort and style.
                Whether you're looking for regular cleaning or deep cleaning,
                we're here to make your home a haven.
              </p>

              <p className="mt-3 mb-8 text-justify">
                {" "}
                Thank you for considering KBS Home Services - the well-being of
                your home is our top priority.
              </p>
            </div>
          </div>
        </div>
      </div>
      <WhyUs />
      <div className="mt-16"></div>
      <Footer />
    </>
  );
};

export default AboutUs;
