import React from "react";


import {  phone, mail , whatsapp } from "../assets";
import { Link } from "react-router-dom";


import Footer from "../Components/Footer";
import MetaData from "../Metadata";

function ContactPage() {
  return (
    <><MetaData title="Contact Us - KBS Home Services" /><div className="justify-center items-center">
      {/* <div className="backgroundImage w-full h-[60vh] "></div> */}
      <div className="justify-center items-center flex sm:h-[70vh]  mt-6 mb-14  ">
        <div className="lg:w-[900px] sm:w-[600px] w-[430px] lg:h-[58vh] h-[36vh] mt-6 justify-center items-center rounded-[30px] bg-secondary/40 absolute z-1 blur-[5px] hidden sm:block"></div>
        <div className="main flex flex-col relative z-10 lg:h-[58vh] sm:bg-white/70 bg-secondary/20 rounded-[30px] lg:w-[900px] sm:w-[600px]  w-[360px]  items-center sm:mt-6 mt-24 sm:mb-0 mb-24">
          <div className="flex flex-row sm:mt-12 mt-8 justify-center items-center">
            <h1 className="font-poppins sm:font-semibold font-bold ss:text-[40px] text-[40px] text-black mb-4  ">
              Contact Us
            </h1>
          </div>
          <div className="flex flex-row justify-center items-center">
            <p className="font-poppins sm:font-semibold font-bold text-orange-400 text-[20px] mb-1 ">
              Help is just a click away
            </p>
          </div>
          <div className="flex flex-row justify-center items-center">
            <p className="font-poppins sm:font-normal font-semibold text-orange-400  sm:text-[18px] text-[16px] mb-6 ">
              Call us anytime between 11am - 8pm
            </p>
          </div>

          <div className="flex justify-center items-center pt-5 pb-5">
            <div className="flex flex-col space-y-2 bg-white w-full sm:w-[400px] max-w-xl p-4 rounded-xl shadow-inner text-Black">
              <div className="flex justify-between ">
                <span className="font-poppins font-normal text-dimBlack text-[15px]">
                  <Link to="mailto:support@kbshomeservice.com  ">
                    support@kbshomeservice.com
                  </Link>
                </span>
                <img loading="lazy" src={mail} alt="discount" className="w-[24px] h-[24px]" />
              </div>
              <hr className="w-full my-8 border-dotted border-t-2 border-gray-300 dark:border-gray-300" />
              <div className="flex justify-between ">
                <span className="font-poppins font-normal text-dimBlack text-[15px]">
                  <Link to="tel:6363617916">+91-63636 17916</Link>
                </span>
                <img loading="lazy" src={phone} alt="discount" className="w-[24px] h-[24px]" />
              </div>
              <hr className="w-full my-8 border-dotted border-t-2 border-gray-300 dark:border-gray-300" />
              <div className="flex justify-between ">
                <span className="font-poppins font-normal text-dimBlack text-[15px]">
                  <Link to="https://api.whatsapp.com/send?phone=+916363617916&text=Hi,%20I%20would%20like%20to%20discuss%20further%20on%20the%20services%20offered%20by%20KBS%20Home%20Services...%20Kindly%20connect.%20Thanks">+91-63636 17916</Link>
                </span>
                <img loading="lazy" src={whatsapp} alt="discount" className="w-[24px] h-[24px]" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div></>
  );
}

export default ContactPage;
