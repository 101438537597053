import React from 'react'

const Description = ({
    headingDescription_1,
    paraDescription_1,
    headingDescription_2,
    paraDescription_2,
}) => {
  return (
    <div className='flex bg-white flex-col items-center sm:items-start px-[20px] md:px-[40px]  my-8'>
        <div className='para1 flex flex-col gap-2 justify-start items-start'>
            <h1 className='sm:text-2xl text-xl font-semibold sm:font-bold font-poppins'>{`${headingDescription_1} ?`}</h1>
            <p className='sm:text-lg text-sm font-poppins text-start'>{paraDescription_1}</p>


        </div>
        <div className='para2 flex flex-col gap-2 my-4 justify-start items-start'>
        <h1 className='sm:text-2xl text-xl font-semibold sm:font-bold font-poppins'>{`${headingDescription_2}?`}</h1> 
        <ul className="list-disc pl-5 font-poppins leading-[25px] text-sm sm:text-lg">
              {paraDescription_2.map((item, index) => (
      <li key={index}>{item}</li>
    ))}
              </ul>


        </div>
      
    </div>
  )
}

export default Description
