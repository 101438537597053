import React from "react";
import {
  vaccum,
  pest,
  paint,
  cleaning,
  cctv,
  decorate,
  machine,
  building,
  carwashicon,
  disinfection,
  waterproof,
  interiorDesign,
  tank,
} from "../assets";
import { Link } from "react-router-dom";

const ServiceCard = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const selectedCity = localStorage.getItem("city");
  const citySlug = selectedCity ? selectedCity.toLowerCase() : '';


  const redirectUrl_deep = `/deep-home-cleaning-in-${citySlug}`;
  const redirectUrl_commercial = `/commercial-cleaning-in-${citySlug}`;
  const redirectUrl_decoration = `/decoration-service-in-${citySlug}`;
  const redirectUrl_cctv = `/cctv-service-in-${citySlug}`;
  const redirectUrl_pest = `/pest-control-services-in-${citySlug}`;
  const redirectUrl_paint = `/paint-service-in-${citySlug}`;
  const redirectUrl_disinfect = `/disinfection-service-in-${citySlug}`;
  const redirectUrl_waterproofing = `/water-proofing-service-in-${citySlug}`;
  const redirectUrl_home = `/home-sanitization-in-${citySlug}`;
  const redirectUrl_carwash = `/car-washing-in-${citySlug}`;
  const redirectUrl_interiodesign = `/interior-designing-service-in-${citySlug}`;
  const redirectUrl_watertank = `/water-tank-and-sump-cleaning-in-${citySlug}`;

  return (
    <div className="flex flex-col gap-2 justify-center items-center w-full mt-0 bg-slate-50  md:mt-20">
      <div className="flex flex-col justify-center items-center  shadow-sm  p-3 rounded-lg sm:mt-[40px]">
        <h1 className="font-bold font-poppins text-3xl mb-5 sm:mb-10 ">
          Our Top Services
        </h1>
        <div className="serviceCard flex flex-col lg:flex-row gap-3  justify-start items-center">
          <div className="grid  grid-cols-4 lg:grid-cols-5 gap-3 lg:gap-[50px] sm:gap-8 justify-center items-center">
            <Link onClick={scrollToTop} to={redirectUrl_deep}>
              {" "}
              <div className="iconCard md:w-[200px] sm:h-[130px] py-3 flex flex-col justify-center items-center hover:scale-110  transition-transform duration-300 ease-in-out rounded-2xl md:shadow-lg bg-opacity-0">
                <img
                  className="w-[30px] sm:w-[60px]"
                  src={vaccum}
                  loading="lazy"
                  alt="vaccum"
                />
                <h2 className="font-poppins mt-4 text-[12px] font-normal  text-center lg:text-sm ">
                  Deep home{" "}
                </h2>
              </div>
            </Link>
            <Link onClick={scrollToTop} to={redirectUrl_commercial}>
              {" "}
              <div className="iconCard md:w-[200px] sm:h-[130px] py-3 flex flex-col justify-center items-center hover:scale-110  transition-transform duration-300 ease-in-out rounded-2xl md:shadow-lg bg-opacity-0">
                <img
                  className="w-[30px] sm:w-[60px]"
                  src={building}
                  loading="lazy"
                  alt="machine"
                />
                <h2 className="font-poppins mt-4 text-[12px] font-normal  text-center lg:text-sm">
                  Commercial{" "}
                </h2>
              </div>
            </Link>
            <Link onClick={scrollToTop} to={redirectUrl_decoration}>
              {" "}
              <div className="iconCard md:w-[200px] sm:h-[130px] py-3 flex flex-col justify-center items-center hover:scale-110  transition-transform duration-300 ease-in-out rounded-2xl md:shadow-lg bg-opacity-0">
                <img
                  className="w-[30px] sm:w-[60px]"
                  src={decorate}
                  loading="lazy"
                  alt="decorate"
                />
                <h2 className="font-poppins mt-4 text-[12px] font-normal  text-center lg:text-sm">
                  Decoration{" "}
                </h2>
              </div>{" "}
            </Link>

            <Link onClick={scrollToTop} to={redirectUrl_paint}>
              {" "}
              <div className="iconCard md:w-[200px] sm:h-[130px] py-3 flex flex-col justify-center items-center hover:scale-110  transition-transform duration-300 ease-in-out rounded-2xl md:shadow-lg bg-opacity-0">
                <img
                  className="w-[30px] sm:w-[60px]"
                  src={paint}
                  loading="lazy"
                  alt="paint"
                />
                <h2 className="font-poppins mt-4 text-[12px] font-normal  text-center lg:text-sm">
                  Painting{" "}
                </h2>
              </div>
            </Link>
            <Link onClick={scrollToTop} to={redirectUrl_disinfect}>
              <div className="iconCard md:w-[200px] sm:h-[130px] py-3 flex flex-col justify-center items-center hover:scale-110  transition-transform duration-300 ease-in-out rounded-2xl md:shadow-lg bg-opacity-0">
                <img
                  className="w-[30px] sm:w-[60px]"
                  src={cleaning}
                  loading="lazy"
                  alt="cleaning"
                />
                <h2 className="font-poppins mt-4 text-[12px] font-normal  text-center lg:text-sm">
                  Sanitization{" "}
                </h2>
              </div>
            </Link>
            <Link onClick={scrollToTop} to={redirectUrl_carwash}>
              <div className="iconCard md:w-[200px] sm:h-[130px] py-3 flex flex-col justify-center items-center hover:scale-110  transition-transform duration-300 ease-in-out rounded-2xl md:shadow-lg bg-opacity-0">
                <img
                  className="w-[30px] sm:w-[60px]"
                  src={carwashicon}
                  loading="lazy"
                  alt="cleaning"
                />
                <h2 className="font-poppins mt-4 text-[12px] font-normal  text-center lg:text-sm">
                  Car Wash{" "}
                </h2>
              </div>
            </Link>
            <Link onClick={scrollToTop} to={redirectUrl_home}>
              <div className="iconCard md:w-[200px] sm:h-[130px] py-3 flex flex-col justify-center items-center hover:scale-110  transition-transform duration-300 ease-in-out rounded-2xl md:shadow-lg bg-opacity-0">
                <img
                  className="w-[30px] sm:w-[60px]"
                  src={disinfection}
                  loading="lazy"
                  alt="cleaning"
                />
                <h2 className="font-poppins mt-4 text-[12px] font-normal  text-center lg:text-sm">
                  Disinfection{" "}
                </h2>
              </div>
            </Link>

            <Link onClick={scrollToTop} to={redirectUrl_cctv}>
              <div className="iconCard md:w-[200px] sm:h-[130px] py-3 flex flex-col justify-center items-center hover:scale-110  transition-transform duration-300 ease-in-out rounded-2xl md:shadow-lg bg-opacity-0">
                <img
                  className="w-[30px] sm:w-[60px]"
                  src={cctv}
                  loading="lazy"
                  alt="cctv"
                />
                <h2 className="font-poppins mt-4 text-[12px] font-normal  text-center lg:text-sm">
                  CCTV{" "}
                </h2>
              </div>
            </Link>

            <Link onClick={scrollToTop} to={redirectUrl_pest}>
              {" "}
              <div className="iconCard  md:w-[200px] sm:h-[130px] py-3 flex flex-col justify-center items-center hover:scale-110  transition-transform duration-300 ease-in-out rounded-2xl md:shadow-lg bg-opacity-0">
                <img
                  className="w-[30px] sm:w-[60px]"
                  src={pest}
                  loading="lazy"
                  alt="pest"
                />
                <h2 className="font-poppins mt-4 pb-4 ss:pb-0 text-[12px] font-normal  text-center lg:text-sm">
                  Pest Control {" "}
                </h2>
              </div>
            </Link>

            <Link onClick={scrollToTop} to={redirectUrl_interiodesign}>
              <div className="iconCard md:w-[200px] sm:h-[130px] py-3 flex flex-col justify-center items-center hover:scale-110  transition-transform duration-300 ease-in-out rounded-2xl md:shadow-lg bg-opacity-0">
                <img
                  className="w-[30px] sm:w-[60px]"
                  src={interiorDesign}
                  loading="lazy"
                  alt="cctv"
                />
                <h2 className="font-poppins mt-4 text-[12px] font-normal  text-center lg:text-sm">
                  Interior Designing
                </h2>
              </div>
            </Link>
            <Link onClick={scrollToTop} to={redirectUrl_watertank}>
              <div className="iconCard md:w-[200px] sm:h-[130px] py-3 flex flex-col justify-center items-center hover:scale-110  transition-transform duration-300 ease-in-out rounded-2xl md:shadow-lg bg-opacity-0">
                <img
                  className="w-[30px] sm:w-[60px]"
                  src={tank}
                  loading="lazy"
                  alt="cctv"
                />
                <h2 className="font-poppins mt-4 text-[12px] font-normal  text-center lg:text-sm">
                  Water Tank Cleaning
                </h2>
              </div>
            </Link>

            <Link onClick={scrollToTop} to={redirectUrl_waterproofing}>
              <div className="iconCard md:w-[200px] sm:h-[130px] py-3 flex flex-col justify-center items-center hover:scale-110  transition-transform duration-300 ease-in-out rounded-2xl md:shadow-lg bg-opacity-0">
                <img
                  className="w-[30px] sm:w-[60px]"
                  src={waterproof}
                  loading="lazy"
                  alt="cleaning"
                />
                <h2 className="font-poppins mt-4 pb-4 ss:pb-0 text-[12px] font-normal  text-center lg:text-sm">
                  Waterproofing  {" "}
                </h2>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
