import React from "react";
import {
  goodperson,
  excellent,
  service,
  support,
  qualityservice,
  qualitytools,
  topservice,
  costumerservice,
  goodpersoncolor,
  customerservice,
} from "../assets";

const WhyUs = () => {
  return (
    <div className="flex flex-wrap bg-secondary text-white flex-col justify-center items-center p-2 py-4">
      <h1 className="font-poppins sm:text-4xl text-3xl sm:font-semibold font-bold mt-4 mb-4 ">
        What Sets Us Apart
      </h1>


      
     <div className="grid grid-cols-1 my-[20px] justify-stretch md:grid-cols-2 lg:gap-[60px] gap-[30px]">
      <div className="rounded-xl p-4  w-[300px] bg-white/30 shadow-xl   text-black hover:scale-105 transition-transform ease-in-out relative overflow-hidden group">
      <div className="card relative z-10  flex flex-row justify-start items-center gap-[10px] ">
        <div className="img">
          <img src={goodpersoncolor} alt="verified professional" className="w-[50px]" />
        </div>
        <div className="text flex flex-col gap-1">
          <h1 className="text-xl text-white font-semibold font-poppins">Verified Professional</h1>
          <p className="text-sm font-poppins">Our team of professional <br />  cleaners are best in the city</p>
        </div>
      </div>
      <div className="absolute inset-0 bg-gradient-to-br from-white/90 to-orange-600 opacity-0 group-hover:opacity-100 transition-opacity duration-500 z-0"></div>
      </div>



      <div className="rounded-xl p-4  w-[300px] bg-white/30 shadow-xl   text-black hover:scale-105 transition-transform ease-in-out relative overflow-hidden group">
      <div className="card relative z-10 flex flex-row justify-start items-center gap-[10px]">
        <div className="img">
          <img src={qualitytools} alt="verified professional" className="w-[50px]" />
        </div>
        <div className="text flex flex-col gap-1">
          <h1 className="text-xl text-white font-semibold font-poppins">Finest Instruments</h1>
          <p className="text-sm font-poppins">  Our team adopts hygienic <br /> mechanized cleaning solution</p>
        </div>
      </div>
      <div className="absolute inset-0 bg-gradient-to-br from-white/90 to-orange-600 opacity-0 group-hover:opacity-100 transition-opacity duration-500 z-0"></div>
      </div>



      <div className="rounded-xl p-4  w-[300px] bg-white/30 shadow-xl   text-black hover:scale-105 transition-transform ease-in-out relative overflow-hidden group">
      <div className="card relative z-10 flex flex-row justify-start items-center gap-[10px]">
        <div className="img">
          <img src={topservice} alt="verified professional" className="w-[50px]" />
        </div>
        <div className="text flex flex-col gap-1">
          <h1 className="text-xl text-white font-semibold font-poppins"> Excellent Service</h1>
          <p className="text-sm font-poppins"> All cleaning is done by <br />
              full time and trained employees</p>
        </div>
      </div>
      <div className="absolute inset-0 bg-gradient-to-br from-white/90 to-orange-600 opacity-0 group-hover:opacity-100 transition-opacity duration-500 z-0"></div>
      </div>



      <div className="rounded-xl p-4  w-[300px] bg-white/30 shadow-xl  text-black hover:scale-105 transition-transform ease-in-out relative overflow-hidden group">
      <div className="card relative z-10 flex flex-row justify-start items-center gap-[10px] ">
        <div className="img border-gray rounded-[100%]">
          <img src={customerservice} alt="verified professional" className="w-[50px]" />
        </div>
        <div className="text flex flex-col gap-1 ">
          <h1 className="text-xl text-white font-semibold font-poppins">  Customer Support</h1>
          <p className="text-sm font-poppins">  24/7 customer support <br /> is available</p>
        </div>
      </div>
      <div className="absolute inset-0 bg-gradient-to-br from-white/90 to-orange-600 opacity-0 group-hover:opacity-100 transition-opacity duration-500 z-0"></div>
      </div>
     

     </div>
    </div>
  );
};

export default WhyUs;
