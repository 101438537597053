import React from 'react'

import {  babyshower } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';

import DecorationModal from './DecorationModal';
import FaqServices from '../../Components/FaqServices';
import Description from "../../Components/Description";



const BabyShower = () => {

  const data = {
    headingFirst: "Baby Shower",
    headingSecond: "Baby Shower",
    price: "₹5999",
    description: [
      "Customized baby shower decorations",
      "Theme-based decoration setups",
      "Balloon arrangements and arches",
      "Table centerpieces with baby shower motifs",
      "Backdrop designs with personalized messages",
      "Chair covers and sashes in thematic colors",
      "Sweet and treat table styling",
      "Photo booth setup with props and accessories",
      "Floor decals or banners for a festive atmosphere",
      "Creative signage and welcome boards",
      "Floral arrangements and bouquets",
      "Lighting arrangements to enhance the ambiance",
      "Coordinated tableware and utensils",
      "Setup and takedown services included",
  ],
  
    image: babyshower,
    space: "ocassion place",
    size: "place",
    descriptionMeta: "Celebrate your bundle of joy with our exquisite Baby Shower Decoration Service. From charming themes to elegant decor, we'll create a memorable atmosphere for this special occasion. Let us bring your vision to life—book your baby shower decor today! ",
    
  
  }

  const babyShowerDecorationFAQ = [
    {
      question: 'When should I book the baby shower decoration service?',
      answer: 'It is recommended to book the baby shower decoration service well in advance, preferably a few weeks before the scheduled date to ensure availability and proper planning.',
    },
    {
      question: 'What decoration themes do you offer for baby showers?',
      answer: 'We offer a variety of decoration themes for baby showers, including classic, gender-specific, and trendy themes. You can discuss your preferences with our team to choose the perfect theme.',
    },
    {
      question: 'Can I customize the baby shower decorations?',
      answer: "Yes, we provide customization options for baby shower decorations. You can share your specific color schemes, preferences, and any personalized elements you'd like to include in the decorations.",
    },
    {
      question: 'Do you provide baby shower decoration packages?',
      answer: 'Yes, we offer different baby shower decoration packages to suit various budgets and preferences. Our packages may include balloon arrangements, banners, centerpieces, and other decorative elements.',
    },
    {
      question: 'Is setup and takedown included in the baby shower decoration service?',
      answer: 'Yes, our team will handle both the setup and takedown of the baby shower decorations. You can focus on enjoying the event while we take care of the decorative details.',
    },
    {
      question: 'Can you provide outdoor baby shower decorations?',
      answer: 'Yes, we offer outdoor baby shower decorations to create a beautiful and festive atmosphere in outdoor venues. Our decorations are designed to withstand various weather conditions.',
    },
    {
      question: 'Do you offer rental options for baby shower decorations?',
      answer: 'Yes, we provide rental options for baby shower decorations, including items such as chairs, tables, linens, and decorative props. Renting decorations can be a cost-effective and convenient choice.',
    },
    {
      question: "Customized Baby Shower Decorations?",
      answer: "Yes, our team specializes in creating personalized baby shower decorations tailored to your specific themes, interests, and preferences. Whether it's a favorite color scheme, a beloved storybook character, or a unique hobby, we'll work closely with you to design decorations that reflect the mom-to-be's personality and make the event unforgettable."
    }
,    
    {
      question: 'What payment options do you accept for baby shower decoration services?',
      answer: 'We accept various payment options for baby shower decoration services, including credit/debit cards, bank transfers, and cash payments. Our goal is to make the payment process as convenient as possible for our clients.',
    },
    {
      question: "Additional Services for Baby Shower Decorations?",
      answer: "Yes, we offer a range of additional services including catering, photography, entertainment, and more to enhance your baby shower experience. Our goal is to create a seamless and memorable event tailored to your preferences."
    },
    
    
  ];

  const descriptionData = {
    headingDescription_1: "Why Baby Shower Decoration Service is Essential",
    paraDescription_1: "Welcoming a new addition to the family is a joyous occasion that deserves to be celebrated in style. Baby showers are a cherished tradition where loved ones gather to honor the expectant mother and shower her with love, support, and well-wishes for the journey ahead. Creating a warm and inviting atmosphere is key to making the occasion memorable and meaningful. Professional baby shower decoration service plays a vital role in bringing your vision to life, transforming ordinary spaces into enchanting settings that reflect the joy and anticipation of welcoming a new baby. With creative design concepts, attention to detail, and personalized touches, our service ensures that every aspect of the baby shower is beautifully adorned, from thematic decorations and centerpieces to balloon arrangements and photo backdrops.",
    headingDescription_2: "What We Do in Baby Shower Decoration Service",
    paraDescription_2: [
        "Collaboration with clients to understand their theme, color scheme, and decoration preferences.",
        "Creation of customized decoration packages tailored to the client's budget and requirements.",
        "Design and setup of thematic decorations, including banners, balloons, streamers, and table centerpieces.",
        "Installation of creative backdrops and photo booths for memorable photo opportunities.",
        "Arrangement of floral displays, including bouquets, corsages, and table arrangements.",
        "Incorporation of personalized elements such as baby name banners, signage, and keepsakes.",
        "Coordination with venue staff and vendors to ensure seamless setup and execution of decorations.",
        "On-site supervision and adjustments throughout the event to maintain the visual appeal of decorations.",
        "Cleanup and removal of decorations after the event to minimize stress for the host.",
        "Additional services available, such as themed favors, dessert tables, and entertainment options.",
        "Flexible scheduling and customization options to accommodate varying preferences and budgets."
    ]
}

  

   
  return (
    <div className='main'>
       <DecorationModal {...data}/>
       <FaqServices faqData={babyShowerDecorationFAQ} />
       <Description {...descriptionData} />
       <Benefits />
       <TestimonialCarousel />
       <Footer />
    </div>
  )
}

export default BabyShower
