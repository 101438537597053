import React  from 'react'

import { vacantpaint } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';

import PaintModal from './PaintModal';
import FaqServices from '../../Components/FaqServices';
import Description from '../../Components/Description';

const VacantWallPainting = () => {

  const data = {
    headingFirst: "Vacant Wall",
    headingSecond: "Vacant Wall",
    price: "₹1999",
    descriptionMeta: "Revitalize your indoor space with our comprehensive Interior Paint Service. From accent walls to full-room makeovers, we'll refresh your interiors with high-quality paints and professional craftsmanship. Transform your home or office—schedule your interior paint service today!",

    description: [
      "Surface preparation, including filling cracks and holes",
      "Priming of walls and surfaces",
      "Application of multiple coats of paint",
      "Color consultation and selection",
      "Trim and molding painting",
      "Ceiling painting",
      "Door and window frame painting",
      "Repairing and smoothing of surfaces",
      "Protection of furniture and floors during painting",
      "Clean and neat paint job with sharp lines",
      "Cleanup and removal of painting debris",
      "Quality assurance and final inspection",
      "Exterior and interior painting services",
      "Specialized finishes and textures upon request",
      "Professional guidance on paint types and finishes",
      "Timely and efficient completion of the painting project",
  ],
  
    image: vacantpaint,
    space: "living space",
    size: "house",
    
  
  }
  const vacantWallPaintFAQ = [
    {
      question: 'How long does it take for freshly painted walls to dry?',
      answer: 'The drying time for freshly painted walls depends on factors like humidity and ventilation. In general, it can take anywhere from a few hours to a few days for walls to fully dry and cure.',
    },
    {
      question: 'Can you paint over existing wallpaper on the walls?',
      answer: 'In most cases, it is not recommended to paint over existing wallpaper, as it may affect the adhesion and finish. It is advisable to remove wallpaper before painting for the best results.',
    },
    {
      question: 'Do I need to move furniture before wall painting?',
      answer: 'For a more efficient and thorough painting process, it is recommended to move furniture away from the walls. Our team can assist in moving light furniture, or you can move larger items before the service.',
    },
    {
      question: 'What types of paint finishes are available for walls?',
      answer: "We offer a variety of paint finishes, including matte, satin, eggshell, and semi-gloss. The choice of finish depends on factors like the room's function and personal preference.",
    },
    {
      question: "Is wall preparation included in the painting service?",
      answer: 'Yes, our wall painting service includes thorough preparation, such as cleaning, patching holes, and priming, to ensure a smooth and long-lasting finish.',
    },
    {
      question: 'Do you provide touch-up services after wall painting?',
      answer: 'Yes, we offer touch-up services to address any minor imperfections or areas that may need additional paint coverage after the initial painting is complete.',
    },
    {
      question: 'Can you paint accent walls as part of the wall painting service?',
      answer: 'Absolutely, we can paint accent walls to add visual interest and personality to your space. Our team can help you choose the perfect color and location for accent walls.',
    },
    {
      question: 'Do you offer a warranty or guarantee for your wall painting services?',
      answer: 'Yes, we stand behind the quality of our workmanship and offer a warranty or guarantee for our wall painting services. Please contact us for more details on our warranty policy.',
    },
    {
      question: 'How do you protect floors and furniture during wall painting?',
      answer: 'Before starting the painting process, we take precautions to protect floors and furniture by covering them with drop cloths or plastic sheets to prevent any paint spills or splatters.',
    },
    {
      question: 'Can you provide a cost estimate for wall painting services?',
      answer: 'Yes, we offer free estimates for wall painting services. Simply contact us to schedule a consultation, and our team will assess your needs and provide you with a detailed cost estimate.',
    },
  ];
  const descriptionData = {
    headingDescription_1: "Why Professional Wall Painting is Essential for Vacant Spaces",
    paraDescription_1: "Transforming the interior of a vacant space with a fresh coat of paint is crucial for revitalizing its appearance, enhancing its ambiance, and increasing its market value. Over time, empty walls can accumulate dust, dirt, and signs of wear and tear, detracting from the overall appeal of the space. Neglecting proper painting maintenance can result in dull, faded walls that fail to inspire or attract potential occupants. Professional wall painting service plays a vital role in rejuvenating vacant spaces, creating a welcoming atmosphere, and leaving a lasting impression on visitors. With skilled painters, premium-quality paints, and meticulous attention to detail, our service breathes new life into empty walls, transforming them into vibrant canvases that reflect style and sophistication. Investing in professional wall painting is not just about aesthetics; it's about maximizing the potential and value of your vacant space for future occupants and buyers.",
    headingDescription_2: "What We Do in Professional Wall Painting Service",
    paraDescription_2: [
        "Preparation of walls, including cleaning, sanding, and patching any imperfections or cracks.",
        "Selection of premium-quality paints in desired colors and finishes to suit the aesthetic preferences of the client.",
        "Application of primer to ensure proper adhesion and smooth, uniform coverage of paint.",
        "Painting of walls using professional-grade tools and techniques to achieve precise lines and consistent color application.",
        "Multiple coats of paint applied as needed to achieve full coverage and desired depth of color.",
        "Touch-up and detailing to address any missed spots or uneven areas for a flawless finish.",
        "Cleanup of work area, including removal of paint drips, masking tape, and other debris.",
        "Final inspection to ensure quality workmanship and client satisfaction.",
        "Customized painting services available for accent walls, murals, or specialty finishes.",
        "Optional color consultation services to help clients choose the perfect palette for their space."
    ]
};


  
  return (
    <div className='main'>
       <PaintModal {...data} />
       <FaqServices faqData={vacantWallPaintFAQ} />
       <Description {...descriptionData} />
       <Benefits />
       <TestimonialCarousel />
       <Footer />
    </div>
  )
}

export default VacantWallPainting
