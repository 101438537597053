import React, { useState, useEffect, useRef } from "react";
import logo from "../assets/logo.png";
// import call from "../assets/phone-call.png";
// import downarrow from "../assets/down-arrow.png";
// import { SlArrowDown } from "react-icons/sl";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

import { IoIosMail, IoIosArrowDown } from "react-icons/io";
import { IoLocationOutline } from "react-icons/io5";
import {
  FaPhoneAlt,
  // FaUser,
  FaHome,
  FaBars,
  // FaInfoCircle,
  // FaSearch,
  FaWhatsapp,
  FaTimes,
  // FaLocationArrow,
} from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";
import styles from "../style";
import { MdOutlineLocationOn } from "react-icons/md";
import { Fragment } from "react";

// import { LuMailbox } from "react-icons/lu";

import { Menu, Transition } from "@headlessui/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { cityOptions, cityOptionsObj } from "../constants";
// import { flag } from "../assets";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = () => {
  const navigate = useNavigate();
  const city = useParams();
  const [showModal, setShowModal] = useState(false);
  const currentCity = localStorage.getItem("city")
    ? localStorage.getItem("city")
    : "Bangalore";
  const [selectedCity, setSelectedCity] = useState(
    cityOptionsObj[city.city] || "Bangalore"
  );
  // const selectedCity = localStorage.getItem('city')

  const handleLocationSelection = (selectedLocation) => {
    console.log("Location selected:", selectedLocation);
    localStorage.setItem("hasModalBeenOpened", true);
    setSelectedCity(selectedLocation);
    localStorage.setItem("city", selectedLocation);
    setShowModal(false);
    document.body.classList.remove("overflow-hidden");
    navigate(`/${selectedLocation.toLowerCase()}`);
  };

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const handleDropdownToggle = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const [showPestcontrol, setShowPestcontrol] = useState(false);
  const handlePestcontrol = () => {
    setShowPestcontrol(!showPestcontrol);
  };

  const [showPaintServices, setShowPaintServices] = useState(false);
  const handlePaintServices = () => {
    setShowPaintServices(!showPaintServices);
  };
  const [showWaterTank, setShowWaterTank] = useState(false);
  const handleWaterTank = () => {
    setShowWaterTank(!showWaterTank);
  };
  const [showWaterproofing, setShowWaterproofing] = useState(false);
  const handleWaterproofing = () => {
    setShowWaterproofing(!showWaterproofing);
  };

  const [showDecoration, setShowDecoration] = useState(false);
  const handleDecoration = () => {
    setShowDecoration(!showDecoration);
  };

  const [showSanitize, setShowSanitize] = useState(false);
  const handleSanitize = () => {
    setShowSanitize(!showSanitize);
  };

  const [showOtherservices, setShowOtherservices] = useState(false);
  const handleOtherservices = () => {
    setShowOtherservices(!showOtherservices);
  };

  const [navbarVisible, setNavbarVisible] = useState(false);

  const handleNavBarMenuToggle = () => {
    setNavbarVisible(!navbarVisible);
  };

  // console.log(selectedCity);
  const redirectUrl_cleaning = `/cleaning-services-in-${currentCity.toLowerCase()}`;
  const redirectUrl_pest = `/pest-control-services-in-${currentCity.toLowerCase()}`;
  const redirectUrl_paint = `/paint-service-in-${currentCity.toLowerCase()}`;
  const redirectUrl_watertank = `/water-tank-and-sump-cleaning-in-${currentCity.toLowerCase()}`;
  const redirectUrl_waterproofing = `/water-proofing-service-in-${currentCity.toLowerCase()}`;
  const redirectUrl_disinfect = `/disinfection-service-in-${currentCity.toLowerCase()}`;
  const redirectUrl_decoration = `/decoration-service-in-${currentCity.toLowerCase()}`;
  const redirectUrl_cctv = `/cctv-service-in-${currentCity.toLowerCase()}`;
  const redirectUrl_packers = `/packers-and-movers-service-in-${currentCity.toLowerCase()}`;
  const redirectUrl_interiodesign = `/interior-designing-service-in-${currentCity.toLowerCase()}`;

  const mobileLinks = [
    { icon: <FaHome size={25} />, text: "Home", to: `/${currentCity}` },

    {
      icon: <FaWhatsapp size={25} />,
      text: "Whatsapp",
      to: "https://api.whatsapp.com/send?phone=+916363617916&text=Hi,%20I%20would%20like%20to%20discuss%20further%20on%20the%20services%20offered%20by%20KBS%20Home%20Services...%20Kindly%20connect.%20Thanks",
    },

    { icon: <FaPhoneAlt size={25} />, text: "Call", to: "tel:6363617916" },
    {
      icon: <FaBars size={30} />,
      text: "Services",
      sublinks: [
        { text: "Cleaning Services", to: redirectUrl_cleaning },
        { text: "Pest Control Services", to: redirectUrl_pest },
        { text: "Painting Services", to: redirectUrl_paint },
        { text: "Watertank & Sump Services", to: redirectUrl_watertank },
        { text: "Waterproofing Services", to: redirectUrl_waterproofing },
        { text: "Decoration Services", to: redirectUrl_decoration },
        { text: "CCTV Services", to: redirectUrl_cctv },
        { text: "Packers and Movers ", to: redirectUrl_packers },
        { text: "Disinfection Services", to: redirectUrl_disinfect },
        { text: "Interior Designing Services", to: redirectUrl_interiodesign },
      ],
    },
  ];

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleMobileLinkClick = (to) => {
    setShowMobileMenu(false);
    scrollToTop();
  };

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowMobileMenu(false); // Close the dropdown
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleMobileMenuToggle = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [cities, setcities] = useState([
    {
      question: "Deoria",
      answer: "Bareilly",
      isOpen: false,
    },
  ]);
  const toggleFaqFirstSet = (index) => {
    const updatedCities = [...cities];
    updatedCities[index].isOpen = !updatedCities[index].isOpen;
    setcities(updatedCities);
  };

  const toggleFaqSecondSet = (index) => {
    const updatedCities = [...cities];
    updatedCities[index + 5].isOpen = !updatedCities[index + 5].isOpen;
    setcities(updatedCities);
  };

  return (
    <div className=" top-0 left-0 right-0 z-50 bg-slate-100 ">
      {/* upper navbar*/}
      <div className=" hidden md:flex md:w-auto  xl:w-full justify-center items-center  bg-secondary absolute z-1 top-[0%] left-[50%]   right-[0%]   blur-[8px]">
        Text
      </div>
      <div className="hidden md:flex items-center font-normal justify-end md:pl-8 md:pr-8 w-full  flex-col md:flex-row text-black text-[16px] bg-white/20 relative z-10 p-[4px] backdrop-blur-2xl  ">
        <div className="flex flex-row justify-center items-center gap-2">
          {" "}
          <IoIosMail size={19} />
          &nbsp;&nbsp;Mail: support@kbshomeservice.com &nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <div className="flex flex-row justify-center items-center gap-2">
          <FaPhoneAlt size={15} />
          &nbsp;&nbsp;Contact No.: +91-63636 17916
        </div>
      </div>

      {/* Mobile View */}
      <div
        ref={dropdownRef}
        className="md:hidden  fixed bottom-0 left-0 right-0 bg-slate-50 py-4  flex justify-around items-center z-50 shadow-md"
      >
        {mobileLinks.slice(0, 3).map((link, index) => (
          <div
            key={index}
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleMobileLinkClick(link.to)}
          >
            <Link
              to={link.to}
              className="flex hover:text-orange-400 flex-col w-[30px] h-[30px] items-center"
            >
              {link.icon}
            </Link>
          </div>
        ))}

        <div
          className="flex flex-col items-center cursor-pointer relative hover:text-orange-400"
          onClick={handleMobileMenuToggle}
        >
          <FaBars size={25} />

          {/* Dropdown for Services */}
          {showMobileMenu && (
            <div className=" origin-top-left py-2  absolute mr-[70px]  w-[200px] flex flex-col justify-start items-start  gap-2 rounded-md bg-slate-50  text-black border-gray-200 z-70 bottom-full mb-6 ">
              {mobileLinks[3].sublinks.map((sublink, subIndex) => (
                <Link
                  key={subIndex}
                  to={sublink.to}
                  className="cursor-pointer px-2 rounded-md  py-2 hover:text-orange-400  hover:translate-x-5 duration-100 ease-linear"
                  onClick={() => handleMobileLinkClick(sublink.to)}
                >
                  {sublink.text}
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="navbar flex justify-between items-center  pt-2 sm:ml-0 sm:mr-4 md:ml-0 md:mr-8">
        <div className="logo px-5 justify-start ">
          <Link onClick={scrollToTop} to="/">
            <img
              loading="lazy"
              src={logo}
              alt="logo"
              className=" w-[100px] md:w-[140px]"
            />
          </Link>
        </div>

        {/* Upper mobile navbar */}

        <div className="md:hidden flex flex-row items-center justify-center gap-4 pt-3 pb-3 ">
          <div className=" flex flex-row justify-between items-center  w-auto  text-white  lg:hidden bg-secondary  rounded-lg px-3 py-1.5 mb-1">
            {cities.map((city, index) => (
              <div
                key={index}
                className={` ${city.isOpen ? "open" : ""} `}
                onClick={() => toggleFaqFirstSet(index)}
              >
                <div className=" flex flex-row gap-3 justify-between items-center relative">
                  <div>
                    <IoLocationOutline
                      size={20}
                      className="text-[14px] sm:text-[15px]"
                    />
                  </div>
                  <div className="text-sm sm:text-lg text-start">
                    {currentCity}
                  </div>
                  <div className="">
                    <motion.div
                      initial={false}
                      animate={{ rotate: city.isOpen ? 180 : 0 }}
                      transition={{ duration: 0.3 }}
                      className="  "
                    >
                      <IoIosArrowDown className=" text-right" size={20} />
                    </motion.div>
                  </div>
                </div>

                {city.isOpen && (
                  <div className="drop-down  bg-white text-black w-[160px]  absolute  right-[60px] sm:right-[70px]  rounded-md mt-3 ">
                    <ul className="cursor-pointer font-semibold  px-[20px]  font-poppins flex flex-col justify-center items-center text-md">
                      {cityOptions.map((location) => (
                        <>
                          <div className="py-1 hover:bg-secondary hover:text-white  rounded-md ">
                            <li
                              className=""
                              key={location}
                              onClick={() => {
                                handleLocationSelection(location);
                              }}
                            >
                              {location}
                            </li>
                          </div>
                        </>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}

            {/* <IoLocationOutline
              size={20}
              className="text-[14px] sm:text-[15px]"
            /> */}
            {/* <span className="font-poppins text-[14px] sm:text-[15px]  text-white">
              {selectedCity}
            </span> */}
          </div>
          <div className="lg:hidden top-0 mr-5 ">
            <button
              onClick={handleNavBarMenuToggle}
              className="text-2xl focus:outline-none"
            >
              {navbarVisible ? <FaTimes /> : <FaBars />}
            </button>
          </div>
        </div>

        {navbarVisible && (
          <div className="lg:hidden origin-bottom-left  mt-[68px] w-full bg-white z-40 absolute top-0 right-0 bg-gray-800 p-4 transition-all duration-800 ease-in-out ">
            <div className="flex flex-col space-y-2">
              <Link
                to="/"
                onClick={() => {
                  scrollToTop();
                  handleNavBarMenuToggle();
                }}
                className="hover:text-orange-400 text-xl hover:transition-transform hover:scale-105 duration-300"
              >
                Home
              </Link>
              <Link
                to="/aboutus"
                onClick={() => {
                  scrollToTop();
                  handleNavBarMenuToggle();
                }}
                className="hover:text-orange-400 text-xl hover:transition-transform hover:scale-105 duration-300"
              >
                About
              </Link>
              <Menu as="div">
                <Menu.Button className=" flex flex-row items-center hover:text-orange-400 text-xl hover:transition-transform hover:scale-105 duration-300 ">
                  Services&nbsp;
                  <MdOutlineKeyboardArrowRight className="mt-1" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-100"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-bottom-right absolute font-poppins font-normal left-2 mt-4 w-56 rounded-md shadow-inner bg-white divide-y divide-gray-100 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={() => {
                              scrollToTop();
                              handleNavBarMenuToggle();
                            }}
                            to={redirectUrl_cleaning}
                            className={classNames(
                              active ? " text-secondary" : "text-gray-700",
                              "block px-4 py-2 text-sm font-medium"
                            )}
                          >
                            Cleaning Services
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={() => {
                              scrollToTop();
                              handleNavBarMenuToggle();
                            }}
                            to={redirectUrl_pest}
                            className={classNames(
                              active ? " text-secondary" : "text-gray-700",
                              "block px-4 py-2 text-sm font-medium"
                            )}
                          >
                            Pest Control Services
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={() => {
                              scrollToTop();
                              handleNavBarMenuToggle();
                            }}
                            to={redirectUrl_paint}
                            className={classNames(
                              active ? " text-secondary" : "text-gray-700",
                              "block px-4 py-2 text-sm font-medium"
                            )}
                          >
                            Painting Services
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={() => {
                              scrollToTop();
                              handleNavBarMenuToggle();
                            }}
                            to={redirectUrl_watertank}
                            className={classNames(
                              active ? " text-secondary" : "text-gray-700",
                              "block px-4 py-2 text-sm font-medium"
                            )}
                          >
                            Watertank & Sump Cleaning
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={() => {
                              scrollToTop();
                              handleNavBarMenuToggle();
                            }}
                            to={redirectUrl_waterproofing}
                            className={classNames(
                              active ? " text-secondary" : "text-gray-700",
                              "block px-4 py-2 text-sm font-medium"
                            )}
                          >
                            Waterproofing Services
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={() => {
                              scrollToTop();
                              handleNavBarMenuToggle();
                            }}
                            to={redirectUrl_decoration}
                            className={classNames(
                              active ? " text-secondary" : "text-gray-700",
                              "block px-4 py-2 text-sm font-medium"
                            )}
                          >
                            Decoration Services
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={() => {
                              scrollToTop();
                              handleNavBarMenuToggle();
                            }}
                            to={redirectUrl_cctv}
                            className={classNames(
                              active ? " text-secondary" : "text-gray-700",
                              "block px-4 py-2 text-sm font-medium"
                            )}
                          >
                            CCTV Installation Services
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={() => {
                              scrollToTop();
                              handleNavBarMenuToggle();
                            }}
                            to={redirectUrl_packers}
                            className={classNames(
                              active ? " text-secondary" : "text-gray-700",
                              "block px-4 py-2 text-sm font-medium"
                            )}
                          >
                            Packers & Movers Services
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={() => {
                              scrollToTop();
                              handleNavBarMenuToggle();
                            }}
                            to={redirectUrl_disinfect}
                            className={classNames(
                              active ? " text-secondary" : "text-gray-700",
                              "block px-4 py-2 text-sm font-medium"
                            )}
                          >
                            Disinfection & Sanitization Services
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={() => {
                              scrollToTop();
                              handleNavBarMenuToggle();
                            }}
                            to={redirectUrl_interiodesign}
                            className={classNames(
                              active ? " text-secondary" : "text-gray-700",
                              "block px-4 py-2 text-sm font-medium"
                            )}
                          >
                            Interior Designing Service
                          </Link>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              <Link
                to="/blog"
                onClick={() => {
                  scrollToTop();
                  handleNavBarMenuToggle();
                }}
                className="hover:text-orange-400 text-xl hover:transition-transform hover:scale-105 duration-300"
              >
                Blog
              </Link>
              <Link
                to="/contactus"
                onClick={() => {
                  scrollToTop();
                  handleNavBarMenuToggle();
                }}
                className="hover:text-orange-400 text-xl hover:transition-transform hover:scale-105 duration-300"
              >
                Contact Us
              </Link>
            </div>
          </div>
        )}

        <div
          className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
          id="navbar-user"
        >
          <ul className="items-center justify-between flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-slate-100 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li>
              <Link
                onClick={scrollToTop}
                to="/"
                className="block py-2 px-3 text-white rounded md:bg-transparent md:text-secondary md:p-0 font-poppins "
                aria-current="page"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                onClick={scrollToTop}
                to="/aboutus"
                className={`${styles.navSection} text-black font-poppins font-normal`}
              >
                About
              </Link>
            </li>
            <li>
              <Menu as="div" className="relative inline-block text-left z-40">
                <Menu.Button className=" py-2 px-3 text-black flex-row flex hover:text-secondary font-poppins font-normal">
                  Services&nbsp;
                  <IoIosArrowDown className="mt-1 ml-1" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-100"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-left absolute font-poppins font-normal left-2 mt-4 w-56 rounded-md shadow-inner bg-white divide-y divide-gray-100 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={scrollToTop}
                            to={redirectUrl_cleaning}
                            className={classNames(
                              active ? " text-secondary" : "text-gray-700",
                              "block px-4 py-2 text-sm font-medium"
                            )}
                          >
                            Cleaning Services
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={scrollToTop}
                            to={redirectUrl_pest}
                            className={classNames(
                              active ? " text-secondary" : "text-gray-700",
                              "block px-4 py-2 text-sm font-medium"
                            )}
                          >
                            Pest Control Services
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={scrollToTop}
                            to={redirectUrl_paint}
                            className={classNames(
                              active ? " text-secondary" : "text-gray-700",
                              "block px-4 py-2 text-sm font-medium"
                            )}
                          >
                            Painting Services
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={scrollToTop}
                            to={redirectUrl_watertank}
                            className={classNames(
                              active ? " text-secondary" : "text-gray-700",
                              "block px-4 py-2 text-sm font-medium"
                            )}
                          >
                            Watertank & Sump Cleaning
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={scrollToTop}
                            to={redirectUrl_waterproofing}
                            className={classNames(
                              active ? " text-secondary" : "text-gray-700",
                              "block px-4 py-2 text-sm font-medium"
                            )}
                          >
                            Waterproofing Services
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={scrollToTop}
                            to={redirectUrl_decoration}
                            className={classNames(
                              active ? " text-secondary" : "text-gray-700",
                              "block px-4 py-2 text-sm font-medium"
                            )}
                          >
                            Decoration Services
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={scrollToTop}
                            to={redirectUrl_cctv}
                            className={classNames(
                              active ? " text-secondary" : "text-gray-700",
                              "block px-4 py-2 text-sm font-medium"
                            )}
                          >
                            CCTV Instalation Services
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={scrollToTop}
                            to={redirectUrl_packers}
                            className={classNames(
                              active ? " text-secondary" : "text-gray-700",
                              "block px-4 py-2 text-sm font-medium"
                            )}
                          >
                            Packers & Movers Services
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={() => {
                              scrollToTop();
                              handleNavBarMenuToggle();
                            }}
                            to={redirectUrl_interiodesign}
                            className={classNames(
                              active ? " text-secondary" : "text-gray-700",
                              "block px-4 py-2 text-sm font-medium"
                            )}
                          >
                            Interior Designing Service
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={scrollToTop}
                            to={redirectUrl_disinfect}
                            className={classNames(
                              active ? " text-secondary" : "text-gray-700",
                              "block px-4 py-2 text-sm font-medium"
                            )}
                          >
                            Disinfection & Sanitization Services
                          </Link>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </li>
            <li>
              <Link
                onClick={scrollToTop}
                to="/vendor-form"
                className={`${styles.navSection} font-poppins font-normal`}
              >
                Partner With Us
              </Link>
            </li>
            <li>
              <Link
                onClick={scrollToTop}
                to="/contactus"
                className={`${styles.navSection} font-poppins font-normal`}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
