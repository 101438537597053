import React from 'react'

import {  kitchen } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';


import CleaningServiceModal from './CleaningServicesModel';

import FaqServices from '../../Components/FaqServices';
import Description from '../../Components/Description';

const KitchenCleaning = () => {

  const faqData = [
    {
      question: 'How often should I schedule a professional kitchen cleaning?',
      answer: 'For optimal hygiene, it is recommended to schedule a professional kitchen cleaning service at least once every three months. However, high-traffic kitchens may require more frequent cleanings.',
    },
    {
      question: 'What does the kitchen cleaning service include?',
      answer: 'Our kitchen cleaning service includes the thorough cleaning of countertops, appliances, cabinets, sinks, and other surfaces. We also address grease buildup, sanitize surfaces, and clean kitchen floors.',
    },
    {
      question: 'Do you clean kitchen appliances like ovens and refrigerators?',
      answer: 'Yes, our kitchen cleaning service includes the cleaning of kitchen appliances such as ovens, refrigerators, microwaves, and more. We remove grease, stains, and food residue for a fresh and sanitized kitchen.',
    },
    {
      question: 'Are the cleaning products used in the kitchen safe for food preparation areas?',
      answer: 'Absolutely. We use safe and food-grade cleaning products in kitchen areas. Our goal is to provide a clean and sanitary kitchen environment without compromising food safety.',
    },
    {
      question: 'Can you handle deep cleaning tasks in the kitchen, like inside cabinets and behind appliances?',
      answer: 'Yes, our kitchen cleaning service includes deep cleaning tasks such as cleaning inside cabinets, behind appliances, and other hard-to-reach areas. We ensure a comprehensive cleaning process for a spotless kitchen.',
    },
    {
      question: 'Do you offer eco-friendly cleaning options for kitchen cleaning services?',
      answer: 'Yes, we provide eco-friendly cleaning options for kitchen cleaning services. We use environmentally friendly cleaning products and methods to minimize environmental impact while ensuring a clean and hygienic kitchen environment.',
    },
    {
      question: 'Can you accommodate specific dietary restrictions or preferences during kitchen cleaning?',
      answer: 'Certainly! We understand the importance of accommodating dietary restrictions or preferences during kitchen cleaning. Our team can adjust cleaning products and methods accordingly to meet your specific needs and preferences.',
    },
    {
      question: 'Is there a minimum or maximum kitchen size for your cleaning services?',
      answer: 'No, we cater to kitchens of all sizes, from small residential kitchens to large commercial kitchen spaces. Our cleaning services are scalable and adaptable to accommodate the unique requirements of each kitchen.',
    },
    {
      question: 'Do you provide post-construction cleaning services for kitchens?',
      answer: "Yes, we offer post-construction cleaning services for kitchens. Whether it's a renovation project or new construction, our team is equipped to handle the cleaning of kitchen spaces after construction or remodeling work.",
    },
    {
      question: 'Can you provide regular maintenance cleaning for commercial kitchen facilities?',
      answer: 'Absolutely. We offer regular maintenance cleaning services for commercial kitchen facilities to ensure ongoing cleanliness and compliance with hygiene standards. Our scheduled cleaning plans can be tailored to fit the needs of your business.',
  },  
  ];
  

  const data = {
    headingFirst: "Kitchen",
    headingSecond: "Kitchen",
    price: "₹1399",
    descriptionMeta: "Transform your kitchen into a sparkling sanctuary with our Kitchen Cleaning Service. From countertops to appliances, we'll tackle grease and grime, leaving your culinary space fresh and inviting. Book now for a pristine kitchen!",
    description: [
      "Thorough cleaning and degreasing of kitchen surfaces to restore their original shine. ",
      " Polishing and disinfection of kitchen faucets, sinks, and other fixtures for a sparkling finish.",
      " Comprehensive cleaning and disinfection of kitchen appliances to eliminate grease, stains, and bacteria.",
      "  Streak-free cleaning and refinement of kitchen glass surfaces, including windows and cabinet doors.",
      " Expert cleaning of kitchen floors, removing dirt and grime to create a refreshed and sanitized atmosphere.  ",
      "Inspection and deep cleaning of kitchen exhaust systems to ensure proper ventilation and air quality.",
     
     
     
    ],
    image: kitchen,
    space: "kitchen space",
    size: "kitchen",
    
  
  }
  const kitchenCleaningDescriptionData = {
    headingDescription_1: "Why Kitchen Cleaning Service is Essential",
    paraDescription_1: "Maintaining a clean and hygienic kitchen is crucial for ensuring the health and well-being of your family. The kitchen is often considered the heart of the home, where meals are prepared and memories are made. However, with daily cooking activities, the kitchen can quickly accumulate grease, food spills, and bacteria, leading to unpleasant odors and potential health hazards. Neglecting proper cleaning and maintenance can not only compromise food safety but also attract pests and cause damage to kitchen surfaces and appliances. Professional kitchen cleaning service plays a vital role in thoroughly sanitizing and disinfecting your kitchen, removing stubborn stains, and eliminating harmful germs and bacteria. With specialized cleaning techniques and eco-friendly products, our service ensures that your kitchen remains a clean, safe, and inviting space for cooking and gathering.",
    headingDescription_2: "What We Do in Kitchen Cleaning Service",
    paraDescription_2: [
        "Deep cleaning and sanitization of all kitchen surfaces, including countertops, backsplashes, and appliances.",
        "Degreasing and cleaning of stovetops, range hoods, and oven interiors to remove built-up grease and grime.",
        "Cleaning and disinfecting of sinks, faucets, and drains to prevent bacterial growth and odors.",
        "Washing and sanitizing of kitchen cabinets, drawers, and shelves to remove dust, dirt, and food residue.",
        "Cleaning and polishing of stainless steel appliances to restore shine and minimize fingerprints.",
        "Scrubbing and disinfecting of tile grout to remove mold, mildew, and stains.",
        "Cleaning of kitchen floors, including sweeping, mopping, and spot treating for tough stains.",
        "Disposal of trash and recyclables and thorough cleaning of trash bins to eliminate odors.",
        "Final inspection to ensure a spotless and hygienic kitchen environment.",
        "Customized add-on services available upon request to address specific cleaning needs or concerns."
    ]
};

    
  return (
    <div className=''>
  <CleaningServiceModal {...data} />
  <FaqServices faqData={faqData} />
  <Description {...kitchenCleaningDescriptionData} />
  <Benefits />
  <TestimonialCarousel />
  <Footer />

    </div>
  )
}

export default KitchenCleaning
