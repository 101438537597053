import React from 'react'

import { 
     ant,
     woodboarer,
     mosquito,
     cockroach,
     bedbug,
     termite,
     rodent,
 
 } from '../../assets';

import AllServicePage from "../AllServicePage";

const PestControlService = () => {
  const selectedCity = localStorage.getItem('city')

  const data = {
    headingFirst: " Pest Control" ,
    headingSecond: "Services",
    description: "Transform your environment with KBS Home Service the unrivaled solution for ensuring a pristine and germ-free space. Contact us now to experience a renewed and hygienic living atmosphere ",
    rating: 4.8,
    reviews: "Based on 738 Reviews",
    imageLink1: bedbug,
    link1: `/bedbug-control-in-${selectedCity.toLowerCase()}`,
    name1: "Bedbug Pest Control",
    imageLink2: ant,
    link2:`/ant-pest-control-in-${selectedCity.toLowerCase()}`,
    name2: "Ant Pest Control",
    imageLink3: mosquito,
    link3:`/mosquito-pest-control-in-${selectedCity.toLowerCase()}`,
    name3: "Mosquito Pest Control",
    imageLink4: termite,
    link4:`/termite-pest-control-in-${selectedCity.toLowerCase()}`,
    name4: "Termite Pest Control",
    imageLink5: cockroach,
    link5:`/cockroach-pest-control-in-${selectedCity.toLowerCase()}`,
    name5: "Cockroach Pest Control",
    imageLink6: rodent,
    link6:`/rodent-pest-control-in-${selectedCity.toLowerCase()}`,
    name6: "Rodent Pest Control",
    imageLink7: woodboarer,
    link7:`/wood-boarer-pest-control-in-${selectedCity.toLowerCase()}`,
    name7: "Woodboarer Pest Control",

    descriptionMeta: "Say goodbye to pests with our professional Pest Control Service. From ants to rodents, we'll eliminate unwanted visitors and safeguard your home or business. Protect your space—schedule your pest control service today!",

    bgImageUrl: "https://hybridpestcontrol.com/storage/2021/07/Commercial-and-Residential-Pest-control-UT-hybridpestcontrol-scaled-2560x1280.jpeg",
    serviceType: ["Bedbug pest control","Ant pest control", "Mosquito pest control","Termote pest control","Rodent pest control", "Woodboarer pest control", "Cockroach pest control"]
  }
 
  return (
    <div className="">
    <AllServicePage {...data} />
  </div>
  )
}

export default PestControlService
