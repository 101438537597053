import React from 'react'
//import { FaStar, FaAngleDown , FaPhone, FaPhoenixFramework } from "react-icons/fa";
import {  sumpcleaning } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';

import WatertankModal from './WatertankModal';
import FaqServices from '../../Components/FaqServices';
import Description from '../../Components/Description';

const SumpCleaning = () => {

  const data = {
    headingFirst: " Sump",
    headingSecond: " Sump",
    price: "₹3999",
    descriptionMeta: "Maintain optimal drainage with our thorough Sump Cleaning Service. Our experts will remove debris and buildup, ensuring your sump functions efficiently. Prevent clogs—schedule your sump cleaning service now!",
    description: [
      "Thorough cleaning of sump and water storage tanks",
      "Removal of sediment, debris, and contaminants from the sump",
      "Scrubbing and disinfection of sump walls and surfaces",
      "Inspection for any cracks, leaks, or damages in the sump structure",
      "Cleaning and disinfection of water pipes connected to the sump",
      "Removal of algae or any organic growth in the sump",
      "Proper disposal of waste generated during the cleaning process",
      "Ensuring water quality and purity after the cleaning process",
      "Detailed inspection report outlining the condition of the sump",
      "Recommendations for maintenance and preventive measures",
      "Use of eco-friendly and safe cleaning agents for sump cleaning",
    ],
    image: sumpcleaning,
    space: "sump space",
    size: "sump",
    
  
  }
  const sumpCleaningFAQ = [
    {
      question: 'Why is sump cleaning necessary?',
      answer: 'Sump cleaning is necessary to remove accumulated sediment, debris, and contaminants from the water storage tank. Regular cleaning ensures the water remains clean and safe for consumption.',
    },
    {
      question: 'How often should I schedule sump cleaning?',
      answer: 'It is recommended to schedule sump cleaning at least once a year to maintain the quality of stored water and prevent the growth of bacteria and impurities.',
    },
    {
      question: 'Can sump cleaning help improve water pressure?',
      answer: 'Yes, sump cleaning can improve water pressure by removing blockages and sediment that may be affecting the flow of water from the storage tank.',
    },
    {
      question: 'Is it safe to use water immediately after sump cleaning?',
      answer: 'It is advisable to wait for a short period after sump cleaning to allow any residual cleaning agents to dissipate. After that, the water is safe for use.',
    },
    {
      question: 'Do you provide sump maintenance services?',
      answer: 'Yes, in addition to sump cleaning, we offer sump maintenance services to inspect and ensure the proper functioning of the pump and other components.',
    },
    {
      question: 'What are the signs that my sump pump needs cleaning?',
      answer: 'Signs that your sump pump needs cleaning include strange noises during operation, reduced water flow, foul odors from the sump pit, and visible debris or sediment in the water.',
    },
    {
      question: 'Can I clean my sump pump myself, or should I hire professionals?',
      answer: 'While some basic maintenance tasks like removing debris can be done DIY, thorough sump pump cleaning often requires professional expertise and equipment to ensure proper cleaning and maintenance.',
    },
    {
      question: 'How long does sump cleaning usually take?',
      answer: 'The duration of sump cleaning depends on factors such as the size of the sump pit, the extent of sediment buildup, and any additional maintenance tasks required. On average, it may take a few hours.',
    },
    {
      question: 'Does sump cleaning help prevent sump pump failures?',
      answer: 'Yes, regular sump cleaning helps prevent sump pump failures by reducing strain on the pump, improving water flow, and ensuring proper drainage, thereby extending the lifespan of the sump pump.',
    },
    {
      question: 'What are the benefits of professional sump cleaning services?',
      answer: 'Professional sump cleaning services offer expertise, specialized equipment, thorough cleaning, and maintenance inspections, ensuring optimal sump pump performance and water quality.',
    },
  ];
  const sumpCleaningDescriptionData = {
    headingDescription_1: "Why Sump Cleaning Service is Essential",
    paraDescription_1: "Ensuring the cleanliness and hygiene of your sump is crucial for maintaining the quality of your water supply and preventing contamination. Over time, sumps can accumulate sediment, debris, and microbial growth, leading to foul odors, discoloration, and potential health hazards. Neglecting sump cleaning can compromise the safety and purity of your water, putting you and your family at risk of waterborne diseases. Professional sump cleaning service plays a vital role in removing accumulated contaminants, disinfecting the sump, and ensuring the integrity of your water supply. With specialized equipment and expertise, our service effectively eliminates impurities, restores water quality, and safeguards your health. Investing in professional sump cleaning is essential for maintaining clean, safe, and reliable water for your household needs.",
    headingDescription_2: "What We Do in Sump Cleaning Service",
    paraDescription_2: [
        "Draining the sump to remove stagnant water and sediment buildup.",
        "Scrubbing and disinfecting the interior walls and floor of the sump to eliminate microbial growth and odor.",
        "Inspecting the sump for cracks, leaks, or structural issues that may compromise its functionality.",
        "Removing debris, sediment, and other contaminants from the sump to improve water quality.",
        "Applying safe and effective disinfectants to sanitize the sump and prevent bacterial growth.",
        "Flushing and rinsing the sump thoroughly to remove residual contaminants and cleaning agents.",
        "Performing a final inspection to ensure the sump is clean, watertight, and functioning properly.",
        "Providing recommendations for regular maintenance and scheduling future cleaning appointments as needed.",
        "Emergency services available for sump cleaning and repairs to address urgent issues and ensure uninterrupted water supply.",
        "Disinfecting and cleaning the sump pump to maintain optimal performance.",
        "Removing mineral deposits and scaling from sump components for improved efficiency.",
        "Educating customers on proper sump maintenance practices to prevent future contamination and prolong the lifespan of the sump."
    ]
}

  
  
  
  
  return (
    <div className='main'>
       <WatertankModal {...data} />
       <FaqServices faqData={sumpCleaningFAQ} />
       <Description {...sumpCleaningDescriptionData} />
       <Benefits />
       <TestimonialCarousel />
       <Footer />
       
    </div>
  )
}

export default SumpCleaning
