import React from 'react'

import {  homesanitize } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';

import DisinfectionModal from './DisinfectionModal';

import FaqServices from '../../Components/FaqServices';
import Description from '../../Components/Description';

const HomeSanitization = () => {

  const data = {
    headingFirst: "Home Sanitization",
    headingSecond: "Home Sanitization",
    price: "₹4999",
    descriptionMeta: "Safeguard your loved ones and create a healthy living environment with our effective Home Sanitization Service. Using advanced sanitization techniques, we'll rid your home of germs and bacteria, promoting a clean and hygienic space for your family. Protect your home—schedule your sanitization service now! ",
    description: [
      "Comprehensive disinfection of high-touch surfaces",
      "Sanitization of frequently used areas and objects",
      "Sterilization of doorknobs, light switches, and handles",
      "Disinfecting electronic devices and gadgets",
      "Thorough cleaning and sanitization of bathroom fixtures",
      "Surface disinfection in kitchen spaces",
      "Sanitizing furniture, including sofas, chairs, and mattresses",
      "Elimination of germs and pathogens on carpets and curtains",
      "Sanitization of air vents and HVAC systems",
      "Deep cleaning and disinfection of cabinets and storage spaces",
      "Special attention to commonly touched items like remote controls",
      "Sanitizing home appliances such as refrigerators and microwaves"
    ],
    image: homesanitize,
    space: "living space",
    size: "house",
    
  
  
  }
  const homeSanitizationFAQ = [
    {
      question: 'How frequently should I schedule home sanitization?',
      answer: 'The frequency of home sanitization depends on various factors, including the size of your home and the level of foot traffic. In general, it is recommended to schedule sanitization every 3 to 6 months for optimal cleanliness.',
    },
    {
      question: 'Is home sanitization effective against viruses and bacteria?',
      answer: 'Yes, our home sanitization service is designed to effectively eliminate a broad spectrum of viruses, bacteria, and other harmful microorganisms. We use industry-approved sanitization products and techniques.',
    },
    {
      question: 'Are the sanitization products safe for pets and children?',
      answer: 'Yes, the sanitization products we use are safe for pets and children. We prioritize using non-toxic and eco-friendly sanitization solutions to ensure a safe and healthy environment.',
    },
    {
      question: 'How long does the home sanitization process take?',
      answer: 'The duration of the home sanitization process depends on the size of your home. On average, it may take 1 to 2 hours to thoroughly sanitize all living spaces and surfaces.',
    },
    {
      question: 'Do you offer any post-sanitization recommendations?',
      answer: 'After home sanitization, it is advisable to ventilate the treated areas for a few hours. Additionally, regular cleaning practices and hand hygiene should be maintained to ensure ongoing cleanliness.',
    },
    {
      question: 'How often should I sanitize my home?',
      answer: 'We recommend sanitizing your home every 3 to 6 months for optimal cleanliness, depending on factors like home size and foot traffic.',
    },
    {
      question: 'Is home sanitization effective against viruses?',
      answer: 'Yes, our home sanitization service effectively eliminates viruses, bacteria, and other harmful microorganisms using industry-approved products and techniques.',
    },
    {
      question: 'Are sanitization products safe for pets and children?',
      answer: 'Yes, we prioritize using non-toxic and eco-friendly sanitization products that are safe for pets and children.',
    },
    {
      question: 'How long does home sanitization take?',
      answer: 'The duration of home sanitization varies based on home size, typically taking 1 to 2 hours to thoroughly sanitize all living spaces and surfaces.',
    },
    {
      question: 'Do you provide post-sanitization recommendations?',
      answer: "After home sanitization, it's recommended to ventilate treated areas for a few hours and maintain regular cleaning practices and hand hygiene for ongoing cleanliness.",
    },
  ];
  
  const descriptionData = {
    headingDescription_1: "Why Professional Home Sanitization is Essential",
    paraDescription_1: "Ensuring a clean and sanitized home environment is paramount for safeguarding the health and well-being of your family, especially in today's world. Harmful pathogens, including viruses, bacteria, and germs, can lurk on surfaces and in the air, posing a risk of illness and infection. Regular cleaning may not be sufficient to eliminate these invisible threats. Professional home sanitization service provides a comprehensive solution to disinfect your home thoroughly, targeting high-touch surfaces and hidden areas where germs can proliferate. By utilizing advanced sanitization techniques and hospital-grade disinfectants, our service helps create a safe and hygienic living space for you and your loved ones, reducing the risk of illness and promoting peace of mind.",
    headingDescription_2: "What We Do in Professional Home Sanitization",
    paraDescription_2: [
        "Deep cleaning and disinfection of all surfaces, including countertops, tables, and door handles.",
        "Sanitization of electronic devices, such as remote controls, keyboards, and smartphones.",
        "Disinfection of frequently touched surfaces, including light switches, doorknobs, and stair railings.",
        "Application of EPA-approved disinfectants that are effective against a broad spectrum of pathogens, including viruses and bacteria.",
        "Sanitization of soft furnishings, upholstery, and carpets to eliminate allergens and microbial contaminants.",
        "Steam cleaning of hard-to-reach areas and porous surfaces to ensure thorough disinfection.",
        "Air purification using HEPA filtration systems to remove airborne particles and improve indoor air quality.",
        "Treatment of HVAC systems to prevent the spread of airborne contaminants and allergens.",
        "Ongoing maintenance and follow-up services to ensure continued protection and peace of mind.",
        "Customized sanitization plans available for specific needs or concerns, including post-illness disinfection and allergy control."
    ]
};

  
  
  return (
    <div className='main'>
       <DisinfectionModal {...data} />
       <FaqServices faqData={homeSanitizationFAQ} />
       <Description {...descriptionData} />
       <Benefits />
       <TestimonialCarousel />
       <Footer />
    </div>
  )
}

export default HomeSanitization
