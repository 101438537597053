import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { FaAngleDown, FaMapMarkerAlt, FaSearch } from "react-icons/fa";
import { banner, banner1, banner2, banner3, placeholder_3 } from "../assets";
import Footer from "../Components/Footer";
import ServiceCard from "../Components/ServiceCard";
import Services from "./Services";
import Benefits from "./Benefits";
import Faq from "./FAQ";

import { Link } from "react-router-dom";
import styles from "../style";
import WhyUs from "./WhyUs";
import { motion } from "framer-motion";
import TestimonialCarousel from "./Testimonials";
import { MdOutlineLocationOn } from "react-icons/md";


import {
  cityOptionsObj,
  serviceOptionsObj,
  cityOptions,
  serviceOptions,
} from "../constants";

import AutoSlider from "../Components/Slider";
import MetaData from "../Metadata";

const Hero = () => {
  const navigate = useNavigate();
  const city = useParams();
  const service = useParams();
  // console.log(city.city,"CityName");

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [selectedService, setSelectedService] = useState("");
  const [selectedCity, setSelectedCity] = useState(
    cityOptionsObj[city.city] || "Bangalore"
  );
  // console.log(localStorage.getItem('city'),"CityStore");

  useEffect(() => {
    !city?.city && localStorage.setItem("city", "Bangalore");
  }, [selectedCity]);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const hasModalBeenOpened = sessionStorage.getItem("hasModalBeenOpened");
    if (!hasModalBeenOpened) {
      setShowModal(true);
      sessionStorage.setItem("hasModalBeenOpened", true);
    }
  }, []);

  useEffect(() => {
    if (showModal && window.innerWidth <= 1060) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [showModal]);

 
  const handleLocationSelection = (selectedLocation) => {

    console.log("Location selected:", selectedLocation); 
    localStorage.setItem('hasModalBeenOpened', true);
    setSelectedCity(selectedLocation);
    localStorage.setItem("city", selectedLocation);
    setShowModal(false); 
    document.body.classList.remove('overflow-hidden');
    navigate(`/${selectedLocation.toLowerCase()}`)
   
  };
 

  const [currentSlide, setCurrentSlide] = useState(0);
  const images = [banner1, banner2, banner3];
  const [dotColors, setDotColors] = useState(["white", "gray", "gray"]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
      updateDotColors();
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const updateDotColors = () => {
    const newDotColors = dotColors.map((color, index) =>
      index === currentSlide ? "white" : "gray"
    );
    setDotColors(newDotColors);
  };

  return (
    <>
    <MetaData title="KBS Home Services" />
      {" "}
      <div className={` ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <div className="bg-white w-full mt-6 sm:h-auto flex flex-col justify-center items-center">
            <div className="flex flex-col w-full  sm:p-2 ">
              {/* <div className="circlePosition w-[100px] h-[100px] bg-orange-400 rounded-[100%] absolute z-1 top-[70%] right-[0%] translate-x-[-50%] translate-y-[-50%] blur-[20px]"></div>
            
            <div className="circlePosition w-[100px] h-[100px] bg-orange-400 rounded-[100%] absolute z-1 top-[35%] right-[25%] translate-x-[-50%] translate-y-[-50%] blur-[20px]"></div> */}

            <div class="circlePosition  hidden lg:flex sm:w-[540px] w-[300px] sm:h-[400px] h-[300px] bg-orange-400 rounded-[100%] absolute z-1 sm:top-[58%] top-[36%] left-[10%] sm:translate-x-[-50%] translate-x-[-30%] translate-y-[-50%] sm:blur-[150px] blur-[100px] "></div>

              <div className="flex lg:hidden w-full">
                <AutoSlider />
              </div>
              <div className="w-full hidden lg:flex flex-col items-center md:flex-row sm:gap-0  ">
                <div className="justify-center moto w-auto sm:w-full flex flex-col text-white opacity-0 sm:opacity-100 font-semibold text-4xl  sm:bg-white/70 bg-white/50 sm:rounded-l-3xl relative z-10 p-[20px] backdrop-blur-[20px] sm:ml-5 ml-0 ">
                  <div className="flex flex-row justify-between items-center w-full z-10 mt-6">
                    <motion.h1
                      initial={{ y: "2rem", opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{
                        duration: 2,
                        type: "spring",
                        delay: 0.75,
                      }}
                      className="flex-1 font-poppins font-bold ss:text-[65px] text-[33px] text-orange-400 ss:leading-[70.8px] leading-[45px]"
                    >
                      Spotless Cleaning, <br className="sm:block hidden" />{" "}
                      <span className="font-poppins text-gradient font-bold tracking-wider sm:text-[50px] text-[25px]">
                        Timeless Services
                      </span>{" "}
                    </motion.h1>
                  </div>
                  <motion.p
                    initial={{ y: "2rem", opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                      duration: 2,
                      type: "spring",
                      delay: 1.05,
                    }}
                    className={`font-poppins font-normal sm:text-[18px] text-[16px] ss:leading-[30px] leading-[25px] text-black max-w-[670px] sm:mt-5 mt-3 text-justify`}
                  >
                    Experience the KBS difference! Elevate your home with meticulous cleaning and exceptional service. Book now for a radiant, refreshed living space!
                  </motion.p>
                  <div className="contactbutton z-60  ">
                    <Link to="/contactus">
                      {" "}
                      <button className="bg-secondary mt-7 font-poppins cursor-pointer text-white sm:px-4 px-3 sm:py-3 py-2 text-md sm:rounded-md  rounded-xl text-lg hover:bg-[#FB8B24]  hover:text-white">
                        Contact Us
                      </button>
                    </Link>
                  </div>
                </div>

                <div className="w-full ml-0 md:ml-10 md:flex hidden  items-center md:flex-row overflow-hidden md:mt-[40px]">
                  <img
                  loading="lazy"
                    className="w-[100%] h-[100%] "
                    src={banner}
                    alt="banner"
                  />
                </div>
              </div>
            </div>
          </div>
          {/*---------other sections--------- */}
          <div
            className={`bg-[#F5F7F8] flex justify-center items-center ${styles.flexStart}`}
          >
            <div className={`${styles.boxWidth} `}>
              {/* search bar Start*/}

               <div className="searchbar hidden md:flex flex-col md:flex-row md:gap-0 gap-4 justify-center items-center mt-20 ">
                <div className="flex justify-center items-center ">
                  <div className="">
                    <div className="relative ">
                      <div className="pointer-events-none text-secondary absolute inset-y-0 left-4 flex items-center px-2 ">
                        <FaMapMarkerAlt size={20} />
                      </div>

                      <select
                        className="block appearance-none font-poppins  sm:rounded-l-xl text-xl bg-white shadow-xl text-gray-700 sm:w-[270px] w-[300px]  py-5 px-[60px] pr-8 leading-tight focus:outline-none focus:ring"
                        value={selectedCity}
                        onChange={(e) => {
                          // console.log("cityName",e.target.value)
                          setSelectedCity(cityOptionsObj[city.city]);
                          navigate(`/${e.target.value.toLowerCase()}`);
                          localStorage.setItem("city", e.target.value);
                        }}
                      >
                        {cityOptions.map((city, index) => (
                          <option key={index} value={city}>
                            {city}
                          </option>
                        ))}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-5 flex items-center px-2 text-secondary">
                        <FaAngleDown size={20} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="hidden md:flex flex-row gap-4 justify-center items-center  ">
                  <div className="">
                    <div className="relative ">
                      <select
                        className="block appearance-none text-xl font-poppins  w-[400px] lg:w-[650px] bg-white border-l-2 border-slate-300 text-gray-700 py-5 px-10 pr-8  rounded-md md:rounded-r-xl  leading-tight focus:outline-none  focus:ring shadow-xl"
                        value={selectedService}
                        
                        onChange={(e) => {
                          // console.log("selectServices", e.target.value);
                          setSelectedCity(serviceOptionsObj[service.service]);
                          navigate(`/${e.target.value}-in-${selectedCity}`);
                          scrollToTop();
                          
                        }}
                      >
                        {serviceOptions.map((service, index) => (
                          <option key={index} value={service}>
                            {service}
                          </option>
                        ))}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center  sm:mr-4 md:mr-6 text-secondary pr-2">
                        <FaSearch size={20} />
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
              {/* search bar End */}

              {/* Modal Starts */}

              {showModal && (
                  <>
                  <div className="flex lg:hidden">
                    <div className="absolute  inset-0 bg-black z-70 opacity-90"></div>
                  <div className="bg-white  z-70 h-auto w-[330px] xs:w-[380px] ss:w-[500px] sm:w-[600px]  flex flex-col justify-start items-start sm:items-center rounded-2xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <div className="w-full cityBorder flex flex-row justify-center items-center bg-secondary   px-3 rounded-t-2xl p-5 relative" style={{borderBottom: '5px dashed slate-300'}}>
                  <img src={placeholder_3}  alt="image" className={`w-[90px] `}
  />
                  <h2 className=" text-xl xs:text-3xl opacity-90  font-poppins font-bold mt-8 text-white">Pick Your City,<br /> Let the Clean Begin</h2>
                  </div>
                  <div className="bglocation2 flex justify-start items-start w-full flex-col">
                  {/* <h1 className="flex flex-row justify-center items-center  px-5 py-4 gap-1">
                        <img src={destination}   alt="India" className="w-[55px] " />
                        <h2 className="font-semibold text-2xl italic text-black">Choose your spot</h2>
                        </h1>
              <div className="bg-slate-300 px-5 w-full justify-center h-[1px] items-center mb-5"></div> */}
                        
                  <ul className="grid grid-cols-2 gap-3 py-5">
                    
                    {cityOptions.map((location) => (
                      <>
                        <div className="citiname  px-2 py-1 hover:bg-secondary hover:scale-105 hover:translate-x-5 transition-transform ease-in-out duration-300 rounded-2xl hover:text-white ${selectedCity === location ? 'active' : ''}`}">
                        <li
                          key={location}
                          className="cursor-pointer  font-poppins flex flex-row  gap-0.5  items-center text-lg"
                          onClick={() => {   handleLocationSelection(location) } }>
                          <MdOutlineLocationOn className="w-[20px] mt-1.4" />
                          {location}
                        </li>
                      </div></>
                    ))}
                  </ul>
                  </div>
                </div></div></>
        
        
          
        
      )}
              

              <ServiceCard />
              <Services />
              <WhyUs />
              <Faq />
              <Benefits />

              <TestimonialCarousel />
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </>
  );
};

export default Hero;
