import React from 'react'

import {  sumpproofing } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';

import WaterproofModal from './WaterproofModal';
import FaqServices from '../../Components/FaqServices';
import Description from '../../Components/Description';

const SumpProofing = () => {

  const data = {
    headingFirst: " Sump Proofing",
    headingSecond: " Sump Proofing",
    price: "₹4999",
    descriptionModal: "Prevent water seepage and flooding with our reliable Sump Proofing Service. Our solutions will fortify your sump against leaks and ensure efficient drainage. Protect your property—schedule your sump proofing service now! ",
    description: [
      "Evaluation and repair of sump structures",
    "Application of waterproofing solutions to prevent water seepage",
    "Sealing of cracks and joints in the sump area",
    "Installation of sump pump systems for efficient water drainage",
    "Inspection and maintenance of existing sump pump systems",
    "Waterproofing of basement walls and floors",
    "Protection against dampness and moisture in the sump",
    "Use of quality sealants and membranes for long-lasting results",
    "Thorough assessment of sump condition and potential vulnerabilities",
    "Prevention of mold and mildew growth in the sump area",
    "Professional consultation on sump maintenance and care",
    ],
    image: sumpproofing,
    space: "living space",
    size: "house",
    
  
  }
  const sumpProofingFAQ = [
    {
      question: 'What is sump proofing, and why is it important?',
      answer: 'Sump proofing is a waterproofing technique designed to prevent water seepage and leakage in basements or sump areas. It is important to protect the foundation from water damage and ensure a dry and secure environment.',
    },
    {
      question: 'How do I know if my property needs sump proofing?',
      answer: "Signs that your property may need sump proofing include water seepage, dampness, or mold in the basement or sump area. If you notice any of these signs, it's advisable to consider sump proofing.",
    },
    {
      question: 'What does the sump proofing process involve?',
      answer: 'The sump proofing process typically includes a thorough inspection of the sump area, identification of potential entry points for water, application of waterproofing materials, and installation of drainage systems to redirect water away from the foundation.',
    },
    {
      question: 'How long does sump proofing last?',
      answer: 'The longevity of sump proofing depends on various factors such as the quality of materials used, the severity of the water issue, and maintenance. A professionally done sump proofing job can last for many years.',
    },
    {
      question: 'Can sump proofing be done on both new and existing properties?',
      answer: 'Yes, sump proofing can be applied to both new constructions as a preventive measure and existing properties to address water issues. The approach may vary based on the specific needs of the property.',
    },
    {
      question: 'What are the common causes of water seepage in basements or sump areas?',
      answer: 'Common causes of water seepage include poor drainage, cracks in the foundation, high water table levels, hydrostatic pressure, and inadequate waterproofing.',
    },
    {
      question: 'Is sump proofing a DIY project, or should I hire professionals?',
      answer: 'While some minor sump proofing tasks may be DIY-friendly, such as sealing small cracks, comprehensive sump proofing typically requires professional expertise and equipment to ensure effectiveness.',
    },
    {
      question: 'How much does sump proofing typically cost?',
      answer: 'The cost of sump proofing varies depending on factors such as the size of the area, extent of water damage, chosen waterproofing materials, and labor costs. It is best to request quotes from reputable contractors for accurate pricing.',
    },
    {
      question: 'Does sump proofing increase the property value?',
      answer: 'Investing in sump proofing can increase the property value by enhancing its structural integrity, preventing water damage, and creating a more attractive selling point for potential buyers.',
    },
    {
      question: 'Are there any maintenance tasks required after sump proofing?',
      answer: 'While sump proofing is a long-term solution, periodic maintenance such as inspecting and clearing drainage systems, checking for new cracks, and reapplying sealants may be necessary to ensure continued effectiveness.',
    },
  ];
  const sumpProofingDescriptionData = {
    headingDescription_1: "Why Sump Proofing Service is Essential",
    paraDescription_1: "Protecting your home's sump from water damage is crucial to maintain its structural integrity and prevent costly repairs. Sumps are prone to leakage and seepage, especially in areas with high groundwater levels or poor drainage. Water infiltration can lead to mold growth, foundation damage, and compromised indoor air quality. Sump proofing service provides a reliable solution to waterproof your sump and safeguard your property against water-related issues. By applying specialized waterproofing membranes and sealants, our service effectively seals cracks and gaps, preventing water penetration and ensuring long-lasting protection. Investing in sump proofing service is essential for maintaining a dry, healthy, and structurally sound home.",
    headingDescription_2: "What We Do in Sump Proofing Service",
    paraDescription_2: [
        "Thorough inspection of the sump to assess existing damage and determine the appropriate waterproofing solution.",
        "Preparation of the surface by cleaning and repairing cracks, joints, and other imperfections.",
        "Application of waterproofing membranes and sealants to the interior and exterior surfaces of the sump.",
        "Installation of drainage systems and sump pumps to redirect water away from the sump and prevent accumulation.",
        "Reapplication of waterproofing coatings as necessary to ensure maximum protection and durability.",
        "Final inspection to verify the effectiveness of the sump proofing treatment and ensure customer satisfaction.",
        "Customized maintenance plans and warranty options available for ongoing protection and peace of mind.",
        "Integration of innovative technologies, such as moisture sensors and leak detection systems, for proactive monitoring and early detection of potential issues.",
        "Education and guidance on proper sump maintenance practices to prolong the lifespan of the waterproofing system and maximize its effectiveness.",
        "Collaboration with certified waterproofing experts and engineers to design tailored solutions for unique sump configurations and challenges.",
        "Environmental considerations, such as eco-friendly waterproofing materials and sustainable practices, to minimize ecological impact and promote responsible stewardship.",
        "Continuous research and development to stay abreast of the latest advancements in waterproofing technology and methodologies, ensuring optimal results and customer satisfaction."
    ]
};


  
   
  return (
    <div className='main'>
       <WaterproofModal {...data} />
       <FaqServices faqData={sumpProofingFAQ} />
       <Description {...sumpProofingDescriptionData} />
       <Benefits />
       <TestimonialCarousel />
       <Footer />
    </div>
  )
}

export default SumpProofing
