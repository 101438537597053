import React from 'react'

import { 
  sumpcleaning,
  watertankcleaning,
 
 } from '../../assets';

import AllServicePage from "../AllServicePage";

const WaterTank = () => {

  const selectedCity = localStorage.getItem('city')
  const data = {
    headingFirst: "Water Tank & Sump" ,
    headingThird: "Cleaning",
    headingSecond: " Cleaning Services",
    description: "Transform your environment with KBS Home Service the unrivaled solution for ensuring a pristine and germ-free space. Contact us now to experience a renewed and hygienic living atmosphere ",
    rating: 4.7,
    reviews: "Based on 457 Reviews",
    imageLink1: sumpcleaning,
    link1:`/sump-cleaning-in-${selectedCity.toLowerCase()}`,
    name1: "Sump ",
    imageLink2: watertankcleaning,
    link2:`/water-tank-cleaning-in-${selectedCity.toLowerCase()}`,
    name2: "Water Tank ",
    description: "Ensure clean and safe water with our professional Water Tank Cleaning Service. We'll remove sediment and contaminants, preserving water quality and health. Maintain hygiene—schedule your water tank cleaning today!",
    bgImageUrl: "https://www.powerfm.com.pk/wp-content/uploads/2023/08/Water-Tank-Cleaning-Services-in-Karachi-1-1024x576.jpg.webp",
    serviceType: ["Sump cleaning" , "Water tank cleaning"]
   
  }

  return (
    <div className="">
    <AllServicePage {...data} />
  </div>
  )
}

export default WaterTank
