import React from 'react'

import {  interiorpaint } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';

import PaintModal from './PaintModal';
import FaqServices from '../../Components/FaqServices';
import Description from '../../Components/Description';

const InteriorPaint = () => {

   

    const data = {
      headingFirst: "Interior",
      headingSecond: "Interior",
      price: "₹2999",
      descriptionMeta: "Revitalize your indoor space with our comprehensive Interior Paint Service. From accent walls to full-room makeovers, we'll refresh your interiors with high-quality paints and professional craftsmanship. Transform your home or office—schedule your interior paint service today!",

      description: [
        "Surface preparation, including patching and sanding",
    "Priming of walls and ceilings",
    "Application of high-quality paint in the chosen color(s)",
    "Trim and molding painting",
    "Accent wall painting, if desired",
    "Protection of floors and furniture during the painting process",
    "Cleaning and removal of paint splatters or drips",
    "Touch-up work to ensure a flawless finish",
    "Consultation on color selection and paint types",
    "Thorough inspection for quality assurance",
    "Prompt and efficient service to minimize disruption",
      ],
      image: interiorpaint,
      space: "living space",
      size: "house",
      
    
    }
    const interiorPaintFAQ = [
      {
        question: 'How long does it take for interior paint to dry?',
        answer: 'The drying time for interior paint varies based on factors like humidity and ventilation. In general, it takes around 2 to 4 hours for the paint to dry to the touch, and it may take up to 14 days to fully cure.',
      },
      {
        question: 'Can you paint over existing wallpaper?',
        answer: "While it's possible to paint over wallpaper, it's generally recommended to remove the wallpaper for better adhesion and a smoother finish. Our professionals can assess the condition and provide guidance.",
      },
      {
        question: 'What types of paint do you use for interior painting?',
        answer: 'We use high-quality, low-VOC (volatile organic compounds) or zero-VOC paints to ensure a safe and environmentally friendly interior painting process. We can discuss paint options based on your preferences and needs.',
      },
      {
        question: 'Is it necessary to move furniture before interior painting?',
        answer: "To achieve thorough and even painting, it's advisable to move furniture away from the walls. Our team can assist in moving and covering furniture to protect it during the painting process.",
      },
      {
        question: 'How long does an average interior paint job take?',
        answer: 'The duration of an interior paint job depends on factors like the size of the space, the number of rooms, and any additional preparations needed. On average, a standard room may take a day or two.',
      },
      {
        question: 'Do you provide color consultation services for interior painting?',
        answer: 'Yes, we offer color consultation services to help you choose the perfect paint colors that suit your style, preferences, and the atmosphere you want to create in your home.',
      },
      {
        question: 'Can you paint trim and moldings as part of interior painting?',
        answer: 'Yes, our interior painting services include painting trim, moldings, and other architectural details to ensure a cohesive and finished look throughout your space.',
      },
      {
        question: 'Do you offer eco-friendly paint options?',
        answer: 'Yes, we provide eco-friendly paint options that are low in volatile organic compounds (VOCs) and have minimal environmental impact. These paints are safe for your health and the environment.',
      },
      {
        question: 'What preparations are needed before interior painting?',
        answer: 'Before painting, we prepare the surfaces by cleaning, sanding, and patching any imperfections to ensure a smooth and even finish. We also protect floors and furniture with drop cloths and painter’s tape.',
      },
      {
        question: 'Can you paint ceilings as part of interior painting?',
        answer: 'Yes, our interior painting services include painting ceilings to freshen up the look of your space and provide a uniform appearance throughout the room.',
      },
    ];
    const descriptionData = {
      headingDescription_1: "Why Professional Interior Painting is Essential for Your Home",
      paraDescription_1: "Transforming the interior of your home with a fresh coat of paint is more than just a cosmetic upgrade—it's a transformative experience that enhances the ambiance, improves air quality, and adds value to your property. Over time, walls can accumulate dirt, stains, and signs of wear and tear, detracting from the overall aesthetics and comfort of your living space. Professional interior painting service plays a vital role in revitalizing your home's interior, providing a clean, vibrant, and welcoming environment for you and your family to enjoy. With meticulous preparation, skilled craftsmanship, and premium-quality paints, our service delivers long-lasting results that elevate the beauty and functionality of your home.",
      headingDescription_2: "What We Do in Professional Interior Painting Service",
      paraDescription_2: [
          "Consultation with our expert painters to discuss color options, finishes, and design preferences.",
          "Preparation of surfaces by filling cracks, sanding rough areas, and priming bare walls for optimal paint adhesion.",
          "Protection of floors, furniture, and fixtures with drop cloths and masking tape to prevent paint splatter and damage.",
          "Application of high-quality paints and finishes using professional techniques, such as brushing, rolling, and spraying.",
          "Detailed cutting-in around edges and corners to ensure clean lines and seamless transitions between colors.",
          "Multiple coats of paint applied evenly for uniform coverage and depth of color.",
          "Cleanup of work area, removal of masking materials, and disposal of debris and leftover paint.",
          "Final inspection to ensure quality craftsmanship, color consistency, and customer satisfaction.",
          "Additional services available, including decorative painting techniques, accent walls, and trim painting."
      ]
  }
  
    
  return (
    <div className='main'>
<PaintModal {...data} />
<FaqServices faqData={interiorPaintFAQ } />
<Description {...descriptionData} />
<Benefits />
<TestimonialCarousel />
<Footer />
    </div>
  )
}

export default InteriorPaint
