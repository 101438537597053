import React  from "react";

import {
  
  carwash,
  interiorclean,
  pressurewash,
} from "../../assets";

import AllServicePage from "../AllServicePage";

const CarWashing = () => {

    const selectedCity = localStorage.getItem('city')
  const data = {
    headingFirst: " Car",
    headingSecond: "Washing Services",
    description:
      "Transform your environment with KBS Home Service the unrivaled solution for ensuring a pristine and germ-free space. Contact us now to experience a renewed and hygienic living atmosphere ",
    rating: 4.8,
    reviews: "Based on 452 Reviews",
    imageLink1: carwash,
    link1: `/car-polish-service-in-${selectedCity}`,
    name1: "Car Cleaning and Polishing",
    imageLink2: interiorclean,
    link2: `/interior-car-service-in-${selectedCity}`,
    name2: "Interior Car Cleaning", 
    imageLink3: pressurewash,
    link3: `/pressure-car-service-in-${selectedCity}`,
    descriptionMeta: "Keep your car looking pristine with our expert Car Washing Service. From exterior shine to interior detailing, we'll ensure your vehicle dazzles on the road. Schedule your wash today!",
    name3: "Pressure Washing",
    serviceType: ["Exterior Car Cleaning" , "Interior Car Cleaning" , "Pressure Car Washing"]
   
  };
  

  
  return (
    <div className="">
      <AllServicePage {...data} />
    </div>
  );
};

export default CarWashing;
