import React, { useState } from "react";

import { faq } from "../assets";

import { IoIosArrowDown } from "react-icons/io";
import { FaPlusCircle , FaMinusCircle, FaAngleDown, FaAngleUp } from "react-icons/fa";
// import { IoIosArrowDown } from "react-icons/io";

import { AnimatePresence, motion } from "framer-motion";


const Faq = () => {
  const [faqs, setFaqs] = useState([
    {
      question: "What cleaning services does KBS Home Services offer?", 
      answer:
        "KBS Home Services provides a wide range of cleaning services, including basic house cleaning, deep house cleaning, office cleaning, villa cleaning, disinfection services, water tank and sump cleaning, vacant home cleaning, bathroom cleaning, kitchen deep cleaning, sofa cleaning, carpet cleaning, terrace waterproofing, and sump waterproofing..",
      isOpen: false,
    },
    {
      question: " How can I book a cleaning service with KBS Home Services?",
      answer:
        " Booking a cleaning service is easy! Simply visit our website, navigate to the booking section, choose your preferred services, select your location, and schedule the appointment. You can also contact our customer service for assistance.",
      isOpen: false,
    },
    {
      question:
        "Are the cleaning products used by KBS Home Services eco-friendly?",
      answer:
        "Yes, at KBS Home Services, we prioritize your safety and the environment. We use high-quality, eco-friendly cleaning products that are safe for your home, family, and pets. Our cleaning methods are designed to be effective while minimizing any impact on the environment.",
      isOpen: false,
    },
    {
      question:
        "How does KBS Home Services ensure customer satisfaction?",
      answer:
        "Customer satisfaction is our priority. We have a dedicated customer support team to address any concerns or queries. Additionally, we conduct follow-ups after each service to ensure your satisfaction. If there are any issues, we are committed to resolving them promptly.",
      isOpen: false,
    },
    {
      question: "Is KBS Home Services licensed and insured?",
      answer:
        "Yes, KBS Home Services is a licensed and insured cleaning service company. Our team consists of trained professionals, and we are fully insured to provide you with peace of mind. Your property and belongings are in safe hands when you choose KBS Home Services.",
      isOpen: false,
    },
    {
      question: "What are the payment options accepted by KBS Home Services?",
      answer:
        "KBS Home Services accepts various payment methods, including credit cards, debit cards, online payments, and cash on delivery. You can choose the option that is most convenient for you.",
      isOpen: false,
    },
    {
      question: "Does KBS Home Services offer any discounts or promotions?",
      answer:
        "Yes, KBS Home Services frequently offers discounts and promotions to its customers. Keep an eye on our website and social media channels for the latest deals and offers.",
      isOpen: false,
    },
    {
      question: "Can I customize the cleaning services according to my needs?",
      answer:
        "Absolutely! KBS Home Services understands that every customer has unique cleaning requirements. You can customize your cleaning package by selecting specific services tailored to your needs.",
      isOpen: false,
    },
    {
      question: "What's the level of expertise of KBS Home Services cleaners?",
      answer:
        "The cleaning professionals at KBS Home Services are highly experienced and undergo rigorous training to ensure top-notch service quality. Rest assured, your cleaning needs will be handled by skilled experts.",
      isOpen: false,
    },
    {
      question: "What are KBS Home Services booking cancellation terms?",
      answer:
        "KBS Home Services has a flexible cancellation policy. You can cancel or reschedule your booking without any hassle. However, please ensure to notify us within a reasonable time frame to avoid any inconvenience.",
      isOpen: false,
    },
  
  ]);

  const toggleFaqFirstSet = (index) => {
    const updatedFaqs = [...faqs];
    updatedFaqs[index].isOpen = !updatedFaqs[index].isOpen;
    setFaqs(updatedFaqs);
  };

  const toggleFaqSecondSet = (index) => {
    const updatedFaqs = [...faqs];
    updatedFaqs[index + 5].isOpen = !updatedFaqs[index + 5].isOpen;
    setFaqs(updatedFaqs);
  };

  return (
    <div className="faq bg-slate-100 pt-14 font-poppins pb-10">
      <h6 className="text-2xl font-semibold">Curious about our services?</h6>
      <div className="text-lg font-bold mt-4">Frequently asked questions</div>
      <div className="faq-content mt-[20px] flex flex-col md:flex-row">
        <div className="faq-main-content bg-opacity-0 w-full flex flex-col ml-8 mr-8">
          {faqs.slice(0,5).map((faq, index) => (
            <div
              key={index}
              className={`faq-item ${faq.isOpen ? "open" : ""} `}
              onClick={() => toggleFaqFirstSet(index)}
            >
              <div className="faq-question flex justify-between items-center">
                <div className="text-sm sm:text-lg text-start">{faq.question}</div>
                <motion.div
                  initial={false}
                  animate={{ rotate: faq.isOpen ? 180 : 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <IoIosArrowDown size={20} />
                </motion.div>
              </div>
              <AnimatePresence>
                {faq.isOpen && (
                  <div className="bg-white px-3  py-1">
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3, ease: "linear" }}
                    style={{ overflow: "hidden" }}
                    className="faq-answer text-start text-sm "
                  >
                    {faq.answer}
                  </motion.div>
                  </div>
                )}
              </AnimatePresence>
              <hr className="faq-line" />
            </div>
          ))}
        </div>
        <div className="faq-main-content w-full flex flex-col ml-8 mr-8">
          {faqs.slice(5,10).map((faq, index) => (
            <div
              key={index}
              className={`faq-item ${faq.isOpen ? "open" : ""} `}
              onClick={() => toggleFaqSecondSet(index)}
            >
              <div className="faq-question flex justify-between items-center">
                <div className="text-sm sm:text-lg text-start">{faq.question}</div>
                <motion.div
                  initial={false}
                  animate={{ rotate: faq.isOpen ? 180 : 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <IoIosArrowDown size={20} />
                </motion.div>
              </div>
              <AnimatePresence>
                {faq.isOpen && (
                  <div className="bg-white px-3  py-1">
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3, ease: "linear" }}
                    style={{ overflow: "hidden" }}
                    className="faq-answer text-start text-sm  "
                  >
                    {faq.answer}
                  </motion.div>
                  </div>
                )}
              </AnimatePresence>
              <hr className="faq-line" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;