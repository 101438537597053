import React from 'react'

import { 
     exteriorpaint,
     interiorpaint,
     vacantpaint,
 
 } from '../../assets';

import AllServicePage from "../AllServicePage";

const PaintService = () => {
  const selectedCity = localStorage.getItem('city')

  const data = {
    headingFirst: " Painting " ,
    headingThird: "Services",
    headingSecond: "Services",
    description: "Transform your environment with KBS Home Service the unrivaled solution for ensuring a pristine and germ-free space. Contact us now to experience a renewed and hygienic living atmosphere ",
    rating: 4.7,
    reviews: "Based on 252 Reviews",
    imageLink1: exteriorpaint,
    link1: `/exterior-paint-service-in-${selectedCity.toLowerCase()}`,
    name1: "Exterior Paint ",
    imageLink2: interiorpaint,
    link2: `/interior-paint-service-in-${selectedCity.toLowerCase()}`,
    name2: "Interior Paint ",
    imageLink3: vacantpaint,
    link3: `/vacant-wall-painting-in-${selectedCity.toLowerCase()}`,
    name3: "Vacant Wall ",
    descriptionMeta: "Transform your space with our professional Painting Service. From refreshing walls to enhancing curb appeal, our skilled painters will bring your vision to life with precision and expertise. Elevate your home or business—schedule your painting service today!",
    bgImageUrl: "https://www.nipponpaint.co.in/wp-content/uploads/2021/08/shutterstock_505745176-1024x696.jpg",
    serviceType: ["Exterior paint services","Interior paint services","Vacant wall paint services"]
  }
 
  return (
    <div className="">
    <AllServicePage {...data} />
  </div>
  )
}

export default PaintService
