import React, { useState } from "react";

import {  bathroom } from "../../assets";
import FaqServices from "../../Components/FaqServices";

import TestimonialCarousel from "../Testimonials";
import Footer from "../../Components/Footer";
import Benefits from "../Benefits";

import CleaningServiceModal from "./CleaningServicesModel";
import Description from "../../Components/Description";
import MetaData from "../../Metadata";

 

const BathroomCleaning = () => {

  const faqData = [
    {
      question: 'How often should I schedule a professional bathroom cleaning?',
      answer: 'For optimal cleanliness, it is recommended to schedule a professional bathroom cleaning service at least once a month.',
    },
    {
      question: 'Do I need to remove personal items from the bathroom before cleaning?',
      answer: 'To ensure thorough cleaning, it is advisable to clear the bathroom surfaces of personal items such as toothbrushes, soaps, and towels.',
    },
    {
      question: 'Can you clean and disinfect bathroom tiles and grout?',
      answer: 'Yes, our bathroom cleaning service includes the cleaning and disinfection of tiles and grout to remove dirt, stains, and bacteria.',
    },
    {
      question: 'Do you use eco-friendly cleaning products for bathroom cleaning?',
      answer: 'Yes, we prioritize eco-friendly cleaning products to ensure a safe and environmentally conscious bathroom cleaning process.',
    },
    {
      question: 'Are your bathroom cleaning professionals trained and insured?',
      answer: 'Yes, our bathroom cleaning professionals are highly trained, experienced, and fully insured to provide a reliable and secure service.',
    },
    {
      question: 'How often should I clean my shower drain?',
      answer: 'To prevent clogs and maintain optimal drainage, it is recommended to clean your shower drain every 1-2 weeks by removing hair and debris.',
    },
    {
      question: 'Can you remove stubborn soap scum from shower doors?',
      answer: 'Yes, our cleaning experts use specialized cleaners and techniques to effectively remove stubborn soap scum from shower doors, leaving them sparkling clean.',
    },
    {
      question: 'Do you clean and sanitize toilet bowls?',
      answer: 'Absolutely, our bathroom cleaning service includes thorough cleaning and sanitization of toilet bowls to ensure hygiene and freshness.',
    },
    {
      question: 'How do you clean bathroom mirrors without streaks?',
      answer: 'We use professional-grade glass cleaners and microfiber cloths to clean bathroom mirrors, ensuring a streak-free shine every time.',
    },
    {
      question: 'Do you clean bathroom ventilation fans?',
      answer: 'Yes, our cleaning professionals can clean bathroom ventilation fans to remove dust and buildup, ensuring efficient operation and improved air quality.',
  },
  ];
  

  const data = {
   
    headingFirst: "Bathroom",
    headingSecond: "Bathroom",
    price: "₹1999",
    description: [
      "Thorough cleaning and revitalization of bathroom tiles and grout to restore their original shine.",
      " Polishing and disinfection of faucets, showerheads, and other fixtures to bring a sparkling finish.",
      " Comprehensive cleaning and disinfection of showers and tubs to eliminate soap scum, stains, and bacteria.",
      "  Streak-free cleaning and refinement of mirrors to enhance the overall aesthetic of the bathroom.",
  
      " Cleaning of  tubes, switchboard, bulbs, door handles",
     
    ],
    image: bathroom,
    space: "bathrooms",
    size: "bathrooms",
    descriptionMeta: "Transform your bathroom with our meticulous Bathroom Cleaning Service. Enjoy a spotless sanctuary without the hassle. Book now!",
    
  
  }
  const [selectedFlat, setSelectedFlat] = useState("");

  const flatOptions = [
    "1 BHK+",
    "2 BHK+",
    "3 BHK+",
    "4 BHK+",
    "2000-3000 sq. ft. Villa",
    "4000-6000 sq. ft. Villa",
    "6000-7000 sq. ft. Villa",
    "Bungalow",
  ];

  const descriptionData = {
    headingDescription_1 : "Why Professional Bathroom Cleaning is Essential",
    paraDescription_1 : "Bathrooms are one of the most frequently used areas in any home, making them prone to dirt, grime, and bacteria buildup. Neglecting proper cleaning and maintenance can not only lead to unpleasant odors and unsightly stains but also pose health risks to occupants. Professional bathroom cleaning plays a crucial role in ensuring that your bathroom remains clean, hygienic, and free from harmful pathogens. With specialized cleaning techniques and products, our service targets tough stains, mold, and mildew, leaving your bathroom sparkling clean and sanitized. Investing in professional bathroom cleaning is not just about aesthetics; it's about creating a healthy and comfortable environment for you and your family.Our professional bathroom cleaning service is designed to deliver thorough and effective cleaning results, addressing both visible and hidden areas of your bathroom. Using industry-leading equipment and eco-friendly cleaning agents, our trained professionals perform a comprehensive cleaning process tailored to your specific needs.",
    headingDescription_2 : "What We Do in Professional Bathroom Cleaning",
    paraDescription_2 : [
      "Deep cleaning and sanitization of all surfaces, including countertops, sinks, and faucets.",
      "Removal of soap scum, hard water stains, and mineral deposits from shower walls and doors.",
       "Scrubbing and disinfecting of toilets, including the bowl, seat, and exterior surfaces.",
"Cleaning and polishing of mirrors and vanity surfaces to enhance shine and clarity.",
"Thorough cleaning and sanitization of tile grout to eliminate mold and mildew buildup.",
"Degreasing and sanitizing of bathroom fixtures, such as towel racks and toilet paper holders.",
"Disinfecting high-touch areas, including light switches, door handles, and toilet flush handles.",
"Emptying and cleaning of trash bins to maintain a fresh and odor-free environment.",
"Removing cobwebs and dust from ceilings, corners, and ventilation fans.",
"Customized add-on services available upon request to address specific cleaning needs or concerns.",
    ]

  }
  return (
    <><div className="">
      <CleaningServiceModal {...data} />
      <FaqServices faqData={faqData} />
      <Description {...descriptionData} />
      <Benefits />
      <TestimonialCarousel />

      <Footer />
    </div></>

  );
};

export default BathroomCleaning;
