import React, { useState } from "react";
import Footer from "../Components/Footer";
import MetaData from "../Metadata";

const CareerPage = () => {
  return (
    <>
    <MetaData title="Carrer-KBS Home Services" />
      <div className="bg-slate-100 w-full ">
        <div className="apply mt-[50px] flex flex-col justify-start items-start px-[10px] lg:px-[200px] pt-10">
          <h1 className="text-3xl font-bold font-poppins">How to apply ?</h1>
          <p className="font-poppins text-lg mt-2">
            {" "}
            You can mail us your details including Name, Place, Age, Job Role(you want to apply for) and an ID-proof on <span className="font-semibold">support@kbshomeservice.com</span> or you can fill the application form below and submit it .
          </p>
        </div>

        <div className="contact mt-[50px] flex flex-col justify-start items-start px-[10px] lg:px-[200px] pb-10">
          <h1 className="text-3xl font-bold font-poppins">
            Contact Information:
          </h1>
          <span className="font-poppins text-lg mt-2">
            For further queries, feel free to contact us at :{" "}
          </span>
          <span className="gap-2 text-lg">
            <span className="font-bold pr-2 ">Email:</span>
            support@kbshomeservice.com
          </span>
          <span className="gap-2 text-lg">
            <span className="font-bold pr-2 ">Phone:</span>+91-63636 17916
          </span>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default CareerPage;
