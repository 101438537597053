import React from 'react'

import {  festival } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';

import DecorationModal from './DecorationModal';
import FaqServices from '../../Components/FaqServices';
import Description from '../../Components/Description';

const Festival = () => {

  const data = {
    headingFirst: "Festival Party",
    headingSecond: "Festival Party",
    price: "₹6999",
    description: [
      "Customized and themed decoration for festivals",
      "Setup and arrangement of festive lighting",
      "Installation of festive ornaments and accessories",
      "Decoration of indoor and outdoor spaces",
      "Design and placement of festive banners and signage",
      "Incorporation of seasonal flowers, plants, and greenery",
      "Creation of festive centerpieces and table arrangements",
      "Arrangement of special seating areas with festive decor",
      "Use of unique and vibrant color schemes to enhance the festive atmosphere",
      "Incorporation of cultural and traditional elements based on the festival",
      "Coordination with clients to ensure personalized and desired festive ambiance"
    ],
    image: festival,
    space: "ethnic space",
    size: "compound",
    descriptionMeta: "Add a touch of magic to your festival celebrations with our vibrant Festival Party Decoration Service. From colorful banners to festive lights, we'll create a lively and joyful atmosphere for your event. Let's make your festival unforgettable—book your decoration service today!",
    
  
  }
  const festivalPartyDecorationFAQ = [
    {
      question: 'How far in advance should I book a festival party decoration service?',
      answer: 'It is recommended to book your festival party decoration service well in advance, preferably a few weeks before the event date, to ensure availability and proper planning.',
    },
    {
      question: 'What types of events do you provide festival party decoration for?',
      answer: 'We offer festival party decoration services for a variety of events, including birthdays, weddings, anniversaries, corporate events, and other special occasions.',
    },
    {
      question: 'Can I customize the theme and colors for my festival party decoration?',
      answer: 'Yes, our festival party decoration services are highly customizable. You can choose specific themes, color schemes, and decorations to match your event preferences.',
    },
    {
      question: 'Do you provide outdoor festival party decoration services?',
      answer: 'Yes, we offer both indoor and outdoor festival party decoration services. Our team can create stunning decorations for gardens, lawns, and outdoor venues.',
    },
    {
      question: 'What types of decorations are included in the festival party decoration package?',
      answer: 'Our festival party decoration packages may include a variety of decorations such as balloons, banners, table centerpieces, lighting arrangements, and other thematic elements based on your chosen theme.',
    },
    {
      question: 'Can you accommodate last-minute requests for festival party decoration?',
      answer: 'While we recommend booking in advance, we understand that sometimes events come up unexpectedly. We will do our best to accommodate last-minute requests for festival party decoration services, subject to availability.',
    },
    {
      question: 'Do you provide setup and takedown services for festival party decorations?',
      answer: 'Yes, our team handles both the setup and takedown of festival party decorations. We ensure that the decorations are installed and removed efficiently, allowing you to focus on enjoying the event.',
    },
    {
      question: 'Are there any additional charges for setup of festival party decorations?',
      answer: 'The cost of delivery and setup of festival party decorations is typically included in the service package. However, additional charges may apply for locations outside our standard service area or for special setup requirements.',
    },
    {
      question: 'Can I request specific types of decorations not listed in your packages?',
      answer: "Absolutely! We welcome custom requests for festival party decorations. Whether you have specific decor ideas or unique requirements, we'll work with you to bring your vision to life.",
    },
    {
      question: 'What measures do you take to ensure the safety of outdoor festival party decorations?',
      answer: 'When setting up outdoor festival party decorations, we take into account factors like weather conditions, stability of structures, and safety of installations to ensure a safe and enjoyable experience for everyone attending the event.',
    },
  ];
  const descriptionData = {
    headingDescription_1: "Why Professional Festival Party Decoration Service is Essential",
    paraDescription_1: "Transforming your space into a festive wonderland is essential for creating memorable and enjoyable celebrations. Whether you're hosting a birthday bash, wedding reception, or corporate event, the ambiance and decor play a crucial role in setting the mood and enhancing the overall experience for guests. Neglecting proper decoration can result in lackluster and uninspiring atmospheres, failing to capture the essence of the occasion. Professional festival party decoration service plays a pivotal role in bringing your vision to life, elevating your event with creative themes, stunning decor elements, and meticulous attention to detail. With innovative design concepts and high-quality materials, our service transforms ordinary venues into extraordinary settings, leaving a lasting impression on guests and creating cherished memories. Investing in professional festival party decoration service is not just about aesthetics; it's about creating magical moments and ensuring the success of your special event.",
    headingDescription_2: "What We Do in Professional Festival Party Decoration Service",
    paraDescription_2: [
        "Collaboration with clients to understand their vision, theme, and budget for the event decor.",
        "Conceptualization and design of unique themes and decor elements tailored to the occasion.",
        "Selection and procurement of high-quality decorations, including balloons, banners, centerpieces, and lighting.",
        "Installation and setup of decor elements, ensuring timely and seamless execution.",
        "Customization of decor to incorporate personal touches and branding elements.",
        "Coordination with venue staff and event planners to ensure smooth integration of decor with other event elements.",
        "On-site supervision and adjustments as needed to maintain the integrity and impact of the decor.",
        "Cleanup and removal of decor post-event, leaving the venue in its original condition.",
        "Additional services such as photo booth setups, floral arrangements, and themed props available upon request.",
        "Consultation and support throughout the planning process to ensure the decor aligns with the client's vision and expectations."
    ]
}

  return (
    <div className='main'>
        <DecorationModal {...data} />
        <FaqServices faqData={festivalPartyDecorationFAQ} />
        <Description {...descriptionData} />
        <Benefits />
        <TestimonialCarousel />
        <Footer />
    </div>
  )
}

export default Festival
