import React from "react";

import { carpet } from "../../assets";

import TestimonialCarousel from "../Testimonials";
import Footer from "../../Components/Footer";
import Benefits from "../Benefits";

import CleaningServiceModal from "./CleaningServicesModel";

import FaqServices from "../../Components/FaqServices";
import Description from "../../Components/Description";
import MetaData from "../../Metadata";



const CarpetCleaning = () => {

  const faqData = [
    {
      question: 'How long does it take for carpets to dry after cleaning?',
      answer: 'The drying time for carpets after cleaning varies, but it typically takes around 6 to 12 hours. Factors like ventilation and humidity play a role in the drying process.',
    },
    {
      question: 'Can professional carpet cleaning remove tough stains?',
      answer: 'Yes, professional carpet cleaning can effectively remove tough stains, including pet stains, food spills, and deep-seated dirt.',
    },
    {
      question: 'Is it safe for children and pets after carpet cleaning?',
      answer: 'Once the carpets are fully dry, it is safe for children and pets to be in the cleaned areas. We use safe and non-toxic cleaning solutions.',
    },
    {
      question: 'How often should I vacuum my carpets between professional cleanings?',
      answer: 'Regular vacuuming is essential. It is recommended to vacuum high-traffic areas at least once a week and the entire carpet at least once a month.',
    },
    {
      question: 'Do you offer carpet protection treatments?',
      answer: 'Yes, we offer carpet protection treatments to help repel stains and spills, extending the life of your carpets and making them easier to clean.',
    },
    {
      question: 'How soon can I walk on the carpet after cleaning?',
      answer: "You can typically walk on the carpet immediately after cleaning with clean shoes or socks. However, it's best to avoid wearing outdoor shoes to prevent re-soiling.",
    },
    {
      question: 'Do you use hot water extraction or steam cleaning for carpets?',
      answer: 'Yes, we utilize hot water extraction, also known as steam cleaning, as it is one of the most effective methods for deep cleaning carpets and removing embedded dirt.',
    },
    {
      question: 'Can professional carpet cleaning help with allergies and asthma?',
      answer: 'Yes, professional carpet cleaning can help improve indoor air quality by removing allergens like dust mites, pet dander, and pollen trapped in the carpet fibers.',
    },
    {
      question: 'Do you offer carpet repair services for damaged carpets?',
      answer: 'Yes, we provide carpet repair services for various issues such as burns, tears, wrinkles, and loose seams to restore the appearance and functionality of your carpets.',
    },
    {
      question: 'What should I do if I spill something on the carpet after cleaning?',
      answer: 'If you accidentally spill something on the carpet, blot the area immediately with a clean, dry cloth. Avoid rubbing the spill to prevent spreading and damaging the fibers.',
  },
  ];
  

  const data = {
  
    headingFirst: "Carpet",
    headingSecond: "Carpet",
    price: "₹2999",
    descriptionMeta: "Renew the look and feel of your carpets with our expert Carpet Cleaning Service. Say goodbye to dirt and allergens, and hello to soft, clean carpets that enhance your space. Experience freshness underfoot—schedule your clean now!",
    description: [
      " Utilization of advanced steam cleaning techniques to penetrate deep into carpet fibers and lift away embedded dirt and stains.",
      " Targeted stain removal treatment for stubborn spots, ensuring a spotless and blemish-free carpet surface.",
      "  Special attention to high-traffic areas to effectively remove  dirt, grime, and wear patterns, extending the life of your  carpets.",
      "  Thorough removal of allergens, dust mites, and pollutants, creating a healthier indoor environment.",
      " Efficient drying methods to minimize downtime and allow you to enjoy your revitalized carpets sooner.  ",
     
     
     
    ],
    image: carpet,
    space: "carpets and curtains",
    size: "house",
    
  
  }
  const descriptionData= {
    headingDescription_1: "Why Professional Carpet Cleaning is Essential",
    paraDescription_1: "Carpets can quickly accumulate dirt, dust, allergens, and stains, especially in high-traffic areas of your home. Regular vacuuming may not suffice to remove deeply embedded dirt and contaminants, leading to diminished air quality and potential health hazards. Professional carpet cleaning is essential for maintaining a clean and healthy indoor environment. Our service utilizes advanced cleaning techniques and specialized equipment to thoroughly cleanse your carpets, effectively removing dirt, stains, and allergens. By investing in professional carpet cleaning, you can prolong the lifespan of your carpets, enhance indoor air quality, and create a fresh and inviting atmosphere for your home.",
    headingDescription_2: "What We Do in Professional Carpet Cleaning",
    paraDescription_2: [
        "Pre-inspection of carpets to assess the condition and identify any specific areas of concern, such as stains or odors.",
        "Pre-treatment of stains and high-traffic areas with specialized cleaning solutions to loosen dirt and debris.",
        "Hot water extraction method to deep clean carpets, effectively removing embedded dirt, allergens, and bacteria.",
        "Application of eco-friendly carpet shampoo and deodorizers to eliminate odors and leave carpets smelling fresh.",
        "Spot treatment for stubborn stains and discolorations to restore the original appearance of the carpet fibers.",
        "Grooming of carpets to lift and revive the pile, enhancing the overall appearance and texture.",
        "Speed drying with powerful air movers to expedite the drying process and minimize downtime.",
        "Final inspection to ensure that all areas have been thoroughly cleaned and any remaining spots or stains have been addressed.",
        "Optional Scotchgard protection application to safeguard carpets against future stains and spills.",
        "Customized add-on services available upon request to meet specific cleaning needs or preferences.",
    ]
};

  
  return (
    
    <div className="">
     <CleaningServiceModal {...data} />
     <FaqServices faqData={faqData} />
     <Description {...descriptionData} />
     <Benefits />
     <TestimonialCarousel />
     <Footer />
    </div>
  );
};

export default CarpetCleaning;
