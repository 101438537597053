import React from "react";
import {
  
  carpet,
  commercial,
  bathroom,
  sofa,
  kitchen,
  oofice,
  villa,
  house,
  ant,
  
  mosquito,
  cockroach,
  bedbug,
  termite,
  rodent,
  carwash,
  babyshower,
  bachelorite,
  festival,
  firstnight,
  corporate,
  flower,
} from "../assets";

import { Link } from "react-router-dom";
import CarPolish from "./Cleaning Services/CarPolish";

const Services = () => {
  const city = localStorage.getItem("city")
    ? localStorage.getItem("city")
    : "Bangalore";
  //deep
  const redirectUrl_deep = `/deep-home-cleaning-in-${city.toLowerCase()}`;
  const redirectUrl_bathroom = `/bathroom-cleaning-in-${city.toLowerCase()}`;
  const redirectUrl_kitchen = `/kitchen-cleaning-in-${city.toLowerCase()}`;
  const redirectUrl_commercial = `/commercial-cleaning-in-${city.toLowerCase()}`;
  const redirectUrl_villa = `/villa-cleaning-in-${city.toLowerCase()}`;
  const redirectUrl_carpet = `/carpet-cleaning-in-${city.toLowerCase()}`;
  const redirectUrl_office = `/office-cleaning-in-${city.toLowerCase()}`;
  const redirectUrl_sofa = `/sofa-cleaning-in-${city.toLowerCase()}`;
  const redirectUrl_carwash = `/car-washing-in-${city.toLowerCase()}`
  
  //pest
  const redirectUrl_bedbug = `/bedbug-control-in-${city.toLowerCase()}`
  const redirectUrl_ant = `/ant-pest-control-in-${city.toLowerCase()}`
  const redirectUrl_termite = `/termite-pest-control-in-${city.toLowerCase()}`
  const redirectUrl_cockroach = `/cockroach-pest-control-in-${city.toLowerCase()}`
  const redirectUrl_mosquito = `/mosquito-pest-control-in-${city.toLowerCase()}`
  const redirectUrl_rodent = `/rodent-pest-control-in-${city.toLowerCase()}`


  //decoration
  
  const redirectUrl_babyshower = `/babyshower-service-in-${city.toLowerCase()}`
  const redirectUrl_bachellorite = `/bachellorite-service-in-${city.toLowerCase()}`
  const redirectUrl_corporate = `/corporate-service-in-${city.toLowerCase()}`
  const redirectUrl_festival = `/festival-service-in-${city.toLowerCase()}`
  const redirectUrl_weddingnight = `/weddingnight-service-in-${city.toLowerCase()}`
  const redirectUrl_flower = `/flower-service-in-${city.toLowerCase()}`


   const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className={` w-full bg-slate-50 flex flex-col px-3 sm:px-0 justify-center items-center pb-10 mt-10`}
    >
      <div className="cleaning flex flex-col  justify-center items-center ">
        <div className="font-bold flex pt-10 pb-5  text-Isblack font-poppins justify-start items-center text-3xl ">
          Cleaning Services
        </div>
        <div className="servicecard grid sm:grid-cols-4 lg:grid-cols-5 grid-cols-3 sm:gap-9 gap-[20px]   mt-2  lg:w-full font-poppins ">
          <Link to={redirectUrl_deep} onClick={scrollToTop}>
            {" "}
            <div className="flex flex-col md:w-[200px] sm:w-[150px] w-[100px]  justify-center items-center cursor-pointer z-10 sm:p-4 p-2 shadow-md rounded-lg   h-[100px] sm:h-[150px] lg:h-auto ">
              <img
                
                className="lg:w-[100px]  lg:h-[95px] sm:h-[60px] sm:w-[60px]  h-[40px] w-[40px] rounded-[100%]  lg:rounded-[20%] "
                src={house}
                alt="office"
              />
              <p className="font-poppins lg:text-md text-xs text-center sm:mt-4 mt-2"> Deep Home Cleaning</p>
            </div>
          </Link>
          <Link to={redirectUrl_bathroom} onClick={scrollToTop}>
            {""}
            <div className="flex flex-col md:w-[200px] sm:w-[150px] w-[100px]  justify-center items-center cursor-pointer z-10 sm:p-4 p-2 shadow-md rounded-lg   h-[100px] sm:h-[150px] lg:h-auto ">
              <img
                
                className="lg:w-[100px]  lg:h-[95px] sm:h-[60px] sm:w-[60px]  h-[40px] w-[40px] rounded-[100%]  lg:rounded-[20%] "
                src={bathroom}
                alt="office"
              />
              <p className="font-poppins lg:text-md text-xs text-center sm:mt-4 mt-2"> Bathroom Cleaning</p>
            </div>
          </Link>
          <Link to={redirectUrl_kitchen} onClick={scrollToTop}>
            {" "}
            <div className="flex flex-col md:w-[200px] sm:w-[150px] w-[100px]  justify-center items-center cursor-pointer z-10 sm:p-4 p-2 shadow-md rounded-lg   h-[100px] sm:h-[150px] lg:h-auto ">
              <img
                
                className="lg:w-[100px]  lg:h-[95px] sm:h-[60px] sm:w-[60px]  h-[40px] w-[40px] rounded-[100%]  lg:rounded-[20%] "
                src={kitchen}
                alt="office"
              />
              <p className="font-poppins lg:text-md text-xs text-center sm:mt-4 mt-2"> Kitchen Cleaning</p>
            </div>
          </Link>
         
         
          <Link to={redirectUrl_commercial} onClick={scrollToTop}>
            {" "}
            <div className="flex flex-col md:w-[200px] sm:w-[150px] w-[100px]  justify-center items-center cursor-pointer z-10 sm:p-4 p-2 shadow-md rounded-lg   h-[100px] sm:h-[150px] lg:h-auto ">
              <img
                
                className="lg:w-[100px]  lg:h-[95px] sm:h-[60px] sm:w-[60px]  h-[40px] w-[40px] rounded-[100%]  lg:rounded-[20%] "
                src={commercial}
                alt="office"
              />
              <p className="font-poppins lg:text-md text-xs text-center sm:mt-4 mt-2">Commercial Cleaning</p>
            </div>
          </Link>
          <Link to={redirectUrl_carwash} onClick={scrollToTop}>
            {" "}
            <div className="flex flex-col md:w-[200px] sm:w-[150px] w-[100px]  justify-center items-center cursor-pointer z-10 sm:p-4 p-2 shadow-md rounded-lg   h-[100px] sm:h-[150px] lg:h-auto ">
              <img
                
                className="lg:w-[100px]  lg:h-[95px] sm:h-[60px] sm:w-[60px]  h-[40px] w-[40px] rounded-[100%]  lg:rounded-[20%] "
                src={carwash}
                alt="office"
              />
              <p className="font-poppins lg:text-md text-xs text-center sm:mt-4 mt-2">Deep Car Cleaning</p>
            </div>
          </Link>
        
          <Link to={redirectUrl_carpet} onClick={scrollToTop}>
            {" "}
            <div className="flex flex-col md:w-[200px] sm:w-[150px] w-[100px]  justify-center items-center cursor-pointer z-10 sm:p-4 p-2 shadow-md rounded-lg   h-[100px] sm:h-[150px] lg:h-auto ">
              <img
                
                className="lg:w-[100px]  lg:h-[95px] sm:h-[60px] sm:w-[60px]  h-[40px] w-[40px] rounded-[100%]  lg:rounded-[20%] "
                src={carpet}
                alt="office"
              />
              <p className="font-poppins lg:text-md text-xs text-center sm:mt-4 mt-2"> Carrpet Cleaning</p>
            </div>
          </Link>
          <Link to={redirectUrl_villa} onClick={scrollToTop}>
            {" "}
            <div className="flex flex-col md:w-[200px] sm:w-[150px] w-[100px]  justify-center items-center cursor-pointer z-10 sm:p-4 p-2 shadow-md rounded-lg   h-[100px] sm:h-[150px] lg:h-auto ">
              <img
                
                className="lg:w-[100px]  lg:h-[95px] sm:h-[60px] sm:w-[60px]  h-[40px] w-[40px] rounded-[100%]  lg:rounded-[20%] "
                src={villa}
                alt="office"
              />
             
              <p className="font-poppins lg:text-md text-xs text-center sm:mt-4 mt-2"> Villa Cleaning</p>
             
           
             
            </div>
          </Link>
         
          <Link to={redirectUrl_sofa} onClick={scrollToTop}>
            {" "}
            <div className="flex flex-col md:w-[200px] sm:w-[150px] w-[100px]  justify-center items-center cursor-pointer z-10 sm:p-4 p-2 shadow-md rounded-lg   h-[100px] sm:h-[150px] lg:h-auto ">
              <img
                
                className="lg:w-[100px]  lg:h-[95px] sm:h-[60px] sm:w-[60px]  h-[40px] w-[40px] rounded-[100%]  lg:rounded-[20%] "
                src={sofa}
                alt="office"
              />
              <p className="font-poppins lg:text-md text-xs text-center sm:mt-4 mt-2"> Sofa Cleaning</p>
            </div>
          </Link>
          <Link to={redirectUrl_office} onClick={scrollToTop}>
            {" "}
            <div className="flex flex-col md:w-[200px] sm:w-[150px] w-[100px]  justify-center items-center cursor-pointer z-10 sm:p-4 p-2 shadow-md rounded-lg   h-[100px]  sm:h-[150px] lg:h-auto ">
              <img
                
                className="lg:w-[100px]  lg:h-[95px] sm:h-[60px] sm:w-[60px]  h-[40px] w-[40px] rounded-[100%]  lg:rounded-[20%] "
                src={oofice}
                alt="office"
              />
              <p className="font-poppins lg:text-md text-xs text-center sm:mt-4 mt-2"> Office Cleaning</p>
            </div>
          </Link>
         
        </div>
      </div>



      <div className="cleaning flex flex-col  justify-center items-center ">
        <h1 className="font-bold flex pt-10 pb-5  text-Isblack font-poppins justify-center items-center text-3xl">
          Pest Control Services
        </h1>
       <div className="servicecard grid lg:grid-cols-5 sm:grid-cols-4 grid-cols-3 sm:gap-9 gap-[20px]   mt-2  lg:w-full font-poppins ">
          <Link to={redirectUrl_bedbug} onClick={scrollToTop}>
            {" "}
            <div className="flex flex-col md:w-[200px] sm:w-[150px] w-[100px]  justify-center items-center cursor-pointer z-10 sm:p-4 p-2 shadow-md rounded-lg   h-[100px]  sm:h-[150px] lg:h-auto ">
              <img
                
                className="lg:w-[100px]  lg:h-[95px] sm:h-[60px] sm:w-[60px]  h-[40px] w-[40px] rounded-[100%]  lg:rounded-[20%] "
                src={bedbug}
                alt="office"
              />
              <p className="font-poppins lg:text-md text-xs text-center sm:mt-4 mt-2"> Bedbug Pest Control </p>
            </div>
          </Link>
          <Link to={redirectUrl_cockroach} onClick={scrollToTop}>
            {" "}
            <div className="flex flex-col md:w-[200px] sm:w-[150px] w-[100px]  justify-center items-center cursor-pointer z-10 sm:p-4 p-2 shadow-md rounded-lg   h-[100px]  sm:h-[150px] lg:h-auto ">
              <img
                
                className="lg:w-[100px]  lg:h-[95px] sm:h-[60px] sm:w-[60px]  h-[40px] w-[40px] rounded-[100%]  lg:rounded-[20%] "
                src={cockroach}
                alt="office"
              />
              <p className="font-poppins lg:text-md text-xs text-center sm:mt-4 mt-2">Cockroach  Control</p>
            </div>
          </Link>
          <Link to={redirectUrl_ant} onClick={scrollToTop}>
            {" "}
            <div className="flex flex-col md:w-[200px] sm:w-[150px] w-[100px]  justify-center items-center cursor-pointer z-10 sm:p-4 p-2 shadow-md rounded-lg   h-[100px] sm:h-[150px] lg:h-auto ">
              <img
                
                className="lg:w-[100px]  lg:h-[95px] sm:h-[60px] sm:w-[60px]  h-[40px] w-[40px] rounded-[100%]  lg:rounded-[20%] "
                src={ant}
                alt="office"
              />
              <p className="font-poppins lg:text-md text-xs text-center sm:mt-4 mt-2"> Insect Pest Control </p>
            </div>
          </Link>
          <Link to={redirectUrl_mosquito} onClick={scrollToTop}>
            {" "}
            <div className="flex flex-col md:w-[200px] sm:w-[150px] w-[100px]  justify-center items-center cursor-pointer z-10 sm:p-4 p-2 shadow-md rounded-lg   h-[100px] sm:h-[150px] lg:h-auto ">
              <img
                
                className="lg:w-[100px]  lg:h-[95px] sm:h-[60px] sm:w-[60px]  h-[40px] w-[40px] rounded-[100%]  lg:rounded-[20%] "
                src={mosquito}
                alt="office"
              />
              <p className="font-poppins lg:text-md text-xs text-center sm:mt-4 mt-2">Mosquito Pest Control</p>
            </div>
          </Link>
          <Link to={redirectUrl_termite} onClick={scrollToTop}>
          <div className="flex flex-col md:w-[200px] sm:w-[150px] w-[100px]  justify-center items-center cursor-pointer z-10 sm:p-4 p-2 shadow-md rounded-lg   h-[100px] sm:h-[150px] lg:h-auto ">
              <img
                
                className="lg:w-[100px]  lg:h-[95px] sm:h-[60px] sm:w-[60px]  h-[40px] w-[40px] rounded-[100%]  lg:rounded-[20%] "
                src={termite}
                alt="office"
              />
              <p className="font-poppins lg:text-md text-xs text-center sm:mt-4 mt-2">Termite Pest Control</p>
            </div>
          </Link>
        
          <Link to={redirectUrl_rodent} onClick={scrollToTop}>
            {" "}
            <div className="flex flex-col md:w-[200px] sm:w-[150px] w-[100px]  justify-center items-center cursor-pointer z-10 sm:p-4 p-2 shadow-md rounded-lg   h-[100px] sm:h-[150px] lg:h-auto ">
              <img
                
                className="lg:w-[100px]  lg:h-[95px] sm:h-[60px] sm:w-[60px]  h-[40px] w-[40px] rounded-[100%]  lg:rounded-[20%] "
                src={rodent}
                alt="office"
              />
              <p className="font-poppins lg:text-md text-xs text-center sm:mt-4 mt-2">Rodent Pest Control</p>
            </div>
          </Link>
        </div>
      </div>




      <div className="cleaning flex flex-col  justify-center items-center ">
        <h1 className="md:font-bold  font-semibold flex pt-10 pb-5  text-Isblack font-poppins justify-start items-center text-3xl">
          Decoration Services
        </h1>
       <div className="servicecard grid lg:grid-cols-5 sm:grid-cols-4 grid-cols-3 sm:gap-9 gap-[20px]   mt-2  lg:w-full font-poppins ">
          <Link to={redirectUrl_babyshower} onClick={scrollToTop}>
            {" "}
            <div className="flex flex-col md:w-[200px] sm:w-[150px] w-[100px]  justify-center items-center cursor-pointer z-10 sm:p-4 p-2 shadow-md rounded-lg    h-[100px] sm:h-[150px] lg:h-auto ">
              <img
                
                className="lg:w-[100px]  lg:h-[95px] sm:h-[60px] sm:w-[60px]  h-[40px] w-[40px] rounded-[100%]  lg:rounded-[20%] "
                src={babyshower}
                alt="office"
              />
              <p className="font-poppins lg:text-md text-xs text-center sm:mt-4 mt-2"> Babyshower  Decoration </p>
            </div>
          </Link>
          <Link to={redirectUrl_bachellorite} onClick={scrollToTop}>
            {" "}
            <div className="flex flex-col md:w-[200px] sm:w-[150px] w-[100px]  justify-center items-center cursor-pointer z-10 sm:p-4 p-2 shadow-md  rounded-lg   h-[100px] sm:h-[150px] lg:h-auto ">
              <img
                
                className="lg:w-[100px]  lg:h-[95px] sm:h-[60px] sm:w-[60px]  h-[40px] w-[40px] rounded-[100%]  lg:rounded-[20%] "
                src={bachelorite}
                alt="office"
              />
              <p className="font-poppins lg:text-md text-xs text-center sm:mt-4 mt-2">Bachellorite  Decoration</p>
            </div>
          </Link>
         
          <Link to={redirectUrl_weddingnight} onClick={scrollToTop}>
          <div className="flex flex-col md:w-[200px] sm:w-[150px] w-[100px]  justify-center items-center cursor-pointer z-10 sm:p-4 p-2 shadow-md rounded-lg   h-[100px] sm:h-[150px] lg:h-auto ">
              <img
                
                className="lg:w-[100px]  lg:h-[95px] sm:h-[60px] sm:w-[60px]  h-[40px] w-[40px] rounded-[100%]  lg:rounded-[20%] "
                src={firstnight}
                alt="office"
              />
              <p className="font-poppins lg:text-md text-xs text-center sm:mt-4 mt-2">Wedding Night Decoration</p>
            </div>
          </Link>
          <Link to={redirectUrl_festival} onClick={scrollToTop}>
            {" "}
            <div className="flex flex-col md:w-[200px] sm:w-[150px] w-[100px]  justify-center items-center cursor-pointer z-10 sm:p-4 p-2 shadow-md rounded-lg   h-[100px] sm:h-[150px] lg:h-auto ">
              <img
                
                className="lg:w-[100px]  lg:h-[95px] sm:h-[60px] sm:w-[60px]  h-[40px] w-[40px] rounded-[100%]  lg:rounded-[20%] "
                src={festival}
                alt="office"
              />
              <p className="font-poppins lg:text-md text-xs text-center sm:mt-4 mt-2">Festival Decoration</p>
            </div>
          </Link>
          <Link to={redirectUrl_corporate} onClick={scrollToTop}>
            {" "}
            <div className="flex flex-col md:w-[200px] sm:w-[150px] w-[100px]  justify-center items-center cursor-pointer z-10 sm:p-4 p-2 shadow-md rounded-lg   h-[100px] sm:h-[150px] lg:h-auto ">
              <img
                
                className="lg:w-[100px]  lg:h-[95px] sm:h-[60px] sm:w-[60px]  h-[40px] w-[40px] rounded-[100%]  lg:rounded-[20%] "
                src={corporate}
                alt="office"
              />
              <p className="font-poppins lg:text-md text-xs text-center sm:mt-4 mt-2">Corporate Decoration</p>
            </div>
          </Link>
          <Link to={redirectUrl_flower} onClick={scrollToTop}>
            {" "}
            <div className="flex flex-col md:w-[200px] sm:w-[150px] w-[100px]  justify-center items-center cursor-pointer z-10 sm:p-4 p-2 shadow-md rounded-lg   h-[100px] sm:h-[150px] lg:h-auto ">
              <img
                
                className="lg:w-[100px]  lg:h-[95px] sm:h-[60px] sm:w-[60px]  h-[40px] w-[40px] rounded-[100%]  lg:rounded-[20%] "
                src={flower}
                alt="office"
              />
              <p className="font-poppins lg:text-md text-xs text-center sm:mt-4 mt-2">Flower Decoration</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Services;
