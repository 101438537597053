import React  from 'react'

import {  ant } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';

import PestControlModal from './PestControlModal';
import FaqServices from '../../Components/FaqServices';
import Description from '../../Components/Description';



const AntPestControl = () => {

  const data = {
    headingFirst: "Ant Pest",
    headingSecond: "Ant Pest",
    price: "₹1999",
    description: [
      "Advanced pest control techniques targeting ant infestations for long-lasting results.",
"Specialized treatment for stubborn ant colonies, ensuring a pest-free environment.",
"Focused attention on common ant entry points to prevent future infestations.",
"Thorough elimination of ant nests, providing a more sustainable solution to ant-related issues.",
"Customized ant pest control plans tailored to your specific property and infestation level.",
     
    ],
    image: ant,
    space: "living space",
    size: "house",
    descriptionMeta: "Get rid of ants for good with our targeted Ant Pest Control Service. Our experts will identify and treat ant infestations, ensuring a pest-free environment. Don't let ants take over—schedule your service now!",

    
  
  }
  const antPestControlFAQ = [
    {
      question: 'How long does it take for ant pest control treatment to be effective?',
      answer: 'The effectiveness of ant pest control treatment depends on factors such as the severity of the infestation and the type of treatment used. In most cases, you may notice a significant reduction in ant activity within a few days to a week.',
    },
    {
      question: 'Are the pesticides used in ant pest control safe for humans and pets?',
      answer: 'Yes, the pesticides we use for ant pest control are carefully selected to be safe for humans and pets. Our technicians follow strict guidelines to minimize any potential risks to your family and pets.',
    },
    {
      question: 'How do I prepare for ant pest control treatment?',
      answer: "Before the ant pest control treatment, it's advisable to remove food items, dishes, and pet bowls from areas where the treatment will take place. Seal any open food containers and cover or store sensitive items.",
    },
    {
      question: 'Do I need multiple ant pest control treatments?',
      answer: 'The need for multiple ant pest control treatments depends on the severity of the infestation. Our technicians will assess the situation and recommend a treatment plan tailored to your specific needs.',
    },
    {
      question: 'What preventive measures can I take to avoid future ant infestations?',
      answer: 'To prevent future ant infestations, keep food stored in airtight containers, promptly clean up spills and crumbs, seal entry points like cracks and gaps, and maintain a clean living environment.',
    },
    {
      question: 'Do you offer eco-friendly options for ant pest control?',
      answer: 'Yes, we offer eco-friendly options for ant pest control, including natural and non-toxic treatments that are safe for the environment and your family.',
    },
    {
      question: 'Will I need to leave my home during ant pest control treatment?',
      answer: 'In most cases, it is not necessary to leave your home during ant pest control treatment. Our technicians use safe and low-odor products that allow you to remain indoors during the treatment process.',
    },
    {
      question: 'How long does an ant pest control treatment session typically last?',
      answer: 'The duration of an ant pest control treatment session varies depending on the size of your property and the severity of the infestation. On average, it may take one to two hours.',
    },
    {
      question: 'Can I schedule ant pest control treatment for a specific time?',
      answer: 'Yes, we strive to accommodate your schedule preferences when scheduling ant pest control treatment. Simply let us know your preferred time, and we will do our best to accommodate it.',
    },
    {
      question: 'Do you offer follow-up visits after ant pest control treatment?',
      answer: 'Yes, we provide follow-up visits as part of our ant pest control service to monitor the effectiveness of the treatment and address any recurring ant activity.',
    },
  ];
  const descriptionDataAntPest = {
    headingDescription_1: "The Importance of  Ant Pest Control Service",
    paraDescription_1: "Ant infestations can quickly escalate into a nuisance, posing health risks and property damage. These tiny pests can infiltrate your home, contaminating food sources and causing structural harm. Neglecting ant infestations can lead to significant inconvenience and potential health hazards for you and your family. Professional ant pest control service is essential in effectively eradicating ant colonies, preventing future infestations, and safeguarding your home's integrity. With specialized techniques and eco-friendly solutions, our service targets ant colonies at the source, ensuring long-lasting relief and peace of mind for homeowners.",
    headingDescription_2: "What we do in  Ant Pest Control Service",
    paraDescription_2: [
        "Comprehensive inspection of the property to identify ant entry points, nesting sites, and conducive conditions.",
        "Strategic placement of ant baits and traps to target ant colonies and disrupt their reproductive cycle.",
        "Application of eco-friendly insecticidal sprays or dusts to eliminate existing ant populations.",
        "Sealing of cracks, crevices, and entry points to prevent ant entry and reinfestation.",
        "Recommendations for sanitation and hygiene practices to deter ant activity and minimize attractants.",
        "Education on ant behavior, biology, and prevention strategies for long-term ant management.",
        "Follow-up visits to monitor ant activity, assess treatment effectiveness, and make necessary adjustments.",
        "Integrated Pest Management (IPM) approach to address ant infestations holistically and minimize environmental impact.",
        "Guaranteed satisfaction with our service and commitment to ensuring a pest-free environment for our customers.",
        "Customized treatment plans available to address specific ant species and infestation severity."
    ]
};

  
  return (
    <div className='main'>
       <PestControlModal {...data} />
       <FaqServices faqData={antPestControlFAQ} />
       <Description {...descriptionDataAntPest} />
       <Benefits />
       <TestimonialCarousel />
       <Footer />
    </div>
  )
}

export default AntPestControl
