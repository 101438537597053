import React from 'react'
//import { FaStar, FaAngleDown , FaPhone, FaPhoenixFramework } from "react-icons/fa";
import { 
  terraceproof,
  sumpproofing,
 
 } from '../../assets';

import AllServicePage from "../AllServicePage";

const Waterproofing = () => {

  const selectedCity = localStorage.getItem('city')

  const data = {

    headingFirst: "Waterproofing" ,
    headingThird: "Proofing",
    headingSecond: "Services",
    description: "Transform your environment with KBS Home Service the unrivaled solution for ensuring a pristine and germ-free space. Contact us now to experience a renewed and hygienic living atmosphere ",
    rating: 4.9,
    reviews: "Based on 397 Reviews",
    imageLink1: terraceproof,
    link1:`/terrace-proofing-service-in-${selectedCity.toLowerCase()}`,
    name1: "Terrace ",
    imageLink2: sumpproofing,
    link2:`/sump-proofing-service-in-${selectedCity.toLowerCase()}`,
    name2: "Sump ",
    descriptionMeta:"Keep your property dry and protected with our expert Waterproofing Service. From basements to roofs, we'll prevent water damage and extend the lifespan of your building. Ensure lasting durability—schedule your waterproofing service today!",
    bgImageUrl: "https://5.imimg.com/data5/SELLER/Default/2022/1/VS/XQ/PU/43968190/terries-water-proof.png",
    serviceType: ["Terrace proofing", "Sump proofing"]
  }
  
  return (
    <div className="">
    <AllServicePage {...data} numVisibleService={2} />
  </div>
  )
}

export default Waterproofing
