import React from 'react'

import {  commercialsanitize } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';

import DisinfectionModal from './DisinfectionModal';
import FaqServices from '../../Components/FaqServices';
import Description from "../../Components/Description";

const CommercialSanitization = () => {

  const data = {
    headingFirst: "Commercial Sanitization ",
    headingSecond: "Commercial Sanitization",
    price: "₹3999",
    descriptionMeta: "Maintain a safe and hygienic environment for your business with our reliable Commercial Sanitization Service. From offices to retail spaces, we'll disinfect and sanitize every surface to minimize the risk of infection. Keep your employees and customers safe—contact us for professional sanitization solutions!",
    description: [
      "Thorough sanitization of commercial spaces",
      "Disinfection of high-touch surfaces and common areas",
      "Sanitization of office furniture, desks, and chairs",
      "Cleaning and disinfection of electronic equipment",
      "Specialized sanitization for restrooms and shared facilities",
      "Decontamination of kitchen and breakroom areas",
      "Sanitization of elevators, handrails, and door handles",
      "Deep cleaning of ventilation systems and air ducts",
      "Fogging or spraying of disinfectants for comprehensive coverage",
      "Sanitization of meeting rooms, conference tables, and equipment",
      "Cleaning and disinfection of lobby and reception areas",
      "Targeted cleaning of frequently used equipment and machinery",
    ],
    image: commercialsanitize,
    space: "commercial space",
    size: "compound",
    
  
  } 
  const faqData = [
    {
      question: 'How frequently should I schedule commercial sanitization for my business?',
      answer: 'The frequency of commercial sanitization depends on factors like foot traffic, the nature of your business, and local health guidelines. We recommend scheduling regular sanitization, at least once a month.',
    },
    {
      question: 'What areas of my commercial space will be sanitized?',
      answer: 'Our commercial sanitization service covers high-touch surfaces, common areas, workstations, restrooms, and other relevant spaces. We prioritize areas with a higher risk of germ transmission.',
    },
    {
      question: 'Do you use EPA-approved disinfectants for commercial sanitization?',
      answer: 'Yes, we use EPA-approved disinfectants that are effective against a broad spectrum of bacteria and viruses. Our products are safe for use in commercial spaces.',
    },
    {
      question: 'Is the sanitization process safe for electronic equipment and sensitive materials?',
      answer: 'Our sanitization process is designed to be safe for electronic equipment and sensitive materials commonly found in commercial spaces. We take precautions to avoid damage during the sanitization process.',
    },
    {
      question: 'Can you accommodate after-hours or weekend sanitization appointments for businesses?',
      answer: 'Yes, we understand the importance of minimizing disruption to your business. We can accommodate after-hours or weekend sanitization appointments to suit your schedule.',
    },
    {
      question: 'How often should I schedule commercial sanitization?',
      answer: 'We recommend scheduling commercial sanitization regularly, at least once a month, to maintain a clean and hygienic environment for your business.',
    },
    {
      question: 'Which areas of my commercial space will be sanitized?',
      answer: 'Our commercial sanitization service covers high-touch surfaces, common areas, workstations, restrooms, and other relevant spaces to reduce the risk of germ transmission.',
    },
    {
      question: 'Do you use EPA-approved disinfectants?',
      answer: 'Yes, we use EPA-approved disinfectants that are effective against bacteria and viruses. Our products are safe for use in commercial spaces.',
    },
    {
      question: 'Is the sanitization process safe for electronic equipment?',
      answer: 'Our sanitization process is safe for electronic equipment commonly found in commercial spaces. We take precautions to avoid damage during the process.',
    },
    {
      question: 'Do you offer after-hours or weekend sanitization appointments?',
      answer: 'Yes, we can accommodate after-hours or weekend sanitization appointments to minimize disruption to your business operations.',
    },
  ];
  const descriptionData = {
    headingDescription_1: "Why Commercial Sanitization Service is Essential",
    paraDescription_1: "Maintaining a clean and sanitized environment is crucial for businesses, especially in high-traffic areas such as offices, retail stores, and public facilities. Regular cleaning may not suffice to eliminate harmful bacteria, viruses, and pathogens that can pose health risks to employees, customers, and visitors. Commercial sanitization service plays a vital role in preventing the spread of illnesses and ensuring a safe and hygienic workplace or commercial space. With specialized sanitization techniques and hospital-grade disinfectants, our service targets germs and viruses on surfaces, equipment, and high-touch areas, effectively reducing the risk of infection and promoting peace of mind. Investing in professional commercial sanitization is not just about meeting regulatory standards; it's about prioritizing the health and well-being of your workforce and customers.",
    headingDescription_2: "What We Do in Commercial Sanitization Service",
    paraDescription_2: [
        "Comprehensive disinfection of high-touch surfaces, including doorknobs, light switches, and countertops.",
        "Sanitization of common areas such as lobbies, waiting rooms, and break rooms to minimize the spread of germs.",
        "Deep cleaning and sanitization of restrooms, including toilets, sinks, and fixtures, to maintain hygiene standards.",
        "Treatment of HVAC systems and air ducts to improve indoor air quality and reduce airborne contaminants.",
        "Sanitization of shared office equipment, including keyboards, mice, and telephones, to prevent cross-contamination.",
        "Fogging or misting application of disinfectants to reach inaccessible areas and ensure thorough coverage.",
        "Regularly scheduled sanitization services to maintain cleanliness and hygiene standards in commercial spaces.",
        "Certification and documentation of sanitization procedures for compliance and peace of mind.",
        "Customized sanitization plans tailored to the unique needs and requirements of each business.",
        "24/7 emergency response and sanitization services available to address unexpected situations or outbreaks."
    ]
};

  
 
  
  return (
    <div className='main'>
      <DisinfectionModal {...data} />
      <FaqServices faqData={faqData} />
      <Description {...descriptionData} />
      <Benefits />
      <TestimonialCarousel />
      <Footer />
    </div>
  )
}

export default CommercialSanitization
