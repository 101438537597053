import React, { useState, useEffect } from "react";
import VisibilitySensor from "react-visibility-sensor";

const Benefits = () => {
  const formatNumber = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + "M";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K";
    } else {
      return num.toLocaleString();
    }
  };

  const [completedJobs, setCompletedJobs] = useState(0);
  const [repeatCustomers, setRepeatCustomers] = useState(0);
  const [monthlyJobRequests, setMonthlyJobRequests] = useState(0);
  const [starReviews, setStarReviews] = useState(0);

  const handleVisibilityChange = (isVisible) => {
    if (isVisible) {
      const duration = 1500; // Total duration in milliseconds
      const startTimestamp = Date.now();

      const animateValues = () => {
        const currentTimestamp = Date.now();
        const progress = Math.min(1, (currentTimestamp - startTimestamp) / duration);

        setCompletedJobs(Math.floor(progress * 1000000));
        setRepeatCustomers(progress * 85);
        setMonthlyJobRequests(Math.floor(progress * 4000));
        setStarReviews(Math.floor(progress * 23000));

        if (progress < 1) {
          requestAnimationFrame(animateValues);
        }
      };

      requestAnimationFrame(animateValues);
    }
  };

  return (
    <VisibilitySensor onChange={handleVisibilityChange} partialVisibility>
      <div className="flex flex-wrap pt-10 pb-10 bg-secondary text-white flex-col justify-center items-center font-poppins">
        <div className="font-poppins sm:text-4xl text-lg sm:font-semibold text-center font-bold sm:mt-4 sm:mb-4">
          The Unique Benefits of KBS Home Services
        </div>
        <div className=" grid md:gap-[80px]  sm:gap-[30px] md:grid sm:grid-cols-4 grid-cols-2 gap-7 mt-5 place-content-center ">
          <div className="px-2 py-4  bg-[#93c5fd]/50 rounded-2xl md:w-[150px] w-[130px] sm:h-auto h-[90px]  hover:scale-105 transition-transform duration-300 ease-in-out">
            <div className="flex flex-col justify-center items-center">
              <h1 className="font-bold lg:text-3xl text-xl">{formatNumber(completedJobs)} +</h1>
              <h1 className="font-poppins lg:text-md text-xs text-center mt-2 sm:mt-0">Completed Jobs</h1>
            </div>
          </div>
          <div className="px-2 py-4  bg-[#93c5fd]/50 rounded-2xl lg:w-[150px] w-[130px] sm:h-auto h-[90px]  hover:scale-105 transition-transform duration-300 ease-in-out">
            <div className="flex flex-col justify-center items-center">
              <h1 className="font-bold lg:text-3xl text-xl">{formatNumber(repeatCustomers.toFixed(1))}%</h1>
              <h1 className="font-poppins lg:text-md text-xs text-center mt-2 sm:mt-0">Repeat Customers</h1>
            </div>
          </div>
          <div className="px-2 py-4  bg-[#93c5fd]/50 rounded-2xl lg:w-[150px] w-[130px] sm:h-auto h-[90px]  hover:scale-105 transition-transform duration-300 ease-in-out">
            <div className="flex flex-col justify-center items-center">
              <h1 className="font-bold lg:text-3xl text-xl">{formatNumber(monthlyJobRequests)} +</h1>
              <h1 className="font-poppins text-center lg:text-md text-xs ">Monthly Job Requests</h1>
            </div>
          </div>
          <div className="px-2 py-4 bg-[#93c5fd]/50 rounded-2xl lg:w-[150px] w-[130px] sm:h-auto h-[90px]  hover:scale-105 transition-transform duration-300 ease-in-out">
            <div className="flex flex-col justify-center items-center">
              <h1 className="font-bold lg:text-3xl text-xl">{formatNumber(starReviews)} +</h1>
              <h1 className="font-poppins lg:text-md text-xs mt-2 sm:mt-0">5 Star Reviews</h1>
            </div>
          </div>
        </div>
      </div>
    </VisibilitySensor>
  );
};

export default Benefits;
