import React from "react";

import {  sofa } from "../../assets";

import TestimonialCarousel from "../Testimonials";
import Footer from "../../Components/Footer";
import Benefits from "../Benefits";



import CleaningServiceModal from "./CleaningServicesModel";
import FaqServices from "../../Components/FaqServices";
import Description from "../../Components/Description";

const SofaCleaning = () => {

  const faqData = [
    {
      question: 'How often should I schedule professional sofa cleaning?',
      answer: 'It is recommended to schedule professional sofa cleaning at least once a year to maintain cleanliness, remove dirt, and extend the life of your sofas.',
    },
    {
      question: 'Can professional sofa cleaning remove stains and odors?',
      answer: 'Yes, our professional sofa cleaning service is effective in removing stains, odors, and deep-seated dirt, leaving your sofas fresh and revitalized.',
    },
    {
      question: 'Is it safe for children and pets after sofa cleaning?',
      answer: 'Once the sofas are fully dry, it is safe for children and pets to use the cleaned furniture. We use safe and non-toxic cleaning solutions for sofa cleaning.',
    },
    {
      question: 'Do you clean different types of upholstery fabrics?',
      answer: 'Yes, our sofa cleaning service is suitable for various upholstery fabrics, including leather, microfiber, polyester, and more. We tailor the cleaning process to each fabric type.',
    },
    {
      question: 'Do you provide Scotchgard or fabric protection for sofas?',
      answer: 'Yes, we offer fabric protection treatments such as Scotchgard to help repel stains and spills, making it easier to clean and maintain the appearance of your sofas.',
    },
     {
    question: 'How long does it take for sofas to dry after professional cleaning?',
    answer: 'The drying time for sofas after professional cleaning varies depending on factors such as fabric type, humidity, and ventilation. On average, it may take anywhere from 4 to 12 hours for sofas to dry completely.',
  },
  {
    question: 'Can professional sofa cleaning help alleviate allergy symptoms?',
    answer: 'Yes, professional sofa cleaning can help reduce allergens such as dust, pet dander, and pollen trapped in upholstery fibers, which may alleviate allergy symptoms for sensitive individuals.',
  },
  {
    question: 'Do you offer deodorizing treatments as part of sofa cleaning?',
    answer: 'Yes, we provide deodorizing treatments as part of our sofa cleaning service to eliminate odors and leave your sofas smelling fresh and clean.',
  },
  {
    question: 'Are your sofa cleaning products eco-friendly?',
    answer: 'Absolutely! We prioritize the use of eco-friendly and biodegradable cleaning products for sofa cleaning to minimize environmental impact and ensure the safety of your home.',
  },
  {
    question: 'Can professional sofa cleaning help prolong the lifespan of my furniture?',
    answer: 'Yes, regular professional sofa cleaning can help extend the lifespan of your furniture by removing dirt, dust, and debris that can cause wear and tear over time. It also helps maintain the appearance and integrity of upholstery fabrics.',
  },
  {
    question: 'How long does it take for sofas to dry after professional cleaning?',
    answer: 'The drying time for sofas after professional cleaning varies depending on factors such as fabric type, humidity, and ventilation. On average, it may take anywhere from 4 to 12 hours for sofas to dry completely.',
  },
  {
    question: 'Can professional sofa cleaning help alleviate allergy symptoms?',
    answer: 'Yes, professional sofa cleaning can help reduce allergens such as dust, pet dander, and pollen trapped in upholstery fibers, which may alleviate allergy symptoms for sensitive individuals.',
  },
  {
    question: 'Do you offer deodorizing treatments as part of sofa cleaning?',
    answer: 'Yes, we provide deodorizing treatments as part of our sofa cleaning service to eliminate odors and leave your sofas smelling fresh and clean.',
  },
  {
    question: 'Are your sofa cleaning products eco-friendly?',
    answer: 'Absolutely! We prioritize the use of eco-friendly and biodegradable cleaning products for sofa cleaning to minimize environmental impact and ensure the safety of your home.',
  },
  {
    question: 'Can professional sofa cleaning help prolong the lifespan of my furniture?',
    answer: 'Yes, regular professional sofa cleaning can help extend the lifespan of your furniture by removing dirt, dust, and debris that can cause wear and tear over time. It also helps maintain the appearance and integrity of upholstery fabrics.',
  },
  ];
  

  const data = {
    headingFirst: "Sofa",
    headingSecond: "Sofa",
    price: "₹1999",
    descriptionMeta: "Restore the beauty of your sofa with our professional Sofa Cleaning Service. Say goodbye to stains and odors, and hello to comfort and freshness. Book now for a rejuvenated living space!",
    
    description: [
      "Thorough cleaning of sofas, couches, and lounge chairs",
  "Removal of dust, dirt, and allergens from upholstery",
  "Vacuuming and deep cleaning of sofa fabric or leather",
  "Stain removal and treatment for stubborn marks",
  "Application of fabric conditioner or leather conditioner for a fresh look",
  "Deodorization to eliminate odors and leave a pleasant fragrance",
  "Professional cleaning techniques to preserve the quality and lifespan of your sofas",
  "Expertise in handling various sofa materials, including fabric and leather",
  "Attention to detail for intricate designs and crevices",
  "Comprehensive service to enhance the appearance and hygiene of your living space",
  "Additional services for throw pillows and cushions if required",
     
     
     
    ],
    image: sofa,
    space: "Siitig space",
    size: "sofas",
    
  
  }

  const sofaCleaningDescriptionData = {
    headingDescription_1: "Why Sofa Cleaning Service is Essential",
    paraDescription_1: "Maintaining the cleanliness of your sofa is essential not only for enhancing the aesthetics of your living space but also for promoting a healthy indoor environment. Over time, sofas can accumulate dirt, dust, allergens, and stains from regular use, spills, and pet dander. Neglecting proper cleaning and maintenance can lead to the proliferation of bacteria, mold, and unpleasant odors, posing health risks to you and your family. Professional sofa cleaning service plays a crucial role in deep cleaning and sanitizing your sofa, removing dirt, stains, and allergens, and restoring its freshness and comfort. With specialized cleaning techniques and eco-friendly products, our service effectively rejuvenates your sofa's appearance, extends its lifespan, and creates a cleaner and healthier home environment.",
    headingDescription_2: "What We Do in Sofa Cleaning Service",
    paraDescription_2: [
        "Pre-inspection of the sofa to assess its condition and identify any stains or problem areas.",
        "Vacuuming of the sofa to remove loose dirt, dust, and debris from the surface and crevices.",
        "Spot treatment of stains and spills using appropriate cleaning solutions to break down and lift stains.",
        "Deep cleaning of the sofa using hot water extraction or steam cleaning methods to remove embedded dirt and allergens.",
        "Extraction of cleaning solutions and moisture from the sofa to expedite drying and prevent water damage.",
        "Application of fabric protectors or deodorizers to enhance stain resistance and eliminate odors.",
        "Grooming of the sofa fibers to restore their appearance and texture and promote even drying.",
        "Final inspection to ensure that the sofa is thoroughly cleaned, sanitized, and meets our quality standards.",
        "Customized add-on services available upon request, such as pet hair removal or fabric conditioning, to address specific cleaning needs or concerns."
    ]
};


  
  return (
   <>
   <CleaningServiceModal {...data} />
   <FaqServices faqData={faqData} />
   <Description {...sofaCleaningDescriptionData} />
   <Benefits />
   <TestimonialCarousel />
   <Footer />
   
   </>
  );
};

export default SofaCleaning;
