import React from "react";
import Helmet from "react-helmet";

const MetaData = ({ title , descriptionMeta
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content= {descriptionMeta}/>
      
    </Helmet>
  );
};

export default MetaData;
