import React, { useState } from "react";
// import { FaPlusCircle, FaMinusCircle, FaAngleDown } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
// import { faq } from "../assets";

import { motion, AnimatePresence } from "framer-motion";

const FaqServices = ({ faqData }) => {
  const [faqs, setFaqs] = useState(faqData);

  const toggleFaqFirstSet = (index) => {
    const updatedFaqs = [...faqs];
    updatedFaqs[index].isOpen = !updatedFaqs[index].isOpen;
    setFaqs(updatedFaqs);
  };

  const toggleFaqSecondSet = (index) => {
    const updatedFaqs = [...faqs];
    updatedFaqs[index + 5].isOpen = !updatedFaqs[index + 5].isOpen;
    setFaqs(updatedFaqs);
  };

  return (
    <div className="faq bg-slate-100 pt-14 font-poppins pb-10">
      <h6 className="text-2xl font-semibold">Curious about our services?</h6>
      <div className="text-lg font-bold mt-4">Frequently asked questions</div>
      <div className="faq-content mt-[20px] flex flex-col md:flex-row">
        <div className="faq-main-content bg-opacity-0 w-full flex flex-col ml-8 mr-8">
          {faqs.slice(0, 5).map((faq, index) => (
            <div
              key={index}
              className={`faq-item ${faq.isOpen ? "open" : ""} `}
              onClick={() => toggleFaqFirstSet(index)}
            >
              <div className="faq-question flex justify-between items-center">
                <div className="text-sm sm:text-lg text-start">
                  {faq.question}
                </div>
                <motion.div
                  initial={false}
                  animate={{ rotate: faq.isOpen ? 180 : 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <IoIosArrowDown size={20} />
                </motion.div>
              </div>
              <AnimatePresence>
                {faq.isOpen && (
                  <div className="bg-white px-3  py-1">
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3, ease: "linear" }}
                      style={{ overflow: "hidden" }}
                      className="faq-answer text-start text-sm  "
                    >
                      {faq.answer}
                    </motion.div>
                  </div>
                )}
              </AnimatePresence>
              <hr className="faq-line" />
            </div>
          ))}
        </div>
        <div className="faq-main-content w-full flex flex-col ml-8 mr-8">
          {faqs.slice(5, 10).map((faq, index) => (
            <div
              key={index}
              className={`faq-item ${faq.isOpen ? "open" : ""} `}
              onClick={() => toggleFaqSecondSet(index)}
            >
              <div className="faq-question flex justify-between items-center">
                <div className="text-sm sm:text-lg text-start">
                  {faq.question}
                </div>
                <motion.div
                  initial={false}
                  animate={{ rotate: faq.isOpen ? 180 : 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <IoIosArrowDown size={20} />
                </motion.div>
              </div>
              <AnimatePresence>
                {faq.isOpen && (
                  <div className="bg-white px-3 py-1">
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3, ease: "linear" }}
                      style={{ overflow: "hidden" }}
                      className="faq-answer text-start text-sm "
                    >
                      {faq.answer}
                    </motion.div>
                  </div>
                )}
              </AnimatePresence>
              <hr className="faq-line" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FaqServices;
