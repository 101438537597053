import {facebook, instagram, pinterest, twitter  } from "../assets";
// import { ContactPage } from "../components";
export const navLinks = [
  {
    id: "/",
    title: "Home",
  },
  {
    id: "/Portfolio",
    title: "Portfolio",
  },
  {
    id: "/About",
    title: "About",
  },
 
  {
    id: "/Contact-us",
    title: "Contact us",
  },
];




export const footerLinks = [
 /* {
    title: "Useful Links",
    links: [
      {
        name: "Talk To Designer",
        link: "http://google.com/",
      },
      {
        name: "Testimonials",
        link: "http://google.com/",
      },
      {
        name: "Blog",
        link: "http://google.com/",
      },
      {
        name: "Get a Quote",
        link: "http://google.com/",
      },
    ],
  },*/
  // {
  //   title: "Our Services",
  //   links: [
  //     {
  //       name: "Cleaning Service",
  //       link: "/cleaning-services-in-bangalore",
  //     },
  //     {
  //       name: "Pest Control Service",
  //       link: "/pest-control-services-in-bangalore",
  //     },
  //     {
  //       name: "Painting Service",
  //       link: "/paint-service-in-bangalore",
  //     },
  //     {
  //       name: "Disinfection Services",
  //       link: "/disinfection-service-in-bangalore",
  //     },
  //     {
  //       name: "Car Washing",
  //       link: "/car-washing-in-Bangalore",
  //     },
  //     {
  //       name: "Waterproofing Services",
  //       link: "/water-proofing-service-in-bangalore",
  //     },
  //     {
  //       name: "Sump Cleaning ",
  //       link: "/sump-cleaning-in-bangalore",
  //     },
  //     {
  //       name: "Decoration Services",
  //       link: "/decoration-service-in-bangalore",
  //     },
  //     {
  //       name: "Interior Designing",
  //       link: "/interior-designing-service-in-bangalore"
  //     }
     
  //   ],
  // },
  {
    title: "About Us",
    links: [
     
     
      {
        name: "Blog",
        link: "/blog",
      },
      
      {
        name: "Contact Us",
        link: "/contactus",
      },
      {
        name: "About Us",
        link: "/aboutus",
      },
     
      {
        name: "Partner With Us",
        link: "/vendor-form",
      },
      {
        name: "Career",
        link: "/career",
      },
    ],
  },
  // {
  //   title: "Serving In India",
  //   links: [
  //     {
  //       name: "Bangalore",
  //       link: "/",
  //     },
  //     {
  //       name: "Ahmedabad",
  //       link: "/",
  //     },
  //     {
  //       name: "Chennai",
  //       link: "/",
  //     },
  //     {
  //       name: "Noida",
  //       link: "/",
  //     },
  //     {
  //       name: "Pune",
  //       link: "/",
  //     },
  //     {
  //       name: "Kolkata",
  //       link: "/",
  //     },
  //     {
  //       name: "Mumbai",
  //       link: "/",
  //     },
  //     {
  //       name: "Mysuru",
  //       link: "/",
  //     },
  //     {
  //       name: "Gurugram",
  //       link: "/",
  //     },
  //     {
  //       name: "Delhi",
  //       link: "/",
  //     },
     
  //   ],
  // },
  {
    title: "Get in Touch",
    links: [
      {
        name: "Call us",
        link: "tel:6363617916",
      },
      {
        name: "Mail",
        link: "/contactus",
      },
      {
        name: "Chat With Us",
        link: "https://api.whatsapp.com/send?phone=+916363617916&text=Hi,%20I%20would%20like%20to%20discuss%20further%20on%20the%20services%20offered%20by%20KBS%20Home%20Services...%20Kindly%20connect.%20Thanks",
      },
    ],
  },
];

export const socialMedia = [
  {
    id: "social-media-1",
    icon: instagram,
    link: "https://www.instagram.com/kbshomeservices",
  },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/KBShomeservices",
  },
  {
    id: "social-media-3",
    icon: twitter,
    link: "https://twitter.com/KBShomeservices",
  },

  // {
  //   id: "social-media-4",
  //   icon: linkedin,
  //   link: "https://www.linkedin.com/",
  // },
];

export const cityOptionsObj = {
  bangalore: "Bangalore",
  ahmedabad: "Ahmedabad",
  noida : "Noida",
  chennai :"Chennai",
  pune: "Pune",
  delhi :"Delhi",
  kolkata: "Kolkata",
  mumbai: "Mumbai",
  mysuru: "Mysuru",
  gurugram: "Gurugram",
};

export const cityOptions = [
  "Bangalore",
  "Ahmedabad",
  "Noida",
  "Chennai",
  "Pune",
  "Delhi",
  "Kolkata",
  "Mumbai",
  "Mysuru",
  "Gurugram",
];

export const serviceOptionsObj={
  
  DeepHouseCleaning:"deep-home-cleaning",
  OfficeCleaning: "Office Cleaning",
  VillaCleaning: "Villa Cleaning",
  WaterTankCleaing: "Water Tank / Sump Cleaning",
  VacantHomeCleaning: "Vacant Home Cleaning",
  BathroomCleaning: "bathroom-cleaning",
  KitchenDeepCleaning: "Kitchen Deep Cleaning",
  SofaCleaning: "Sofa Cleaning",
  CarpetCleaning :"Carpet Cleaning",
  TerraceWaterproofing : "Terrace Waterproofing",
  SumpWaterproofing : "Sump Waterproofing",
}

export const serviceOptions = [
  "Basic House Cleaning",
  "deep-home-cleaning",
  "office-cleaning",
  "villa-cleaning",
  "water-tank-service",
  "vacant-cleaning",
  "bathroom-cleaning",
  "kitchen-cleaning",
  "sofa-cleaning",
  "carpet-cleaning",
  "water-proofing-service",
  "sump-proofing-service",
];