import React from "react";

import {
  
  carpet,
  commercial,
  bathroom,
  sofa,
  kitchen,
  oofice,
  villa,
  house,
  carwash,
  vacanthouse,
  bgcleaning,
  
} from "../../assets";

import AllServicePage from "../AllServicePage";

const CleaningService = () => {
  const selectedCity = localStorage.getItem('city')

  const data = {
    headingFirst: " Cleaning",
    headingThird: "Cleaning",
    headingSecond: " Services",
    description:
      "Transform your environment with KBS Home Service the unrivaled solution for ensuring a pristine and germ-free space. Contact us now to experience a renewed and hygienic living atmosphere ",
    rating: 4.8,
    reviews: "Based on 521 Reviews",
    imageLink1: house,
    link1: `/deep-home-cleaning-in-${selectedCity.toLowerCase()}`,
    name1: "Deep Home ",
    imageLink2: bathroom,
    link2: `/bathroom-cleaning-in-${selectedCity.toLowerCase()}`,
    name2: "Bathroom ",
    imageLink3: kitchen,
    link3: `/kitchen-cleaning-in-${selectedCity.toLowerCase()}`,
    name3: "Kitchen ",
    imageLink4: commercial,
    link4: `/commercial-cleaning-in-${selectedCity.toLowerCase()}`,
    name4: "Commercial ",
    imageLink5: villa,
    link5: `/villa-cleaning-in-${selectedCity.toLowerCase()}`,
    name5: "Villa ",
    imageLink6: carpet,
    link6: `/carpet-cleaning-in-${selectedCity.toLowerCase()}`,
    name6: "Carpet ",
    imageLink7: oofice,
    link7: `/office-cleaning-in-${selectedCity.toLowerCase()}`,
    name7: "Office ",
    imageLink8: sofa,
    link8: `/sofa-cleaning-in-${selectedCity.toLowerCase()}`,
    name8: "Sofa",
    imageLink9: vacanthouse,
    link9: `/vacant-cleaning-in-${selectedCity.toLowerCase()}`,
    name9: "Vacant Home",
   imageLink10: carwash,
   link10: `/car-washing-in-${selectedCity.toLowerCase()}`,
   name10: "Car ",
   bgImageUrl: bgcleaning,
   descriptionMeta: "Experience spotless spaces with our professional Cleaning Service. From homes to offices, trust us for tailored cleaning solutions. Schedule your clean today!",


   serviceType: [ "Office Cleaning" , "Deep Home Cleaning" , "Bathroom Cleaning", "Commercial Cleaning", "Car Washing","Sofa Cleaning",
  "Kitchen Cleaning" , "Villa Cleaning" , "Vacant House Cleaning", "Carpet Cleaning"]
  };
 
  return (
    <div className="">
      <AllServicePage {...data}  />
    </div>
  );
};

export default CleaningService;
