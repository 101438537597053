import React from 'react'

import {  pest } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';

import PestControlModal from './PestControlModal';

import FaqServices from '../../Components/FaqServices';
import Description from '../../Components/Description';



const GeneralPestControl = () => {

  const data = {
    headingFirst: "General Pest",
    headingSecond: "General Pest",
    price: "₹3999",
    description: [
      "Comprehensive inspection for common pests such as insects and rodents",
      "Application of appropriate pesticides to targeted areas",
      "Sealing of entry points to prevent future pest infestations",
      "Treatment for crawling insects in all accessible corners and crevices",
      "Rodent baiting and trapping to control and eliminate rodent populations",
      "Application of insecticide or pesticide to eliminate flying insects",
      "Monitoring and follow-up visits to ensure long-term pest control",
      "Safe and eco-friendly pest control solutions",
      "Customized treatment plans based on the type of pests identified",
      "Education on preventive measures to avoid future pest problems",
      "Disposal of pest-infested materials in a responsible manner",
    ],
    image: pest,
    space: "living space",
    size: "house",
    descriptionMeta: "Protect your property from a variety of pests with our comprehensive General Pest Control Service. From ants to termites, we'll address all your pest control needs with professionalism and expertise. Safeguard your space—schedule your service today!",
  
  }
  const pestControlFAQ = [
    {
      question: 'How often should I schedule professional pest control?',
      answer: 'The frequency of professional pest control depends on factors such as the type of pests, your location, and the level of infestation. For preventive measures, scheduling it once every 3 to 6 months is recommended.',
    },
    {
      question: 'Which pests can your general pest control service effectively target?',
      answer: 'Our general pest control service is designed to target a wide range of common pests, including ants, cockroaches, spiders, rodents, and other household pests.',
    },
    {
      question: 'Is the pest control treatment safe for children and pets?',
      answer: 'Yes, we use safe and approved pest control products. Once the treatment is applied and has dried, it is safe for children and pets to be in the treated areas.',
    },
    {
      question: 'Do I need to leave my home during the pest control treatment?',
      answer: 'For most general pest control treatments, it is not necessary to leave your home. Our technicians will provide guidance on any specific precautions during the treatment.',
    },
    {
      question: 'What steps can I take to prevent pests between professional treatments?',
      answer: 'Maintaining cleanliness, sealing entry points, proper food storage, and regular inspection are essential steps to prevent pests. Our technicians can provide personalized tips based on your situation.',
    },
    {
      question: 'Can I schedule pest control services for specific pests only?',
      answer: "Yes, we offer targeted pest control services for specific pests based on your needs. Whether it's ants, rodents, termites, or other pests, we can tailor our services accordingly.",
    },
    {
      question: 'How long does the effect of pest control treatment last?',
      answer: 'The duration of pest control treatment effectiveness varies depending on factors like the type of pests, environmental conditions, and level of infestation. Generally, treatments provide protection for several weeks to months.',
    },
    {
      question: 'Are there any preparations I need to make before pest control treatment?',
      answer: 'Our technicians will provide specific instructions before the treatment, which may include removing food items, covering or storing sensitive items, and ensuring access to all areas needing treatment.',
    },
    {
      question: 'What should I do if I notice pests after the treatment?',
      answer: 'If you notice pests after the treatment, contact us immediately. We may need to schedule a follow-up visit to address any lingering pest activity and ensure complete elimination.',
    },
    {
      question: 'Do you offer eco-friendly pest control options?',
      answer: 'Yes, we offer eco-friendly pest control options that use environmentally safe products and practices. These options are suitable for those seeking green and sustainable pest control solutions.',
    },
  ];
  const descriptionData = {
    headingDescription_1: "Why Professional Pest Control Service is Essential",
    paraDescription_1: "Protecting your home from pests is crucial for safeguarding the health and well-being of your family. Pests like rodents, insects, and termites can not only cause structural damage to your property but also spread diseases and contaminate food sources. Ignoring pest infestations can lead to significant problems and costly repairs down the line. Professional pest control service is essential for effectively identifying and eliminating pests, preventing future infestations, and ensuring a safe and hygienic living environment for you and your loved ones. With specialized techniques and environmentally friendly products, our service targets pests at their source, providing long-lasting protection and peace of mind. Investing in professional pest control is an investment in the health, safety, and comfort of your home.",
    headingDescription_2: "What We Do in Professional Pest Control Service",
    paraDescription_2: [
        "Thorough inspection of the property to identify pest entry points, nesting areas, and conducive conditions.",
        "Implementation of customized treatment plans tailored to your specific pest infestation and property needs.",
        "Application of targeted treatments to eliminate pests, including baits, traps, and eco-friendly pesticides.",
        "Sealing of entry points and exclusion measures to prevent future pest ingress and reinfestation.",
        "Treatment of interior and exterior areas, including attics, basements, crawl spaces, and perimeter walls.",
        "Monitoring and follow-up visits to ensure the effectiveness of treatments and address any remaining pest activity.",
        "Education and recommendations for pest prevention practices to minimize the risk of future infestations.",
        "Humane removal and relocation of wildlife pests, such as squirrels, raccoons, and birds, as needed.",
        "Prompt and courteous customer service, with satisfaction guaranteed.",
        "Emergency response services available for urgent pest control needs.",
    ]
}

  
  
  
  return (
    <div className='main'>
       
   <PestControlModal {...data} />
   <FaqServices faqData={pestControlFAQ} />
   <Description {...descriptionData} />
   <Benefits />
   <TestimonialCarousel />
   <Footer />
    </div>
  )
}

export default GeneralPestControl
