import React from 'react';

import { deephouse, kitchen, mosquito , bedbug, deepcleaning2, decorate } from '../assets';
import Footer from '../Components/Footer';
import MetaData from '../Metadata';

const Blog = () => {
  const blogs = [
    {
      title: 'The Importance of Terrace Waterproofing',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ...',
      image: kitchen,
    },
    {
      title: 'Effective Pest Control Services for a Healthy Home',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ...',
      image: mosquito,
    },
    {
      title: 'Comprehensive Cleaning Services for a Neat Home',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ...',
      image: deephouse,
    },
      {
        title: 'Dealing with Bedbugs: Tips for Effective Pest Control',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ...',
        image: bedbug,
      },
      {
        title: 'Adding Sparkle to Your Celebrations: Festival Decoration Services',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ...',
        image: decorate,
      },
      {
        title: 'Ensuring a Safe Environment: Importance of Sanitization Services',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ...',
        image: deepcleaning2    ,
      },
  ];

  return (
    <><MetaData title="Blog - KBS Home Services" /><div className="bg-gray-100 mt-10">
      {/* Header */}
      <header className="bg-blue-500 text-white text-center py-4 p">
        <h1 className="text-3xl font-bold font-poppins">KBS Home Services Blog</h1>
      </header>

      {/* Blog Posts */}
      <div className="container mx-auto mt-8 mb-8 px-4 ">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogs.map((blog, index) => (
            <div key={index} className="bg-white p-4 shadow-md rounded-md hover:transition-transform hover:scale-105">
              <img
                loading="lazy"
                src={blog.image}
                alt={blog.title}
                className="w-full h-40 object-cover mb-4 rounded-md" />
              <h2 className="text-xl font-semibold mb-2">{blog.title}</h2>
              <p className="text-gray-600">{blog.content}</p>
              <a
                href="#"
                className="mt-4 inline-block text-blue-500 hover:underline"
              >
                Read more
              </a>
            </div>
          ))}
        </div>
      </div>


      <Footer />
    </div></>
  );
};

export default Blog;
