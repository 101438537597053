/* eslint-disable no-undef */
import React, { useState } from "react";

import { check } from "../assets";
import Faq from "../pages/FAQ";
import TestimonialCarousel from "../pages/Testimonials";
import Footer from "../Components/Footer";
import Benefits from "../pages/Benefits";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import HomeCleaning from "../assets/videos/DeepHomeCleaning.mp4";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaAngleDown } from "react-icons/fa";
import MetaData from "../Metadata";

const AllServicePage = ({
  headingFirst,
  headingThird,
  headingSecond,
  description,
  options,
  rating,
  reviews,
  imageLink1,
  link1,
  name1,
  imageLink2,
  link2,
  name2,
  imageLink3,
  link3,
  name3,
  imageLink4,
  link4,
  name4,
  imageLink5,
  link5,
  name5,
  imageLink6,
  link6,
  name6,
  imageLink7,
  link7,
  name7,
  imageLink8,
  link8,
  name8,
  imageLink9,
  link9,
  name9,
  imageLink10,
  link10,
  name10,
  numVisibleService,
  serviceType,
  bgImageUrl,
  descriptionMeta
}) => {
  const [service, setService] = useState(serviceType[0]);

  const handleServiceChange = (e) => {
    const selectedService = e.target.value;
    setService(selectedService); // Update the service state
    setState({
      ...state,
      service: selectedService, // Update the service property of the state object
    });
  };

  const formArray = [1, 2];
  const [formNo, setFormNo] = useState(formArray[0]);

  const [state, setState] = useState({
    name: "",
    email: "",
    mobile: "",
    location: "",
    service: `${serviceType[0]} Service `,
    message: "",
  });
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");

  const inputHandle = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    let isValid = true;

    // Name validation
    if (state.name.length < 3) {
      setNameError("Name must be at least 3 characters long");
      isValid = false;
    } else {
      setNameError("");
    }

    // Email validation
    if (!state.email.includes("@") || state.email.length < 5) {
      setEmailError("Please enter a valid email address");
      isValid = false;
    } else {
      setEmailError("");
    }

    // Mobile number validation
    if (
      !/^\d+$/.test(state.mobile) ||
      state.mobile.length < 10 ||
      state.mobile.length > 12
    ) {
      setMobileError("Please enter a valid mobile number ");
      isValid = false;
    } else {
      setMobileError("");
    }

    return isValid;
  };

  const next = () => {
    if (formNo === 1 && validateForm()) {
      setFormNo(formNo + 1);
    } else {
      toast.error("Please fill up all input fields correctly");
    }
  };

  const pre = () => {
    setFormNo(formNo - 1);
  };
  const baseUrl = "https://api.kbshomeservice.com";
  const navigate = useNavigate();

  const finalSubmit = async () => {
    if (
      state.name &&
      state.email &&
      state.message &&
      state.location &&
      state.service &&
      state.mobile
    ) {
      navigate("/responsepage");
      try {
        const response = await fetch(`${baseUrl}/email/sendEmail`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(state),
        });

        if (response.ok) {
          toast.success("Form submitted successfully");
        } else {
          toast.error("Failed to submit form");
        }
      } catch (error) {
        console.error("Error:", error);
       
      }
    } else {
      toast.error("Please fill up all input fields");
    }
  };

  const servicesData = [
    { imageLink: imageLink1, link: link1, name: name1 },
    { imageLink: imageLink2, link: link2, name: name2 },
    { imageLink: imageLink3, link: link3, name: name3 },
    { imageLink: imageLink4, link: link4, name: name4 },
    { imageLink: imageLink5, link: link5, name: name5 },
    { imageLink: imageLink6, link: link6, name: name6 },
    { imageLink: imageLink7, link: link7, name: name7 },
    { imageLink: imageLink8, link: link8, name: name8 },
    { imageLink: imageLink9, link: link9, name: name9 },
    { imageLink: imageLink10, link: link10, name: name10 },
  ];

  // let dataSend = {};

  const [selectedFlat, setSelectedFlat] = useState("1 BHK+");

  const flatOptions = ["1 BHK", "2 BHK", "3 BHK", "4 BHK+", "Bungalow"];

  const handleOptionChange = (e) => {
    const selectedOption = e.target.value;
    setSelectedFlat(selectedOption);
  };
  //   const [name, setName] = useState("");
  //   const [email, setEmail] = useState("");
  //   const [mobile, setMobile] = useState("");
  //   const [location, setLocation] = useState("");
  //   const [message, setMessage] = useState("");

  //   const [nameError, setNameError] = useState("");
  //   const [emailError, setEmailError] = useState("");
  //   const [mobileError, setMobileError] = useState("");
  //   const [formError, setFormError] = useState("");
  //   const [isFormValid, setIsFormValid] = useState(false);

  //   const validateForm = () => {
  //     let isValid = true;

  //     if (name.length < 3) {
  //       setNameError("Please enter atleast 3 character");
  //       isValid = false;
  //     } else {
  //       setNameError("");

  //     }

  //     if (!email.includes("@")
  //     || email.length < 5) {
  //       setEmailError("Please enter a valid email address.");
  //       isValid = false;
  //     } else {
  //       setEmailError("");
  //     }

  //    if (!/^\d+$/.test(mobile)) {
  //   setMobileError("Please enter a valid mobile number");
  //   isValid = false;
  // } else if (mobile.length < 10 || mobile.length > 13) {
  //   setMobileError("Please enter a valid mobile number");
  //   isValid = false;
  // } else {
  //   setMobileError("");
  // }

  //     if (!isValid) {
  //       setFormError("Please correct the errors in the form.");
  //     } else {
  //       setFormError("");
  //     }

  //     setIsFormValid(isValid);
  //     return isValid;
  //   };

  //   const baseUrl = "https://api.kbshomeservice.com";
  //   const sendEmail = async () => {

  //     if (validateForm()) {
  //       navigate('/responsepage')
  //      dataSend = {
  //         name: name,
  //         email: email,
  //         mobile: mobile,
  //         location: location,
  //         message: message,
  //         service: service,
  //         //selectedFlat: selectedFlat,
  //       };

  //       console.log("Email sent successfully!");
  //     } else {
  //       // Form validation failed
  //       console.log("Form validation failed. Please correct the errors.");
  //     }

  //     const res = await fetch(`${baseUrl}/email/sendEmail`, {
  //       method: "POST",
  //       body: JSON.stringify(dataSend),
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     // HANDLING ERRORS
  //     .then((res) => {
  //       console.log(res);
  //       if (res.status > 199 && res.status < 300) {
  //         alert("Send Successfully !");
  //       }
  //     });

  //     toast.success('Email sent successfully!');
  //     navigate('/responsepage');
  //   };
  const selectedCity = localStorage.getItem("city");

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <MetaData title={`${headingFirst} Services - KBS Home Services`}
      
       descriptionMeta={descriptionMeta}
      />
      <div>
        <div className="bg-cleaning w-full lg:h-[88vh] flex flex-col justify-start items-start relative "  >
        <div className="absolute top-0 right-0 bottom-0 left-0 bg-gradient-to-b from-black/50 to-transparent" style={{ zIndex: -1 }}></div>
      <div className="absolute top-0 right-0 bottom-0 left-0 bg-cover bg-center" style={{ backgroundImage: `url(${bgImageUrl})`, zIndex: -2 }}></div>
          <div className="flex  flex-col w-full  p-2 mt-[30px] lg:mt-0 pr-8 pl-8">
            {/* <div className="circlePosition hidden   w-[540px] h-[400px] bg-orange-400 rounded-[100%] absolute z-1 top-[20%] lg:top-[58%] left-[10%] translate-x-[-50%] translate-y-[-50%] blur-[150px]"></div> */}
            <div className="w-full flex flex-col items-center md:flex-row  ">
              <div className=" mt-5 xl:h-[510px] justify-center moto w-full flex flex-col text-white font-normal   rounded-3xl relative  pb-5 lg:p-[20px]  ">
                <div className="flex flex-row justify-between items-center w-full z-10">
                  <motion.h1
                    initial={{ y: "2rem", opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                      duration: 2,
                      type: "spring",
                      delay: 0.75,
                    }}
                    className="flex-1 font-poppins font-bold  ss:text-[65px] text-[25px] text-orange-400 ss:leading-[70.8px] leading-[45px] "
                  >
                    {headingFirst} Services
                    <br className="sm:block hidden" />{" "}
                    <span className="font-poppins text-white font-bold sm:text-[50px] text-[25px]">
                      {" "}
                      in {selectedCity}
                    </span>
                  </motion.h1>
                </div>
                <div className="hidden lg:flex">
                  <motion.p
                    initial={{ y: "2rem", opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                      duration: 2,
                      type: "spring",
                      delay: 1.05,
                    }}
                    className="font-poppins   font-normal sm:text-[18px] text-[16px] ss:leading-[30px] leading-[25px] text-white max-w-[670px] sm:mt-5 mt-3 text-justify"
                  >
                    Experience a transformative home with KBS Home Service your
                    go-to for impeccable deep cleaning. Call us now to revive
                    your living spaces.
                  </motion.p>
                </div>

                <div className="bg-secondary/20 w-[180px] hidden sm:block h-auto mt-6 rounded-[10px] pl-3 backdrop-blur  justify-center items-center">
                  <div className="w-[180px] items-center flex flex-row gap-3">
                    <div className="stars">
                      <ul className="flex flex-row gap-1 mt-2 ">
                        <li className="text-amber-400 text-lg ">
                          <img
                            loading="lazy"
                            width="20"
                            height="20"
                            src="https://img.icons8.com/fluency/20/star--v1.png"
                            alt="star--v1"
                          />
                        </li>
                        <li className="text-amber-400 text-lg">
                          <img
                            loading="lazy"
                            width="20"
                            height="20"
                            src="https://img.icons8.com/fluency/20/star--v1.png"
                            alt="star--v1"
                          />
                        </li>
                        <li className="text-amber-400 text-lg">
                          <img
                            loading="lazy"
                            width="20"
                            height="20"
                            src="https://img.icons8.com/fluency/20/star--v1.png"
                            alt="star--v1"
                          />
                        </li>
                        <li className="text-amber-400 text-lg">
                          <img
                            loading="lazy"
                            width="20"
                            height="20"
                            src="https://img.icons8.com/fluency/20/star--v1.png"
                            alt="star--v1"
                          />
                        </li>
                      </ul>
                    </div>

                    <div className="rating items-center pt-3 font-poppins text-amber-500 font-semibold sm:text-[22px]">
                      4.8
                    </div>
                  </div>
                  <p className="font-semibold text-[14px] font-poppins mb-2 mt-[-2px] text-white">
                    Based on 442 Reviews
                  </p>
                </div>

                <div className="keypoints relative w-[300px] sm:w-[400px] ">
                  <div className="check relative  grid grid-cols-2 gap-3 mt-6">
                    <div className="absolute sm:hidden left-1/2 top-0 h-full w-px bg-white"></div>
                    {/* Horizontal line */}
                    <div className="absolute sm:hidden left-0 top-1/2 w-full h-px bg-white"></div>

                    <div className=" text-[14px] w-full sm:text-[16px] flex flex-row gap-1 sm:gap-3 items-center text-white font-poppins">
                      <img
                        className="w-[15px] sm:w-[20px]"
                        src={check}
                        loading="lazy"
                        alt="check"
                      />
                      Top Quality Service
                    </div>
                    <div className="text-[14px] w-full  sm:text-[16px] flex flex-row gap-1 sm:gap-3 items-center text-white font-poppins">
                      <img
                        className="w-[15px] sm:w-[20px]"
                        src={check}
                        loading="lazy"
                        alt="check"
                      />
                      Skilled Experts
                    </div>
                    <div className="text-[14px] w-full  sm:text-[16px] flex flex-row gap-1 sm:gap-3 items-center text-white font-poppins">
                      <img
                        className="w-[15px] sm:w-[20px]"
                        src={check}
                        loading="lazy"
                        alt="check"
                      />
                      Best Price Match
                    </div>
                    <div className="text-[14px] w-full  sm:text-[16px] flex flex-row gap-1 sm:gap-3 items-center text-white font-poppins">
                      <img
                        className="w-[15px] sm:w-[20px]"
                        src={check}
                        loading="lazy"
                        alt="check"
                      />
                      Payment after Work
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative lg:mt-0 hidden ">
                <video
                  autoPlay
                  loop
                  muted
                  className="w-[1200px] h-full mt-4 object-cover rounded-2xl "
                >
                  <source src={HomeCleaning} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </div>

        {/*-------------Sub Services---------- */}

        <div className=" lowersection pb-10 w-full flex flex-col justify-center items-center lg:items-start md:px-5  lg:flex-row gap-5 ">
          <div className=" bg-slate-100 py-10 mb-8 mt-7 md:rounded-3xl w-full lg:w-2/3 gap-9  px-2 md:px-0    ml-0 relative">
            <div className="flex flex-col md:flex-row w-auto justify-center items-center  md:pl-5 sm:font-semibold font-bold  mb-[20px] font-poppins text-3xl ">
              Our Services
            </div>
            <div className="servicecards grid xl:grid-cols-4 grid-cols-3 ml-2 xs:ml-3 sm:ml-5 sm:grid-cols-3 justify-center items-center sm:gap-[30px] gap-[10px]   ">
              {servicesData.slice(0, numVisibleService).map(
                (service, index) =>
                  service.imageLink &&
                  service.name && (
                    <div className="link-wrapper lg:w-[200px] sm:w-[150px] w-[100px]">
                      <Link to={service.link} key={index} onClick={scrollToTop}>
                        <div className="flex flex-col bg-white/50 md:w-[200px] sm:w-[150px] w-[100px]  justify-center items-center cursor-pointer z-10  p-2 shadow-md rounded-lg md:mr-2 xl:mr-0  h-[100px] sm:h-[150px] lg:h-auto ">
                          <img
                            loading="lazy"
                            className="lg:w-[100px]  lg:h-[95px] sm:h-[60px] sm:w-[60px]  h-[40px] w-[40px] rounded-[100%]  lg:rounded-[20%]"
                            src={service.imageLink}
                            alt={service.name}
                          />
                          <div className="flex flex-col md:gap-2 justify-center items-center md:flex-row">
                          <p className="font-poppins sm:text-sm text-xs text-center mt-2">
                            {service.name}
                          </p>
                          <p className="font-poppins sm:text-sm text-xs text-center md:mt-2 ">
                            {headingThird}
                          </p>
                          </div>

                          <div className="overlay rounded-lg"></div>
                        </div>
                      </Link>
                    </div>
                  )
              )}
            </div>
          </div>

          {/*---------------Request Form------------ */}
          <div className="sm:mt-8   rounded-xl bg-gradient-to-br   text-white from-secondary to-black via-secondary mx-[30px] sm:mx-[15%] md:mx-0 relative shadow-xl  w-[300px] xs:w-[350px] md:w-[400px] xl:w-[450px] lg:ml-8 sm:w-[550px] px-[20px] justify-center items-center  pt-5 ">
            <ToastContainer />
            <div className="card font-poppins flex flex-col items-center justify-center  gap-3  px-2 py-5">
              <div className="font-semibold font-poppins w-full text-lg  text-center ">
                Fill the form for free callback
              </div>
              <div className="flex justify-center items-center">
                {formArray.map((v, i) => (
                  <>
                    <div
                      className={`w-[35px] my-3 text-white rounded-full ${
                        formNo - 1 === i ||
                        formNo - 1 === i + 1 ||
                        formNo === formArray.length
                          ? "bg-orange-600"
                          : "bg-slate-400"
                      } h-[35px] flex justify-center items-center`}
                    >
                      {v}
                    </div>
                    {i !== formArray.length - 1 && (
                      <div
                        className={`w-[85px] h-[2px] ${
                          formNo === i + 2 || formNo === formArray.length
                            ? "bg-orange-600"
                            : "bg-slate-400"
                        }`}
                      ></div>
                    )}
                  </>
                ))}
              </div>
              {formNo === 1 && (
                <div className=" flex flex-col gap-3">
                  <div className="flex flex-col  mb-2">
                    <input
                      value={state.name}
                      onChange={inputHandle}
                      className="bg-gray-100 h-5 py-5  rounded-md xl:w-[400px] sm:w-[370px]  xs:w-[320px]  w-[250px]  shadow-slate-300 shadow-inner px-2 text-black"
                      type="text"
                      name="name"
                      placeholder="Name"
                      id="name"
                    />
                    {nameError && (
                      <p className="text-white text-xs mt-1">{nameError}</p>
                    )}
                  </div>
                  <div className="flex flex-col mb-2">
                    <input
                      value={state.email}
                      onChange={inputHandle}
                      className="bg-gray-100 h-5 py-5  rounded-md xl:w-[400px] sm:w-[370px]  xs:w-[320px]  w-[250px]  shadow-slate-300 shadow-inner px-2 text-black"
                      type="email"
                      name="email"
                      placeholder="Email"
                      id="email"
                    />
                    {emailError && (
                      <p className="text-white text-xs mt-1">{emailError}</p>
                    )}
                  </div>
                  <div className="flex flex-col mb-2">
                    <input
                      value={state.mobile}
                      onChange={inputHandle}
                      className="bg-gray-100 h-5 py-5  rounded-md xl:w-[400px] sm:w-[370px]  xs:w-[320px]  w-[250px]  shadow-slate-300 shadow-inner px-2 text-black"
                      type="tel"
                      name="mobile"
                      placeholder="Mobile Number"
                    />
                    {mobileError && (
                      <p className="text-white text-xs mt-1">{mobileError}</p>
                    )}
                  </div>
                  <div className="mt-4 flex justify-center items-center">
                    <button
                      onClick={next}
                      className="px-3 py-2 text-lg rounded-md w-full text-white bg-orange-600"
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}

              {formNo === 2 && (
                <div>
                  <div className="flex flex-col my-2">
                    <input
                      value={state.location}
                      onChange={inputHandle}
                      className="bg-gray-100 h-5 py-5  rounded-md xl:w-[400px] sm:w-[370px]  xs:w-[320px]  w-[250px]  shadow-slate-300 shadow-inner px-2 text-black"
                      type="text"
                      name="location"
                      placeholder="Location"
                      id="location"
                    />
                  </div>
                  <div className="flex flex-row justify-center items-center relative">
                    <select
                      className="block appearance-none font-poppins rounded-md text-[16px] bg-gray-100 shadow-slate-300 shadow-inner xl:w-[400px] sm:w-[370px] xs:w-[320px] w-[250px] px-3 h-10 py-2 text-black leading-tight focus:outline-none focus:ring"
                      placeholder={`${serviceType[0]} Services `}
                      name="service"
                      value={service}
                      onChange={handleServiceChange}
                      required
                    >
                      {serviceType.map((service, index) => (
                        <option
                          className="py-2 font-poppins"
                          key={index}
                          value={service}
                        >
                          {service}
                        </option>
                      ))}
                    </select>

                    <div className="pointer-events-none absolute inset-y-0 right-4 flex items-center px-1 text-black">
                      <FaAngleDown />
                    </div>
                  </div>
                  <div className="flex flex-col my-2">
                    <textarea
                      value={state.message}
                      onChange={inputHandle}
                      row={5}
                      className="bg-gray-100 pb-10 pt-2 rounded-md   xl:w-[400px] sm:w-[370px]  xs:w-[320px]   w-[250px] shadow-slate-300	 shadow-inner px-2 text-black"
                      type="text"
                      name="message"
                      placeholder="Enter your Message "
                    ></textarea>
                  </div>
                  <div className="mt-4 gap-3 flex justify-center items-center">
                    <button
                      onClick={pre}
                      className="px-3 py-2 text-lg rounded-md w-full text-white bg-orange-600"
                    >
                      Previous
                    </button>
                    <button
                      onClick={finalSubmit}
                      className="px-3 py-2 text-lg rounded-md w-full text-white bg-orange-600"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Faq />
        <Benefits />
        <TestimonialCarousel />
        <Footer />
      </div>
    </>
  );
};

export default AllServicePage;
