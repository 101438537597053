import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { banner7 } from "../assets";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../index.css";
const AutoSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const images = [banner7];

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentSlide,
    autoplay: false,
    autoplaySpeed: 2000,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full ">
      <Slider {...settings} arrows={false} className="relative">
        {images.map((image, index) => (
          <div
            key={index}
            className="outline-none focus:outline-none justify-center "
          >
            <img
              src={image}
              loading="lazy"
              alt={`Slide ${index + 1}`}
              className="w-full  "
            />
          </div>
        ))}
      </Slider>
      <style>
        {`
          .slick-dots {
            position: absolute;
            bottom: 10px; /* Adjust the distance from the bottom as needed */
            left: 0;
            right: 0;
            margin-bottom: 0; /* Adjust the margin-bottom as needed */
          }

          .slick-dots li {
            margin: 0 2px; /* Adjust the horizontal spacing between dots as needed */
          }
        `}
      </style>
    </div>
  );
};

export default AutoSlider;
