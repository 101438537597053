import React from 'react'

import {  termite } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';

import PestControlModal from './PestControlModal';
import FaqServices from '../../Components/FaqServices';
import Description from '../../Components/Description';

const TermiteControl = () => {

  
  const data = {
    headingFirst: "Termite Pest",
    headingSecond: "Termite Pest",
    price: "₹2599",
    description: [
      "Comprehensive inspection for termite infestations",
  "Application of specialized termite control treatments",
  "Treatment of wooden structures and vulnerable areas",
  "Soil treatment to create a protective barrier against termites",
  "Identification and elimination of termite nests",
  "Preventive measures to discourage future termite activity",
  "Monitoring and follow-up inspections for long-term effectiveness",
  "Use of environmentally friendly and safe termite control products",
  "Protection of furniture, wooden fixtures, and valuables from termite damage",
  "Expert advice on minimizing conditions conducive to termite infestation",
  "Transparent reporting and documentation of termite control procedures",

    ],
    image: termite,
    space: "living space",
    size: "house",
    descriptionMeta: "Preserve the integrity of your property with our specialized Termite Pest Control Service. Our treatments will eradicate termites and prevent costly damage to your home or business. Don't let termites destroy your investment—schedule your service now!",
    
  
  }
  const termiteControlFAQ = [
    {
      question: 'How do I know if my property has a termite infestation?',
      answer: 'Common signs of termite infestation include mud tubes, damaged wood with a hollow sound, discarded wings, and presence of termite droppings. If you suspect an infestation, it is recommended to schedule a termite inspection.',
    },
    {
      question: 'What methods do you use for termite control?',
      answer: 'We employ various termite control methods, including liquid termite treatments, bait systems, and barrier treatments. The most suitable method depends on the severity and type of termite infestation.',
    },
    {
      question: 'Is termite control safe for my family and pets?',
      answer: 'Yes, our termite control methods are designed to be safe for humans and pets. We use environmentally friendly and low-toxicity solutions to ensure the safety of your home.',
    },
    {
      question: 'How often should I schedule termite inspections?',
      answer: 'Regular termite inspections are crucial for early detection. It is recommended to schedule termite inspections annually or more frequently in areas prone to termite activity.',
    },
    {
      question: 'Do you offer warranty or guarantee for termite control services?',
      answer: 'Yes, we provide warranties or guarantees for our termite control services. The terms may vary, and our team will provide you with detailed information during the consultation.',
    },
    {
      question: 'What are the main types of termites found in homes?',
      answer: 'The main types of termites found in homes are subterranean termites, drywood termites, and dampwood termites. Each species has different behaviors and preferences for wood moisture levels.',
    },
    {
      question: 'Can I prevent termite infestations with DIY methods?',
      answer: 'While DIY methods like maintaining proper drainage, reducing wood-to-soil contact, and regular inspection can help prevent termite infestations, professional treatment and monitoring are often necessary for effective control.',
    },
    {
      question: 'How do you determine the extent of a termite infestation?',
      answer: 'Our technicians use a combination of visual inspections, monitoring tools, and specialized equipment such as moisture meters and termite detection devices to assess the extent of a termite infestation.',
    },
    {
      question: 'What are the consequences of untreated termite infestations?',
      answer: 'Untreated termite infestations can lead to severe structural damage to your property, compromising its stability and value. Early detection and prompt treatment are essential to prevent extensive damage.',
    },
    {
      question: 'Can termites cause damage to furniture and other wooden items?',
      answer: 'Yes, termites can infest and damage not only the structural wood of buildings but also wooden furniture, fixtures, and other items made of cellulose materials.',
    },
  ];
  const termitePestControlDescriptionData = {
    headingDescription_1: "Why Termite Pest Control Service is Essential",
    paraDescription_1: "Termites are silent destroyers that can cause extensive damage to wooden structures and furniture, leading to costly repairs and structural instability. These pests feed on cellulose-based materials, such as wood and paper, and can go undetected for long periods, allowing them to cause significant damage before being noticed. Neglecting proper pest control measures can result in severe structural damage and compromise the safety and integrity of your property. Professional termite pest control service plays a crucial role in identifying termite infestations, implementing targeted treatment strategies, and preventing future damage. Investing in termite pest control is essential for protecting your investment and ensuring the long-term stability and value of your property.",
    headingDescription_2: "What We Do in Termite Pest Control Service",
    paraDescription_2: [
        "Comprehensive inspection of the property to assess termite activity, extent of damage, and conducive conditions.",
        "Identification of termite species and nesting sites to determine the most appropriate treatment approach.",
        "Application of liquid termiticides or baiting systems to eliminate termite colonies and prevent further infestations.",
        "Treatment of soil around the perimeter of the property to create a protective barrier against termites.",
        "Installation of termite monitoring stations to detect early signs of termite activity and provide ongoing protection.",
        "Repair and replacement of damaged wood and structural components affected by termite infestations.",
        "Education and recommendations on preventive measures to minimize the risk of future termite infestations.",
        "Regular inspections and maintenance plans to ensure long-term protection against termites.",
        "Environmentally friendly treatment options available to minimize impact on the ecosystem."
    ]
};

  
  return (
    <div className='main'>
        <PestControlModal {...data} />
        <FaqServices faqData={termiteControlFAQ} />
        <Description {...termitePestControlDescriptionData} />
        <Benefits />
        <TestimonialCarousel />
        <Footer />
    </div>
  )
}

export default TermiteControl
