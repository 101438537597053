import React from "react";
import { Routes, Route, useLocation, useParams } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Faq from "./pages/FAQ";
import Services from "./pages/Services";
import Benefits from "./pages/Benefits";
// import Footer from "./Components/Footer";
// import { Container } from "react-bootstrap";
import Hero from "./pages/Hero";
import styles from "./style";
import "./index.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import WhyUs from "./pages/WhyUs";
import ServiceCard from "./Components/ServiceCard";
import TestimonialCarousel from "./pages/Testimonials";
import Deephousecleaning from "./pages/Cleaning Services/Deephousecleaning";
import OfficeCleaning from "./pages/Cleaning Services/OfficeCleaning";
import VillaCleaning from "./pages/Cleaning Services/VillaCleaning";

import VacantCleaning from "./pages/Cleaning Services/VacantCleaning";
import BathroomCleaning from "./pages/Cleaning Services/BathroomCleaning";
import KitchenCleaning from "./pages/Cleaning Services/KitchenCleaning";
import CommercialCleaning from "./pages/Cleaning Services/CommercialCleaning";
import SofaCleaning from "./pages/Cleaning Services/SofaCleaning";
import CarpetCleaning from "./pages/Cleaning Services/CarpetCleaning";
import BedbugControl from "./pages/PestControl Services/BedbugControl";
import AntPestControl from "./pages/PestControl Services/AntPestControl";
import TermiteControl from "./pages/PestControl Services/TermiteControl";
import CockroachControl from "./pages/PestControl Services/CockroachControl";
import MosquitoControl from "./pages/PestControl Services/MosquitoControl";
import RodentControl from "./pages/PestControl Services/RodentControl";
import Woodboarer from "./pages/PestControl Services/Woodboarer";
import GeneralPestControl from "./pages/PestControl Services/GeneralPestControl";
import ExteriorPaint from "./pages/Paint Service/ExteriorPaint";
import InteriorPaint from "./pages/Paint Service/InteriorPaint";
import VacantWallPainting from "./pages/Paint Service/VacantWallPainting";
import WaterTankCleaning from "./pages/Watertank Services/WaterTankCleaning";
import SumpCleaning from "./pages/Watertank Services/SumpCleaning";
import TerraceProofing from "./pages/Waterproofing Services/TerraceProofing";
import SumpProofing from "./pages/Waterproofing Services/SumpProofing";
import HomeSanitization from "./pages/Disinfection Services/HomeSanitization";
import CommercialSanitization from "./pages/Disinfection Services/CommercialSanitization";
import VehicleSanitization from "./pages/Disinfection Services/VehichleSanitization";
import BabyShower from "./pages/Decoration Services/BabyShower";
import Bachellorite from "./pages/Decoration Services/Bachellorite";
import CorporateDecoration from "./pages/Decoration Services/Corporate";
import Festival from "./pages/Decoration Services/Festival";
import FirstNight from "./pages/Decoration Services/FirstNight";
import FlowerDecoration from "./pages/Decoration Services/FlowerDecoration";
import CleaningService from "./pages/Cleaning Services/CleaningService";
import PestControlService from "./pages/PestControl Services/PestControlService";
import PaintService from "./pages/Paint Service/PaintService";
import Decoration from "./pages/Decoration Services/Decoration";
import Disinfection from "./pages/Disinfection Services/Disinfection";
import Waterproofing from "./pages/Waterproofing Services/Waterproofing";
import WaterTank from "./pages/Watertank Services/Watertank";
import CCTV from "./pages/CCTV";
// import Sharebutton from "./Components/Sharebutton";
import CarWashing from "./pages/Cleaning Services/CarWashing";
import CarPolish from "./pages/Cleaning Services/CarPolish";
import PressureCar from "./pages/Cleaning Services/PressureCar";
import InteriorCar from "./pages/Cleaning Services/InteriorCar";
import Partner from "./pages/Partner";
import AboutUs from "./pages/AboutUs";
import ContactPage from "./pages/ContactUs";
import PackersandMovers from "./pages/PackersandMovers";
import Blog from "./pages/Blog";
import CareerPage from "./pages/CareerPage";
import ResponsePage from "./pages/ResponsePage";
import InteriorDesign from "./pages/InteriorDesign";


const App = () => {
  const location = useLocation();
  const {city} = useParams();
  // const selectedCity = city || localStorage.getItem('city') || ''; // Set a default value if null
  const selectedCity = localStorage.getItem("city");
  const citySlug = selectedCity ? selectedCity.toLowerCase() : '';

  //deep Home Cleaning
  const redirectUrl_cleaning = `/cleaning-services-in-${citySlug}`
  const redirectUrl_deep = `/deep-home-cleaning-in-${citySlug}`
  const redirectUrl_office = `/office-cleaning-in-${citySlug}`
  const redirectUrl_villa = `/villa-cleaning-in-${citySlug}`
  const redirectUrl_vacant = `/vacant-cleaning-in-${citySlug}`
  const redirectUrl_bathroom = `/bathroom-cleaning-in-${citySlug}`
  const redirectUrl_kitchen = `/kitchen-cleaning-in-${citySlug}`
  const redirectUrl_commercial = `/commercial-cleaning-in-${citySlug}`
  const redirectUrl_sofa = `/sofa-cleaning-in-${citySlug}`
  const redirectUrl_carpet = `/carpet-cleaning-in-${citySlug}`
  const redirectUrl_carwash = `/car-washing-in-${citySlug}`
  const redirectUrl_interiorcar = `/interior-car-service-in-${citySlug}`
  const redirectUrl_pressurecar = `/pressure-car-service-in-${citySlug}`
  const redirectUrl_carpolish = `/car-polish-service-in-${citySlug}`
  

  //Pest control
  const redirectUrl_pest = `/pest-control-services-in-${citySlug}`
  const redirectUrl_bedbug = `/bedbug-control-in-${citySlug}`
  const redirectUrl_ant = `/ant-pest-control-in-${citySlug}`
  const redirectUrl_termite = `/termite-pest-control-in-${citySlug}`
  const redirectUrl_cockroach = `/cockroach-pest-control-in-${citySlug}`
  const redirectUrl_mosquito = `/mosquito-pest-control-in-${citySlug}`
  const redirectUrl_rodent = `/rodent-pest-control-in-${citySlug}`
  const redirectUrl_woodboarer = `/wood-boarer-pest-control-in-${citySlug}`
  const redirectUrl_generalpest = `/general-pest-control-in-${citySlug}`

  //paint service
  const redirectUrl_paint = `/paint-service-in-${citySlug}`
  const redirectUrl_exterior = `/exterior-paint-service-in-${citySlug}`
  const redirectUrl_interior = `/interior-paint-service-in-${citySlug}`
  const redirectUrl_vacantwall = `/vacant-wall-painting-in-${citySlug}`

  //Watertank Services
  const redirectUrl_watertank = `/water-tank-and-sump-cleaning-in-${citySlug}`
  const redirectUrl_watercleaning = `/water-tank-cleaning-in-${citySlug}`
  const redirectUrl_sump = `/sump-cleaning-in-${citySlug}`

  //WaterProofing
  const redirectUrl_waterproofing = `/water-proofing-service-in-${citySlug}`
  const redirectUrl_terraceproofing = `/terrace-proofing-service-in-${citySlug}`
  const redirectUrl_sumpproofing = `/sump-proofing-service-in-${citySlug}`

  //Disinifection
  const redirectUrl_disinfect = `/disinfection-service-in-${citySlug}`
  const redirectUrl_home = `/home-sanitization-in-${citySlug}`
  const redirectUrl_commercialsani = `/commercial-sanitization-in-${citySlug}`
  const redirectUrl_vehicle = `/vehicle-sanitization-in-${citySlug}`

  //Decoration

  const redirectUrl_decoration = `/decoration-service-in-${citySlug}`
  const redirectUrl_babyshower = `/babyshower-service-in-${citySlug}`
  const redirectUrl_bachellorite = `/bachellorite-service-in-${citySlug}`
  const redirectUrl_corporate = `/corporate-service-in-${citySlug}`
  const redirectUrl_festival = `/festival-service-in-${citySlug}`
  const redirectUrl_weddingnight = `/weddingnight-service-in-${citySlug}`
  const redirectUrl_flower = `/flower-service-in-${citySlug}`

  //cctv
  const redirectUrl_cctv = `/cctv-service-in-${citySlug}`

  //packers and movers 

  const redirectUrl_packers = `/packers-and-movers-service-in-${citySlug}`


  // Interior Design
  

  const redirectUrl_interiodesign = `/interior-designing-service-in-${citySlug}`
  return (
    <>
      <div className={`flex fixed top-0 bg-white md:bg-opacity-0 right-0 left-0 justify-start md:mb-0 sm:mb-[50px] items-center z-50  ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth} `}>
          <Navbar />
        </div>
      </div>
    {/*}  <Sharebutton /> */}
      <div className={` ${styles.flexStart} mt-[50px] md:mt-[90px]`}>
        <div className={`${styles.boxWidth}`}>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Hero />} />
            <Route path="/servicecard" element={<ServiceCard />} />
            <Route path="/:city" element={<Hero />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/services" element={<Services />} />
            <Route path="/whyus" element={<WhyUs />} />
            <Route path="/benefits" element={<Benefits />} />
            <Route path="/testimonials" element={<TestimonialCarousel />} />
            {/* *--------Cleaning services routing*/}

            <Route path={redirectUrl_cleaning} element={<CleaningService />} />
            <Route path={redirectUrl_deep} element={<Deephousecleaning />} />
            <Route path={redirectUrl_office} element={<OfficeCleaning />} />
            <Route path={redirectUrl_villa} element={<VillaCleaning />} />
            <Route path={redirectUrl_vacant} element={<VacantCleaning />} />
            <Route path={redirectUrl_bathroom} element={<BathroomCleaning />} />
            <Route path={redirectUrl_kitchen} element={<KitchenCleaning />} />
            <Route path={redirectUrl_commercial} element={<CommercialCleaning />} />
            <Route path={redirectUrl_sofa} element={<SofaCleaning />} />
            <Route path={redirectUrl_carpet} element={<CarpetCleaning />} />
            <Route path={redirectUrl_carwash} element={<CarWashing />} />
            <Route path={redirectUrl_carpolish} element={<CarPolish />} />
            <Route path={redirectUrl_interiorcar} element={<InteriorCar />} />
            <Route path={redirectUrl_pressurecar} element={<PressureCar />} />


            {/* *--------Pest Control services routing*/}
            <Route path={redirectUrl_pest} element={<PestControlService />} />
            <Route path={redirectUrl_bedbug} element={<BedbugControl />} />
            <Route path={redirectUrl_ant} element={<AntPestControl />} />
            <Route path={redirectUrl_termite} element={<TermiteControl />} />
            <Route path={redirectUrl_cockroach} element={<CockroachControl />} />
            <Route path={redirectUrl_mosquito} element={<MosquitoControl />} />
            <Route path={redirectUrl_rodent} element={<RodentControl />} />
            <Route path={redirectUrl_woodboarer} element={<Woodboarer />} />
            <Route path={redirectUrl_generalpest} element={<GeneralPestControl />}
            />

            {/*-----------Paint service routing */}
            <Route path={redirectUrl_paint} element={<PaintService />} />
            <Route path={redirectUrl_exterior} element={<ExteriorPaint />} />
            <Route path={redirectUrl_interior} element={<InteriorPaint />} />
            <Route path={redirectUrl_vacantwall} element={<VacantWallPainting />} />

            {/* Watertank Services */}
            <Route path={redirectUrl_watertank} element={<WaterTank />} />
            <Route path={redirectUrl_watercleaning} element={<WaterTankCleaning />} />
            <Route path={redirectUrl_sump} element={<SumpCleaning />} />

            {/*----------WaterProofing---------- */}
            <Route path={redirectUrl_waterproofing} element={<Waterproofing />} />
            <Route path={redirectUrl_terraceproofing} element={<TerraceProofing />} />
            <Route path={redirectUrl_sumpproofing} element={<SumpProofing />} />

            {/*------Disinifection--------- */}
            <Route path={redirectUrl_disinfect} element={<Disinfection />} />
            <Route path={redirectUrl_home} element={<HomeSanitization />} />
            <Route path={redirectUrl_commercialsani} element={<CommercialSanitization />}/>
            <Route path={redirectUrl_vehicle} element={<VehicleSanitization />} />

            {/*------Decoration------ */}
            <Route path={redirectUrl_decoration} element={<Decoration />} />
            <Route path={redirectUrl_babyshower} element={<BabyShower />} />
            <Route path={redirectUrl_bachellorite} element={<Bachellorite />} />
            <Route path={redirectUrl_corporate} element={<CorporateDecoration />} />
            <Route path={redirectUrl_festival} element={<Festival />} />
            <Route path={redirectUrl_weddingnight} element={<FirstNight />} />
            <Route path={redirectUrl_flower} element={<FlowerDecoration />} />

            {/*----cctv-------- */}
            <Route path={redirectUrl_cctv} element={<CCTV />} />

            <Route path={redirectUrl_packers} element={<PackersandMovers />} />
            <Route path={redirectUrl_interiodesign} element={<InteriorDesign />} />
            


            


            <Route path="/vendor-form" element={<Partner />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/contactus" element={<ContactPage />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/career" element={<CareerPage />} />
            <Route path="/responsepage" element={<ResponsePage />} />
          </Routes>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default App;
