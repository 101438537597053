import React  from "react";

import {
 
  commercialsanitize,
  homesanitize,
  vehiclesanitize,
} from "../../assets";

import AllServicePage from "../AllServicePage";

const Disinfection = () => {
  const selectedCity = localStorage.getItem('city')
  const data = {
    headingFirst: " Disinfection",
    headingSecond: "Sanitization Services",
    headingThird: "Sanitization",
    description:
      "Transform your environment with KBS Home Service the unrivaled solution for ensuring a pristine and germ-free space. Contact us now to experience a renewed and hygienic living atmosphere",
    rating: 4.8,
    reviews: "Based on 552 Reviews",
    imageLink1: commercialsanitize,
    link1: `/commercial-sanitization-in-${selectedCity.toLowerCase()}`,
    name1: "Commercial ",
    imageLink2: homesanitize,
    link2: `/home-sanitization-in-${selectedCity.toLowerCase()}`,
    name2: "Home ",
    imageLink3: vehiclesanitize,
    link3: `/vehicle-sanitization-in-${selectedCity.toLowerCase()}`,
    name3: "Vehicle",
    descriptionMeta: "Ensure safety and peace of mind with our thorough Disinfection and Sanitization Service. Using industry-approved methods and solutions, we'll eliminate harmful pathogens and viruses from your space. Protect your health and well-being—schedule your sanitization service today!",
    bgImageUrl: "https://www.fikes.com/wp-content/uploads/2021/05/Fogging-Disinfection-in-entryway.jpg",
    serviceType: ["Commercial sanitization" , "Home sanitization", "Vehicle sanitization"]
  };
  

  return (
    <div className="">
    <AllServicePage {...data} />
  </div>
  );
};

export default Disinfection;
