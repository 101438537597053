import React from 'react'

import { 
  babyshower,
  bachelorite,
  firstnight,
  corporate,
  flower,
  festival,
 
 } from '../../assets';

import AllServicePage from "../AllServicePage";


const Decoration = () => {
  const selectedCity = localStorage.getItem('city')
  const data = {
    headingFirst: " Decoration" ,
    headingSecond: "Services",
    headingThird: "Party",
    description: "Transform your environment with KBS Home Service the unrivaled solution for ensuring a pristine and germ-free space. Contact us now to experience a renewed and hygienic living atmosphere ",
    rating: 4.9,
    reviews: "Based on 352 Reviews",
    imageLink1: babyshower,
    link1: `/babyshower-service-in-${selectedCity.toLowerCase()}`,
    name1: "Baby Shower ",
    imageLink2: bachelorite,
    link2: `/bachellorite-service-in-${selectedCity.toLowerCase()}`,
    name2: "Bachellorite ",
    imageLink3: firstnight,
    link3: `/weddingnight-service-in-${selectedCity.toLowerCase()}`,
    name3: "Wedding Night",
    imageLink4: corporate,
    link4: `/corporate-service-in-${selectedCity.toLowerCase()}`,
    name4: "Corporate ",
    imageLink5: flower,
    link5: `/flower-service-in-${selectedCity.toLowerCase()}`,
    name5: "Flower ",
    imageLink6: festival,
    link6: `/festival-service-in-${selectedCity.toLowerCase()}`,
    name6: "Festival",
    descriptionMeta: "Transform any event into an unforgettable experience with our versatile Decoration Service. Whether it's a wedding, birthday, or corporate gathering, we'll tailor our decor to suit your style and theme. Elevate your event with our expert touch—schedule your decoration service now!",
    bgImageUrl: "https://content3.jdmagicbox.com/comp/thiruvananthapuram/60/0471p471std4100360/catalogue/s-v-hire-services-and-decoration-vellayambalam-thiruvananthapuram-event-management-companies-for-wedding-4du2z8cy58.jpg",
    serviceType: ["Babyshower party decoration", "Bachellorite party decoration", "First night decoration","Corporate party decoration", "Festival party decoration","Flower decoration"]
  }
  
  return (
    <div className="">
      <AllServicePage {...data} />
    </div>
  )
}

export default Decoration
