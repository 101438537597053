import React from 'react'
import { Link } from 'react-router-dom'
import MetaData from '../Metadata'

const ResponsePage = () => {
  return (
    <><MetaData title="Response page - KBS Home Services" />
    <div className='flex flex-col justify-center items-center px-4 mt-7'>
      <h1 className='text-4xl mt-4  font-poppins font-bold'>Thank You for your response !</h1>
      <p className='text-2xl text-gray font-poppins mt-4'>Our team will contact  you very shortly. Have a good day 🙂</p>
      <p className='mt-7'><Link className='text-blue-500' to='/'>Click Here</Link> to redirect to home page</p>


    </div></>
  )
}

export default ResponsePage
