import React from 'react'

import {  firstnight } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';

import DecorationModal from './DecorationModal';

import FaqServices from '../../Components/FaqServices';
import Description from '../../Components/Description';

const FirstNight = () => {

  const data = {
    headingFirst: "Wedding Night",
    headingSecond: "Wedding Night",
    price: "₹2999",
    description: [
      "Customized decoration for the Wedding Night",
      "Arrangement of romantic and thematic elements",
      "Use of premium quality decor items",
      "Bedroom embellishment with flowers and candles",
      "Table setting with elegant dinnerware",
      "Inclusion of soft lighting and ambiance enhancement",
      "Personalized touch based on preferences",
      "Creation of a memorable and intimate atmosphere",
      "Special attention to details for a unique experience",
      "Professional setup and arrangement services",
      "Coordination with clients for specific requests",
      "Fridge, microwave, and chimney exterior cleaning",
    ],
    image: firstnight,
    space: "occasion space",
    size: "place",
    descriptionMeta: "Create an enchanting ambiance for your wedding night with our romantic Wedding Night Decoration Service. From candlelit pathways to dreamy backdrops, we'll set the stage for an unforgettable evening of love and celebration. Let us make your wedding night magical—book your decor now!",
    
  
  }
  const firstNightDecorationFAQ = [
    {
      question: 'What is included in the Wedding Night Decoration service?',
      answer: 'Our Wedding Night Decoration service includes the arrangement and decoration of the chosen venue or room with beautiful and thematic decor. This may include lighting, flowers, candles, and personalized touches to create a romantic atmosphere.',
    },
    {
      question: 'Can I customize the decorations based on my preferences?',
      answer: 'Yes, we offer customization options to tailor the decorations according to your preferences and theme. You can discuss your ideas and specific requirements with our decoration team.',
    },
    {
      question: 'How far in advance should I book the Wedding Night Decoration service?',
      answer: 'To ensure availability and proper planning, it is recommended to book the Wedding Night Decoration service at least a few weeks in advance. However, last-minute bookings may also be accommodated based on availability.',
    },
    {
      question: 'Is there a specific duration for the decoration setup?',
      answer: 'The duration of the decoration setup depends on the complexity and scale of the chosen decoration package. Our team will provide you with an estimated setup time during the booking process.',
    },
    {
      question: 'Do you provide decoration services for both indoor and outdoor venues?',
      answer: 'Yes, our Wedding Night Decoration services are available for both indoor and outdoor venues. We will work with you to transform the chosen space into a romantic and enchanting setting for your special night.',
    },
    
      {
        question: "Theme Preferences for Wedding Night Decor?",
        answer:
          "Absolutely! We welcome your ideas and can accommodate specific themes or concepts to make your Wedding Night truly special. Whether you envision a classic romantic setup or a unique theme, we'll bring your vision to life.",
        isOpen: false,
      },
      {
        question: "Setup & Takedown for Wedding Night Decor?",
        answer:
          "Yes, our team handles both the setup and takedown of Wedding Night decorations. We ensure that the decorations are installed and removed efficiently, allowing you to focus on enjoying your special evening.",
        isOpen: false,
      },
      {
        question: "Venue Restrictions for Wedding Night Decor?",
        answer:
          "We are flexible with venue locations and can provide Wedding Night Decoration services for various venues, including hotels, resorts, private residences, and outdoor locations. However, additional charges may apply for distant or remote locations.",
        isOpen: false,
      },
      {
        question: "Custom Add-ons for Wedding Night Decor?",
        answer:
          "Yes, you can request specific decorative elements or add-ons to personalize your Wedding Night Decoration. Whether it's special lighting effects, floral arrangements, or personalized signage, we'll work with you to create a memorable setup.",
        isOpen: false,
      },
      {
        question: "Making Changes to Wedding Night Decor Booking?",
        answer:
          "If you need to make changes or adjustments to your Wedding Night Decoration booking, simply contact our customer service team. We'll be happy to accommodate your requests and ensure that everything is tailored to your preferences.",
        isOpen: false,
      },
  ];

  const descriptionData = {
    headingDescription_1: "Why Wedding Night Party Decoration Service Sets the Tone for Your Celebration",
    paraDescription_1: "The ambiance of your Wedding Night celebration sets the tone for the entire event, creating lasting memories for you and your guests. From romantic lighting to elegant decor, every detail plays a crucial role in enhancing the atmosphere and making your special occasion truly unforgettable. Professional Wedding Night party decoration service ensures that every aspect of your venue is meticulously designed and executed to reflect your unique style and vision. Whether you're planning an intimate gathering or a grand affair, our expert decorators work closely with you to bring your dream celebration to life, creating a magical backdrop for your love story.",
    headingDescription_2: "What We Do in Wedding Night Party Decoration Service",
    paraDescription_2: [
        "Customized theme selection tailored to your preferences and event theme.",
        "Design and installation of stunning floral arrangements and centerpieces.",
        "Creation of enchanting lighting effects, including fairy lights, candles, and lanterns.",
        "Setup of elegant table settings, linens, and chair covers for a sophisticated dining experience.",
        "Incorporation of personalized signage, backdrops, and photo booths for memorable photo opportunities.",
        "Placement of romantic props, such as rose petals, balloons, and love letters, to enhance the romantic ambiance.",
        "Coordination of music, entertainment, and audiovisual elements to complement the decor and set the mood.",
        "On-site supervision and coordination to ensure seamless execution and timely setup.",
        "Cleanup and removal of decorations after the event, allowing you to enjoy your special night without worry.",
        "Optional add-on services available, including flower bouquet arrangements, custom signage, and themed props."
    ]
}

  
  
  
  return (
    <div className='main'>
      <DecorationModal {...data} />
      <FaqServices faqData={firstNightDecorationFAQ} />
      <Description {...descriptionData} />
      <Benefits />
      <TestimonialCarousel />
      <Footer />
    </div>
  )
}

export default FirstNight
