import React from 'react'

import { bachelorite } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';

import DecorationModal from './DecorationModal';

import FaqServices from '../../Components/FaqServices';
import Description from '../../Components/Description';

const Bachellorite = () => {

  const data = {
    headingFirst: "Bachelorite Party",
    headingSecond: "Bachelorite Party",
    price: "₹4999",
    description: [
      "Personalized bachelorite party decoration service",
      "Theme-based decoration to suit the occasion",
      "Decor setup for common bachelorite party areas",
      "Arrangement of banners, balloons, and streamers",
      "Creative table centerpieces and party favors",
      "Innovative photo booth setup for memorable pictures",
      "Lighting arrangements for a vibrant atmosphere",
      "Exclusive lounge area decoration",
      "Customized signage and welcome boards",
      "Special attention to detail for a festive ambiance",
      "Optional add-ons for additional entertainment elements",
    ],
    
    image: bachelorite,
    space: "ocassional spot",
    size: "compound",
    descriptionMeta: "Celebrate your last night of freedom with our exciting Bachelor(ette) Decoration Service. From fun and festive to classy and chic, we'll design a decor that sets the perfect tone for your pre-wedding festivities. Let's make your bachelor(ette) party unforgettable—contact us for custom decor solutions!",
    
  
  }
  const bachelorettePartyDecorationFAQ = [
    {
      question: 'What services are included in the Bachelorette Party Decoration package?',
      answer: 'Our Bachelorette Party Decoration package includes thematic decor, balloon arrangements, table settings, banners, and other personalized decorations to create a festive atmosphere.',
    },
    {
      question: 'Can I customize the decoration theme for the bachelorette party?',
      answer: 'Yes, we offer customization options to tailor the decoration theme based on your preferences. Feel free to discuss your ideas, and we will work to make the decor unique and memorable.',
    },
    {
      question: 'Do you provide delivery and setup of the decorations?',
      answer: 'Yes, our team will handle the delivery and setup of the decorations at the chosen venue. We ensure a hassle-free experience, allowing you to focus on enjoying the celebration.',
    },
    {
      question: 'Is there a minimum notice period for booking Bachelorette Party Decoration services?',
      answer: 'To ensure availability and proper planning, we recommend booking our Bachelorette Party Decoration services at least two weeks in advance. However, we will do our best to accommodate last-minute requests.',
    },
    {
      question: 'Are the decorations reusable or available for purchase?',
      answer: 'While the decorations are designed for one-time use during the event, we also offer options for clients to purchase certain items if they wish to keep them as mementos.',
    },
    {
      question: 'Indoor and Outdoor Bachelorette Party Decorations?',
      answer: 'Yes, we offer decoration services for both indoor and outdoor bachelorette parties. Our decorations are designed to suit various venues and create a vibrant and celebratory atmosphere. Contact us to discuss your preferences!'
    },
    {
      question: 'Bachelorette Party Decoration Upgrades?',
      answer: 'Yes, we provide package upgrades and additional add-ons to enhance the decor and tailor it to your preferences. Options include lighting effects, photo booths, floral arrangements, and more.'
    },
    {
      question: 'Custom Color Schemes for Decorations?',
      answer: "Certainly! We can accommodate specific color schemes or themes for the bachelorette party decorations to match the overall theme or preferences of the bride-to-be. Share your vision with us, and we'll make it happen."
    },
    {
      question: 'Venue Decoration Restrictions?',
      answer: 'While we strive to accommodate decorations in various venues, there may be certain restrictions or limitations imposed by the venue management. We recommend consulting with us beforehand to ensure a smooth setup process.'
    },
    {
      question: 'Themed Bachelorette Party Decorations?',
      answer: 'Yes, we specialize in creating decorations for themed bachelorette parties, including beach-themed, retro-themed, glamorous, rustic, and many more. Let us bring the essence of your chosen theme to life!'
    }
  ];
  const bachelorPartyDescriptionData = {
    headingDescription_1: "Why Bachelor Party Decoration Service is Essential",
    paraDescription_1: "Creating unforgettable memories with your friends is an integral part of celebrating your bachelorhood. Bachelor parties are a time to let loose, have fun, and bond with your closest buddies before tying the knot. However, planning and organizing a memorable bachelor party can be overwhelming, especially when it comes to setting the right ambiance and creating the perfect party atmosphere. Professional bachelor party decoration service plays a crucial role in transforming your venue into an epic party space, setting the stage for an unforgettable celebration. With creative themes, personalized decor, and attention to detail, our service ensures that your bachelor party is nothing short of spectacular.",
    headingDescription_2: "What We Do in Bachelor Party Decoration Service",
    paraDescription_2: [
        "Consultation with the client to understand preferences, theme ideas, and budget constraints.",
        "Customized theme selection and decor planning to suit the style and preferences of the groom-to-be and his friends.",
        "Installation of themed decorations, including banners, balloons, signage, and table centerpieces, to create a festive atmosphere.",
        "Creation of photo booth setups with props and backdrops for capturing memorable moments.",
        "Coordination of lighting and sound effects to enhance the party ambiance and create the right mood.",
        "Arrangement of comfortable seating areas, lounge furniture, and bar setups for relaxation and socializing.",
        "Incorporation of entertainment elements such as games, music playlists, and interactive activities to keep the party lively and engaging.",
        "On-site supervision and management to ensure that the decoration setup is executed smoothly and according to plan.",
        "Cleanup and removal of decorations post-event, leaving the venue clean and tidy.",
        "Additional services available upon request, including catering, bartending, and event planning assistance."
    ]
};

  
  return (
    <div className='main'>
    <DecorationModal {...data} />
    <FaqServices faqData={bachelorettePartyDecorationFAQ} />
    <Description {...bachelorPartyDescriptionData} />
    <Benefits />
    <TestimonialCarousel />
    <Footer />
    </div>
  )
}

export default Bachellorite
