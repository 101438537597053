import React from 'react'

import {  bedbug } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';

import PestControlModal from './PestControlModal';
import FaqServices from '../../Components/FaqServices';
import Description from '../../Components/Description';

const BedbugControl = () => {

  const data = {
    headingFirst: "Bedbug Pest",
    headingSecond: "Bedbug Pest",
    price: "₹1799",
    descriptionMeta: "Keep rodents at bay with our effective Rodent Pest Control Service. From mice to rats, we'll exterminate these pests and prevent future invasions. Protect your property—schedule your rodent control service today!",

    description: [
      "Thorough inspection of living spaces for bedbug infestations",
  "Application of safe and effective pesticides to eliminate bedbugs",
  "Treatment of mattress seams, bed frames, and other potential hiding spots",
  "Steam cleaning of bedding and upholstered furniture to eradicate bedbug eggs and nymphs",
  "Crack and crevice treatment in areas where bedbugs may hide",
  "Use of specialized equipment to reach inaccessible areas",
  "Follow-up inspections to ensure complete elimination of bedbugs",
  "Education on preventive measures to avoid future bedbug infestations",
  "Safe disposal of infested materials in accordance with regulations",
  "Optional ongoing monitoring and maintenance services",
  "Environmentally friendly and pet-safe pest control methods",
     
    ],
    image: bedbug,
    space: "Bed space",
    size: "bed",
    
  
  
  }

  const bedbugPestControlFAQ = [
    {
      question: 'How do I know if I have a bedbug infestation?',
      answer: 'Common signs of a bedbug infestation include small reddish-brown bugs in bedding, bites on your skin, and tiny dark spots on sheets or furniture indicating bedbug feces.',
    },
    {
      question: 'How quickly can you respond to a bedbug infestation?',
      answer: 'We understand the urgency of dealing with bedbugs. Our team strives to respond promptly, usually within 24 to 48 hours, to assess and address the bedbug infestation.',
    },
    {
      question: 'Is your bedbug pest control service safe for children and pets?',
      answer: 'Yes, we use safe and approved methods for bedbug pest control that are specifically designed to minimize risks to children and pets. We prioritize the health and safety of your family.',
    },
    {
      question: 'Do I need to leave my home during bedbug treatment?',
      answer: 'In most cases, you can stay in your home during bedbug treatment. However, certain situations may require temporary evacuation. Our team will provide clear instructions based on your specific situation.',
    },
    {
      question: 'How long does it take to eliminate bedbugs with your pest control service?',
      answer: 'The duration to eliminate bedbugs varies depending on the severity of the infestation. On average, it may take a few treatments over several weeks to completely eradicate bedbugs from your home.',
    },
    {
      question: 'Do you offer any guarantees for your bedbug pest control service?',
      answer: 'Yes, we stand behind the effectiveness of our bedbug pest control service and may offer guarantees or warranties for certain treatments. Our team will provide detailed information about any guarantees during the consultation process.',
    },
    {
      question: 'What precautions should I take before bedbug treatment?',
      answer: "Before bedbug treatment, it's essential to declutter and vacuum the infested areas. Wash bedding and clothing in hot water and dry them on high heat to kill any bedbugs and their eggs.",
    },
    {
      question: 'Can I expect any side effects from the bedbug pest control treatment?',
      answer: 'While rare, some individuals may experience mild irritation or allergic reactions to the products used during bedbug pest control treatment. Our technicians will provide guidance on minimizing any potential side effects.',
    },
    {
      question: 'How soon can I return to my treated home after bedbug treatment?',
      answer: 'In most cases, you can safely return to your home immediately after bedbug treatment. Our technicians use safe and low-odor products that allow for minimal disruption to your daily routine.',
    },
    {
      question: 'Do you provide follow-up inspections after bedbug treatment?',
      answer: 'Yes, we offer follow-up inspections as part of our bedbug pest control service to ensure that the treatment was effective and to address any lingering bedbug activity.',
    },
  ];
  const descriptionData = {
    headingDescription_1: "Why Bedbug Pest Control Service is Essential",
    paraDescription_1: "Ensuring a safe and hygienic environment in your home is paramount for the health and well-being of your family. Bedbugs are notorious pests that can quickly infest your living space, causing discomfort, irritation, and sleepless nights. These elusive insects are adept at hiding in cracks, crevices, and furniture, making them difficult to eradicate with DIY methods. Neglecting bedbug infestations can lead to widespread infestation, property damage, and adverse health effects due to their bites and allergen-producing feces. Professional bedbug pest control service is essential for effectively eliminating bedbugs and preventing their resurgence. With specialized treatments and proven techniques, our service targets bedbugs at all stages of their life cycle, ensuring complete eradication and long-term protection for your home.",
    headingDescription_2: "What We Do in Bedbug Pest Control Service",
    paraDescription_2: [
        "Comprehensive inspection of the property to identify bedbug hotspots, nesting areas, and entry points.",
        "Application of safe and effective insecticides, including residual sprays and dusts, to target bedbugs and their hiding places.",
        "Steam treatment of infested areas to kill bedbugs and their eggs without the use of chemicals.",
        "Vacuuming of furniture, mattresses, and carpets to remove bedbugs, eggs, and debris.",
        "Heat treatment of infested items and areas to exterminate bedbugs and prevent reinfestation.",
        "Installation of bedbug traps and monitors to detect and monitor bedbug activity.",
        "Education on bedbug prevention measures and tips for maintaining a bedbug-free environment.",
        "Follow-up inspections and treatments as needed to ensure complete eradication and long-term protection.",
        "Discreet and professional service to minimize disruption to your daily routine.",
        "Customized treatment plans available to address specific infestation levels and property sizes."
    ]
};

  
 
  
  return (
    <div className='main'>
       <PestControlModal {...data} />
       <FaqServices faqData={bedbugPestControlFAQ} />
       <Description {...descriptionData} />
       <Benefits />
       <TestimonialCarousel />
       <Footer />
    </div>
  )
}

export default BedbugControl
