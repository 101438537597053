import React from 'react'

import {  cockroach } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';
import PestControlModal from './PestControlModal';

import FaqServices from '../../Components/FaqServices';
import Description from '../../Components/Description';

const CockroachControl = () => {

  const data = {
    headingFirst: "Cockroach Pest",
    headingSecond: "Cockroach Pest",
    price: "₹3999",
    description: [
      "Thorough inspection for cockroach infestations",
      "Application of effective and safe cockroach control treatments",
      "Targeted treatment of cockroach-prone areas in kitchens and bathrooms",
      "Sealing entry points to prevent cockroach entry",
      "Use of eco-friendly and pet-safe pest control solutions",
      "Monitoring and follow-up visits to ensure long-term cockroach prevention",
      "Identification and removal of cockroach hiding spots",
      "Application of preventive measures to deter future cockroach infestations",
      "Professional advice on maintaining a cockroach-free environment",
    ],
    image: cockroach,
    space: "living space",
    size: "house",
    descriptionMeta:"Eliminate cockroaches with our comprehensive Cockroach Pest Control Service. Our treatments are tailored to eradicate cockroach infestations and prevent future outbreaks. Say goodbye to cockroaches—schedule your service now!",
    
  
  }
  const cockroachPestControlFAQ = [
    {
      question: 'How often should I schedule cockroach pest control services?',
      answer: 'The frequency of cockroach pest control services depends on the severity of the infestation and the preventive measures in place. For most homes, a bi-annual service may be sufficient.',
    },
    {
      question: 'Are the pesticides used in cockroach pest control safe for humans and pets?',
      answer: 'Yes, the pesticides used in our cockroach pest control service are safe for humans and pets when applied by trained professionals. We prioritize the use of eco-friendly and low-toxicity solutions.',
    },
    {
      question: 'Do I need to vacate my home during cockroach pest control treatment?',
      answer: 'In most cases, there is no need to vacate your home during cockroach pest control treatment. However, it is advisable to keep children, pregnant women, and pets away from treated areas until they are dry.',
    },
    {
      question: 'How long does it take to see results after cockroach pest control treatment?',
      answer: 'You may notice a reduction in cockroach activity shortly after the treatment. Complete eradication may take a few days to a few weeks, depending on the extent of the infestation.',
    },
    {
      question: 'Do you provide follow-up services after cockroach pest control?',
      answer: 'Yes, we offer follow-up services to ensure the effectiveness of the cockroach pest control treatment. This may include additional inspections and treatments if needed.',
    },
    {
      question: 'What are common signs of a cockroach infestation?',
      answer: 'Common signs of a cockroach infestation include seeing live cockroaches, droppings that resemble black pepper or coffee grounds, egg casings, and a musty odor in areas where cockroaches hide.',
    },
    {
      question: 'Can cockroaches pose health risks to humans?',
      answer: 'Yes, cockroaches can pose health risks as they carry bacteria, pathogens, and allergens. Exposure to cockroach allergens can trigger asthma attacks and allergic reactions in sensitive individuals.',
    },
    {
      question: 'How do cockroaches enter homes?',
      answer: 'Cockroaches can enter homes through cracks, crevices, gaps in doors and windows, sewer lines, drains, and pipes. They are attracted to food, moisture, and shelter.',
    },
    {
      question: 'What preventive measures can I take to avoid cockroach infestations?',
      answer: 'To prevent cockroach infestations, keep your home clean and free of food debris, seal cracks and crevices, store food in airtight containers, fix plumbing leaks, and maintain proper sanitation practices.',
    },
    {
      question: 'Are there any natural remedies for controlling cockroach infestations?',
      answer: 'While natural remedies like boric acid, diatomaceous earth, and essential oils may provide some control over cockroaches, they may not be as effective as professional pest control treatments for severe infestations.',
    },
  ];
  const descriptionData = {
    headingDescription_1: "Why Cockroach Pest Control Service is Essential",
    paraDescription_1: "Ensuring a pest-free environment is crucial for maintaining the health and hygiene of your home or business premises. Cockroaches, in particular, pose serious health risks as they can contaminate food, spread diseases, and trigger allergic reactions. These resilient pests can quickly infest properties and are notoriously difficult to eradicate with DIY methods. Professional cockroach pest control service is essential for effectively eliminating cockroach infestations and preventing their recurrence. Our comprehensive approach targets cockroaches at their source, addressing breeding grounds, entry points, and hiding places. With specialized treatments and advanced techniques, we not only exterminate existing cockroaches but also implement preventive measures to safeguard your property against future infestations. Investing in professional cockroach pest control service is essential for protecting the health and well-being of your family, employees, and customers.",
    headingDescription_2: "What We Do in Cockroach Pest Control Service",
    paraDescription_2: [
        "Thorough inspection of the property to identify cockroach infestation hotspots and entry points.",
        "Strategic placement of bait stations, gel baits, and traps to attract and eliminate cockroaches.",
        "Application of targeted insecticide sprays or dusts to treat cockroach harborage areas and nesting sites.",
        "Sealing of cracks, crevices, and gaps in walls, floors, and plumbing fixtures to prevent cockroach entry.",
        "Treatment of drainage systems, sewers, and other potential cockroach breeding grounds.",
        "Education and recommendations on sanitation practices to reduce cockroach attractants and conducive conditions.",
        "Follow-up visits to monitor treatment effectiveness and address any remaining cockroach activity.",
        "Guaranteed service satisfaction and continued support to ensure long-term cockroach control.",
        "Emergency response services available for severe infestations or urgent pest control needs.",
        "Environmentally friendly pest control options available upon request to minimize environmental impact."
    ]
}

  
  
  
  return (
    <div className='main'>
       <PestControlModal {...data} />
       <FaqServices faqData={cockroachPestControlFAQ} />
       <Description {...descriptionData} />
       <Benefits />
       <TestimonialCarousel />
       <Footer />
    </div>
  )
}

export default CockroachControl
