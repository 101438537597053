import React, { useState } from "react";
import Footer from "../Components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MetaData from "../Metadata";

const Partner = () => {
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    mobileNumber: "",
    email: "",
    selectedCity: "",
  });


 

  const cities = [
    "Delhi",
    "Gurugram",
    "Noida",
    "Pune",
    "Mumbai",
    "Bangalore",
    "Kolkata",
    "Ahmedabad",
    "Mysuru",
    "Chennai",
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
  };


  const handlecitychange = (e) =>{
    const selectedCity = e.target.value;
    setCity(selectedCity);
  }

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState(cities[0]);
  const [message, setMessage] = useState("");
  const [typeofservice, setTypeofservice] = useState("");
  const [experience, setExperience] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [formError, setFormError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);


  let dataSend = {};

  const navigate = useNavigate();

  const validateForm = () => {
    let isValid = true;


    if (name.length < 3) {
      setNameError("Please enter atleast 3 character");
      isValid = false;
    } else {
      setNameError("");
     
    }

 
    if (!email.includes("@") 
    || email.length < 5) {
      setEmailError("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError("");
    }

   
   
    if (!/^\d+$/.test(mobile)) {
      setMobileError("Please enter a valid mobile number");
      isValid = false;
    } else if (mobile.length < 10 || mobile.length > 13) {
      setMobileError("Please enter a valid mobile number");
      isValid = false;
    } else {
      setMobileError("");
    }

   
    if (!isValid) {
      setFormError("Please correct the errors in the form.");
    } else {
      setFormError("");
    }


    setIsFormValid(isValid);
    return isValid;
  };


  const baseUrl = "https://api.kbshomeservice.com";

  const sendEmail = async () => {



    if (validateForm()) {
      navigate('/responsepage')
     dataSend = {
      name: name,
      email: email,
      mobile: mobile,
      address: address,
      city: city,
      message: message,
      typeofservice: typeofservice,
      experience: experience,
    };
    console.log("Email sent successfully!");
  } else {
    // Form validation failed
    console.log("Form validation failed. Please correct the errors.");
  }

    const res = await fetch(`${baseUrl}/email/vendor-signup`, {
      method: "POST",
      body: JSON.stringify(dataSend),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      // HANDLING ERRORS
      .then((res) => {
        console.log(res);
        if (res.status > 199 && res.status < 300) {
          alert("Send Successfully !");
        }
      });
  };

  return (
    <>
    <MetaData title="Partner with us - KBS Home Services" />
      <div className="container flex justify-center items-center mx-auto mt-10 p-4">
        <div className="z-20 shadow-lg bg-gradient-to-br w-[300px] xs:w-[400px] md:w-full  text-black from-secondary to-black via-secondary p-6 mb-9 rounded-md">
          <h1 className="text-3xl font-semibold mb-4 text-white text-center">Partner With Us</h1>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col items-center justify-center md:grid md:grid-cols-3  md:gap-4"
          >
            {/* Name */}
            <div className="mb-4">
              
              <input
                placeholder="Enter name"
                type="name"
                onChange={(e) => setName(e.target.value)}
                //   name="name"
                //   value={formData.name}
                //   type="text"
                //   onChange={handleInputChange}
                className="bg-gray-100 h-5 p-5 rounded-md shadow-slate-300	 shadow-inner px-2 sm:w-[370px]  xs:w-[320px]   w-[250px] md:w-full mt-2 text-black"
                required
              />
              {nameError && <p className="text-white font-semibold text-xs mt-1">{nameError}</p>}
            </div>

            {/* Email */}
            <div className="mb-4">
             
              <input
                placeholder="Enter email address"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                //   name="email"
                //   value={formData.email}
                //   type="email"
                //   onChange={handleInputChange}
                className="bg-gray-100 h-5 p-5 rounded-md shadow-slate-300	sm:w-[370px]  xs:w-[320px]   w-[250px] shadow-inner px-2 md:w-full mt-2 text-black"
                required
              />
              {emailError && <p className="text-white font-semibold text-xs mt-1">{emailError}</p>}
            </div>

            {/* Mobile Number */}
            <div className="mb-4">
              <input
                placeholder="Enter mobile number"
                type="mobile"
                onChange={(e) => setMobile(e.target.value)}
                //   name="mobileNumber"
                //   value={formData.mobileNumber}
                //   type="text"
                //   onChange={handleInputChange}
                className="bg-gray-100 h-5 p-5 rounded-md shadow-slate-300 sm:w-[370px]  xs:w-[320px]   w-[250px]	 shadow-inner px-2 md:w-full mt-2 text-black"
                required
              />
              {mobileError && <p className="text-white font-semibold text-xs mt-1">{mobileError}</p>}
            </div>

            {/* Address */}
            <div className="mb-4">
             
              <input
                placeholder="Enter complete address"
                type="address"
                onChange={(e) => setAddress(e.target.value)}
                //   name="address"
                //   value={formData.address}
                //   type="text"
                //   onChange={handleInputChange}
                className="bg-gray-100 h-5 p-5 rounded-md shadow-slate-300 sm:w-[370px]  xs:w-[320px]   w-[250px]	 shadow-inner px-2 md:w-full mt-2 text-black"
                required
              />
            </div>

            {/* City Selection */}
            <div className="mb-4">
              <select
                placeholder="Delhi"
                type="city"
                onChange= {handlecitychange}
                //   name="city"
                //   value={formData.address}
                //   type="text"
                //   onChange={handleInputChange}
                className="bg-gray-100 h-10 rounded-md shadow-slate-300 	sm:w-[370px]  xs:w-[320px]   w-[250px]	 shadow-inner px-2 md:w-full mt-2 text-black"
                required
              >
                 {cities.map((city, index) => (
                  <option  className="py-2 font-poppins text-black" key ={index} value ={city}>
                    {city}
                  </option>
                ))}
              </select>
            </div>
           

            {/* Type Of Service Selection */}
            <div className="mb-4">
             
              <input
                placeholder="Type of Service you provide"
                type="typeofservice"
                onChange={(e) => setTypeofservice(e.target.value)}
                //   name="typeofservice"
                //   value={formData.address}
                //   type="text"
                //   onChange={handleInputChange}
                className="bg-gray-100 h-5 p-5 rounded-md shadow-slate-300	sm:w-[370px]  xs:w-[320px]   w-[250px]	 shadow-inner px-2 md:w-full mt-2 text-black"
                required
              />
            </div>

            {/* year of experience Selection */}
            <div className="mb-4">
           
              <input
                placeholder="Total year of experience"
                type="experience"
                onChange={(e) => setExperience(e.target.value)}
                //   name="typeofservice"
                //   value={formData.address}
                //   type="text"
                //   onChange={handleInputChange}
                className="bg-gray-100 h-5 p-5 rounded-md shadow-slate-300	sm:w-[370px]  xs:w-[320px]   w-[250px]	 shadow-inner px-2 md:w-full mt-2 text-black"
                required
              />
            </div>
             {/* Message Selection */}
             <div className="mb-4">
            
            <input
              
              placeholder="Comment Here"
              type="message"
              onChange={(e) => setMessage(e.target.value)}
              //   name="message"
              //   value={formData.address}
              //   type="text"
              //   onChange={handleInputChange}
              className="bg-gray-100 h-5 p-5 rounded-md shadow-slate-300	sm:w-[370px]  xs:w-[320px]   w-[250px]	 shadow-inner px-2 md:w-full mt-2 text-black"
              required
            />
          </div>

            {/* Submit Button */}
            <div className="col-span-3">
              <button
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
              
                  // Check if any of the required fields are empty
                  if (!name || !email || !mobile || !city || !message || !address || !typeofservice || !experience) {
                    toast.error("Please fill all the fields");
                    return;
                  }
              
                  if (!validateForm()) {
                    toast.error("Please correct the errors in the form.");
                    return;
                  }
              
                  // If all required fields are filled, send the email
                  sendEmail();
                }}
                className=" shadow-secondary/50 shadow-lg sm:w-[370px]  xs:w-[320px]   w-[250px]	 md:w-auto   bg-orange-400 font-poppins cursor-pointer text-white px-4 py-1 text-md rounded-md text-lg hover:bg-[#9BCF53]  hover:text-white"
              >
                Submit
               </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Partner;
