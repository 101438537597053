import React, { useState } from "react";
import {
 FaWhatsapp,
 FaAngleDown,
 FaPhone,

} from "react-icons/fa";

import HomeCleaning from "../assets/videos/DeepHomeCleaning.mp4";

import { motion } from "framer-motion";



import { interior1, check} from "../assets";
import FaqServices from "../Components/FaqServices";
import Benefits from "./Benefits";
import TestimonialCarousel from "./Testimonials";
import Footer from "../Components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Description from "../Components/Description";
import MetaData from "../Metadata";
import RequestFormFlat from "../Components/RequestFormFlat";


const InteriorDesign = ({
 headingFirst,
 headingSecond,

 
 image,
 options,

 selectedLocation,
}) => {
   

  const formService = {
    headingFirst: "Interior Designing"
  }


 const flatOptions = [
   "1 BHK",
   "2 BHK",
   "3 BHK",
   "4 BHK+",
  
   "Bungalow",
 ];

 const flatRates = {
   "1 BHK": "3999",
   "2 BHK": "4999",
   "3 BHK": "5499",
   "4 BHK+": "On inspection",
   "2000-3000 sq. ft. Villa": "On inspection",
   "4000-6000 sq. ft. Villa": "On inspection",
   "6000-7000 sq. ft. Villa": "On inspection",
   "Bungalow": "On inspection",
 };
 const handleOptionChange = (e) => {
   const selectedOption = e.target.value;
   setSelectedFlat(selectedOption);
   
   setRate(flatRates[selectedOption]);
 };

 const [name, setName] = useState("");
 const [email, setEmail] = useState("");
 const [mobile, setMobile] = useState("");
 const [location, setLocation] = useState("");
 const [message, setMessage] = useState("");
 const [service, setService] = useState("Interior Designing Service");
 const [rate, setRate] = useState("3999");
 const [selectedFlat, setSelectedFlat] = useState(flatOptions[0]);
 const [nameError, setNameError] = useState("");
 const [emailError, setEmailError] = useState("");
 const [mobileError, setMobileError] = useState("");
 const [formError, setFormError] = useState("");
 const [isFormValid, setIsFormValid] = useState(false);


 const navigate = useNavigate();

 let dataSend = {};

  const validateForm = () => {
    let isValid = true;


    if (name.length < 3) {
      setNameError("Please enter atleast 3 character");
      isValid = false;
    } else {
      setNameError("");
     
    }

 
    if (!email.includes("@") 
    || email.length < 5) {
      setEmailError("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError("");
    }

   
  
    if (!/^\d+$/.test(mobile)) {
     setMobileError("Please enter a valid mobile number");
     isValid = false;
   } else if (mobile.length < 10 || mobile.length > 13) {
     setMobileError("Please enter a valid mobile number");
     isValid = false;
   } else {
     setMobileError("");
   }

   
    if (!isValid) {
      setFormError("Please correct the errors in the form.");
    } else {
      setFormError("");
    }



    setIsFormValid(isValid)

    return isValid;
  };
  const baseUrl = "https://api.kbshomeservice.com";
  const sendEmail = async () => {

  

    
    if (validateForm()) {
      navigate('/responsepage')
     dataSend = {
        name: name,
        email: email,
        mobile: mobile,
        location: location,
        message: message,
        service: service,
        selectedFlat: selectedFlat,
      };
     
      console.log("Email sent successfully!");
    } else {
      // Form validation failed
      console.log("Form validation failed. Please correct the errors.");
    }
  

  
    
    const res = await fetch(`${baseUrl}/email/sendEmail`, {
      method: "POST",
      body: JSON.stringify(dataSend),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    // HANDLING ERRORS
    .then((res) => {
      console.log(res);
      if (res.status > 199 && res.status < 300) {
        alert("Send Successfully !");
      }
    });
    
    toast.success('Email sent successfully!');
    navigate('/responsepage');
  };
  const selectedCity = localStorage.getItem('city')

  const InteriorDesigningFAQ = [
    {
        question: 'When should I consider hiring an interior designer?',
        answer: "Hiring an interior designer is beneficial when you're planning to renovate, remodel, or redecorate your space. Whether you're starting from scratch or simply seeking to refresh your interior, an interior designer can offer valuable expertise and creative insights.",
    },
    {
        question: 'What are the key benefits of hiring an interior designer?',
        answer: 'An interior designer can help you save time and money by providing expert guidance on design decisions, preventing costly mistakes, and maximizing the potential of your space. Additionally, they can source unique furnishings and decor, and coordinate with contractors to ensure a seamless execution of your design vision.',
    },
    {
        question: 'How involved will I be in the design process?',
        answer: 'Your level of involvement in the design process is entirely up to you. A good interior designer will work closely with you to understand your preferences, lifestyle, and functional needs, ensuring that the final design reflects your personal style and vision.',
    },
    {
        question: 'What factors should I consider when choosing an interior designer?',
        answer: "When selecting an interior designer, consider their portfolio, design style, communication style, qualifications, and experience. It's important to find someone who aligns with your aesthetic preferences and understands your project goals.",
    },
    {
        question: 'Can an interior designer work within my budget?',
        answer: 'Yes, a skilled interior designer can work within a range of budgets, offering creative solutions and alternatives to help you achieve your desired look without compromising on quality. Be transparent about your budget from the outset to ensure realistic expectations and effective budget management throughout the project.',
    },
    {
        question: 'What is the typical timeline for an interior design project?',
        answer: 'The timeline for an interior design project varies depending on the scope of work, the size of the space, and other factors. A simple redecoration project may take a few weeks, while a full-scale renovation could take several months. Your interior designer will provide a detailed timeline based on your specific project requirements.',
    },
    {
        question: 'How can interior design improve the functionality of my space?',
        answer: "Interior design is not just about aesthetics; it's also about enhancing the functionality and usability of your space. An interior designer can optimize layout, traffic flow, and spatial organization to improve comfort, efficiency, and overall livability.",
    },
    {
        question: 'Do I need to purchase new furniture and decor for my design project?',
        answer: 'Not necessarily. An interior designer can work with your existing furnishings and decor, incorporating them into the new design scheme if desired. However, they can also suggest new pieces to complement your existing items or to achieve a cohesive look and feel.',
    },
    {
        question: 'What happens if I don\'t like the design proposed by the interior designer?',
        answer: "Open communication is key in the design process. If you're unhappy with the initial design concept, discuss your concerns with the interior designer. They can revise the design based on your feedback and preferences until you're completely satisfied with the outcome.",
    },
    {
        question: 'Can an interior designer help with commercial spaces?',
        answer: 'Yes, many interior designers specialize in commercial design projects, such as offices, retail stores, restaurants, and hospitality venues. They can create functional, aesthetically pleasing environments that reflect your brand identity and cater to the needs of your employees, customers, or clients.',
    },
];


const description = [
    "Comprehensive interior design services to transform your space into a personalized haven",
    "Tailored design plans crafted to reflect your unique style, preferences, and functionality needs",
    "Creative concepts and meticulous attention to detail to enhance the aesthetics and functionality of your interior",
    "Professional sourcing and selection of furnishings, decor, and materials to elevate the ambiance of your space",
    "Expert coordination of contractors and craftsmen to ensure seamless execution of design projects",
    "Strategic space planning to optimize layout and maximize the potential of every area in your home or office",
    "Integration of innovative design solutions to address specific challenges and enhance usability",
    "Customized color schemes, lighting solutions, and textures to create the desired atmosphere and mood",
    "Transparent communication and collaboration throughout the design process to bring your vision to life",
    "Collaboration with experienced architects, contractors, and suppliers to deliver exceptional results on time and within budget",
    "Thorough project management and supervision to oversee every aspect of the design process and ensure client satisfaction",
];

const descriptionDataInteriorDesign = {
  headingDescription_1: "Why Interior Designing is Essential for Your Space",
  paraDescription_1: "Interior designing goes beyond mere decoration; it's about creating functional, aesthetically pleasing spaces that enhance your quality of life. Whether it's your home or workplace, the design of your environment significantly impacts your mood, productivity, and overall well-being. A well-designed space reflects your personality, values, and aspirations while maximizing functionality and comfort. From optimizing layouts to selecting furniture and decor, interior designing transforms ordinary spaces into extraordinary havens tailored to your needs and preferences. With professional interior design services, you can unlock the full potential of your space, creating inspiring environments that enrich your daily life and leave a lasting impression.",
  headingDescription_2: "What We Do in Interior Designing Services",
  paraDescription_2: [
      "Comprehensive space planning to optimize layout and functionality.",
      "Tailored design concepts reflecting your unique style and personality.",
      "Selection and sourcing of furniture, decor, and materials to enhance aesthetics and comfort.",
      "Integration of color schemes, lighting solutions, and textures to create ambiance and mood.",
      "Customized solutions for storage, organization, and space utilization.",
      "Collaboration with architects and contractors to ensure seamless execution of design plans.",
      "Project management and supervision to oversee every aspect of the design process.",
      "Coordination of installations, deliveries, and finishing touches to bring the design to life.",
      "Post-installation support and guidance for ongoing maintenance and enhancements.",
      "Continuous communication and collaboration to ensure your vision is realized.",
      "Adaptability to evolving needs and preferences throughout the design journey."
  ]
};

 return (
  <><MetaData title="Interior Designing - KBS Home Services" 
  descriptionMeta="Elevate your space with our personalized Interior Designing Service. From concept to execution, we'll transform your vision into reality with creativity and expertise. Create your dream space—schedule your interior designing service now!
  "
  /><div>
     <div className="bg-interior w-full lg:h-[88vh] flex flex-col justify-start items-start">
        <div className="flex  flex-col w-full  p-2 mt-[30px] lg:mt-0 pr-8 pl-8">
          {/* <div className="circlePosition hidden   w-[540px] h-[400px] bg-orange-400 rounded-[100%] absolute z-1 top-[20%] lg:top-[58%] left-[10%] translate-x-[-50%] translate-y-[-50%] blur-[150px]"></div> */}
          <div className="w-full flex flex-col items-center md:flex-row  ">
            <div className=" mt-5 xl:h-[510px] justify-center moto w-full flex flex-col text-white font-normal   rounded-3xl relative  pb-5 lg:p-[20px]  ">
              <div className="flex flex-row justify-between items-center w-full z-10">
                <motion.h1
                  initial={{ y: "2rem", opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{
                    duration: 2,
                    type: "spring",
                    delay: 0.75,
                  }}
                  className="flex-1 font-poppins font-bold  ss:text-[65px] text-[25px] text-orange-400 ss:leading-[70.8px] leading-[45px] "
                >
                  Interior Designing <br className="sm:block hidden" />{" "}
                
                    
                 
                  <span className="font-poppins text-white font-bold sm:text-[50px] text-[25px]">
                    {" "}in {selectedCity}
                  </span>
                </motion.h1>
              </div>
              <div className="hidden lg:flex">
                <motion.p

                  initial={{ y: "2rem", opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{
                    duration: 2,
                    type: "spring",
                    delay: 1.05,
                  }}
                  className="font-poppins   font-normal sm:text-[18px] text-[16px] ss:leading-[30px] leading-[25px] text-white max-w-[670px] sm:mt-5 mt-3 text-justify"
                >
                  Experience a transformative home with KBS Home Service your
                  go-to for impeccable deep cleaning. Call us now to revive your
                  living spaces.
                </motion.p>
              </div>

               <div className="bg-secondary/20 w-[180px] hidden sm:block h-auto mt-6 rounded-[10px] pl-3 backdrop-blur  justify-center items-center">
                <div className="w-[180px] items-center flex flex-row gap-3">
                  <div className="stars">
                    <ul className="flex flex-row gap-1 mt-2 ">
                      <li className="text-amber-400 text-lg ">
                        <img
                          loading="lazy"
                          width="20"
                          height="20"
                          src="https://img.icons8.com/fluency/20/star--v1.png"
                          alt="star--v1" />
                      </li>
                      <li className="text-amber-400 text-lg">
                        <img
                          loading="lazy"
                          width="20"
                          height="20"
                          src="https://img.icons8.com/fluency/20/star--v1.png"
                          alt="star--v1" />
                      </li>
                      <li className="text-amber-400 text-lg">
                        <img
                          loading="lazy"
                          width="20"
                          height="20"
                          src="https://img.icons8.com/fluency/20/star--v1.png"
                          alt="star--v1" />
                      </li>
                      <li className="text-amber-400 text-lg">
                        <img
                          loading="lazy"
                          width="20"
                          height="20"
                          src="https://img.icons8.com/fluency/20/star--v1.png"
                          alt="star--v1" />
                      </li>
                    </ul>
                  </div>

                  <div className="rating items-center pt-3 font-poppins text-amber-500 font-semibold sm:text-[22px]">
                    4.8
                  </div>
                </div>
                <p className="font-semibold text-[14px] font-poppins mb-2 mt-[-2px] text-white">
                  Based on 442 Reviews
                </p>
              </div>

              <div className="keypoints relative w-[300px] sm:w-[400px] ">

              
                <div className="check relative  grid grid-cols-2 gap-3 mt-6">
                <div className="absolute sm:hidden left-1/2 top-0 h-full w-px bg-white"></div>
            {/* Horizontal line */}
            <div className="absolute sm:hidden left-0 top-1/2 w-full h-px bg-white"></div>

                
                  <div className=" text-[14px] w-full sm:text-[16px] flex flex-row gap-1 sm:gap-3 items-center text-white font-poppins">
                    <img className="w-[15px] sm:w-[20px]" src={check} loading="lazy" alt="check" />
                    Top Quality Service 

                  </div>
                  <div className="text-[14px] w-full  sm:text-[16px] flex flex-row gap-1 sm:gap-3 items-center text-white font-poppins">
                    <img className="w-[15px] sm:w-[20px]" src={check} loading="lazy" alt="check" />
                    Skilled Experts

                  </div>
                  <div className="text-[14px] w-full  sm:text-[16px] flex flex-row gap-1 sm:gap-3 items-center text-white font-poppins">
                    <img className="w-[15px] sm:w-[20px]" src={check} loading="lazy" alt="check" />
                    Best Price Match

                  </div>
                  <div className="text-[14px] w-full  sm:text-[16px] flex flex-row gap-1 sm:gap-3 items-center text-white font-poppins">
                    <img className="w-[15px] sm:w-[20px]" src={check} loading="lazy" alt="check" />
                    Payment after Work
                  </div>
                </div>
              </div>
            </div>
            <div className="relative lg:mt-0 hidden ">
              <video
                autoPlay
                loop
                muted
                className="w-[1200px] h-full mt-4 object-cover rounded-2xl "
              >
                <source src={HomeCleaning} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>


     {/*-------------Booking Section---------- */}
     <div className=" bg-[#F5F7F8] lowersection w-full flex flex-col justify-start lg:w-auto items-center md:items-start md:flex-row-reverse px-3 md:px-5 gap-3 ">
       {/*---------------Request Form------------ */}
     <RequestFormFlat {...formService} />



       <div className=" mt-8 pb-10 mb-8 rounded-3xl w-full bg-white/70 lg:ml-8 px-2 relative  ">
         <div className="flex flex-col md:flex-col w-auto justify-start items-center sm:mt-0 mt-5 pl-5 pt-5 sm:items-start md:items-start">
           <div className="font-semibold font-poppins text-2xl">
             {headingSecond}  Interior Designing Services
           </div>
           <p className="font-poppins text-[16px] justify-center items-center">
             Book our service, let the dust settle – we'll make your space
             sparkle!
           </p>
           {/* flex replaced by hidden in future will be changed */}
           <div className=" hidden flex-col gap-2 justify-start items-start ">
             <div className="font-semibold font-poppins text-2xl">
               {headingSecond} Interior Designing Services
             </div>
             <p className="font-poppins text-[16px] justify-center items-center">
               Book our service, let the dust settle – we'll make your space
               sparkle!
             </p>

             <div className=" flex flex-col gap-1">
               <div className="flex flex-row items-center mt-4">
                 <span className="text-gray-500 font-poppins line-through">
                   ₹9999
                 </span>
                 <span className="text-blue-600 font-bold font-poppins ml-2 text-xl">
                   {rate && <p> ₹{rate}</p>}
                 </span>
               </div>
               <span className="text-dimWhite  font-poppins text-sm">
                 (40% OFF on first booking)
               </span>
             </div>

             <div className="mt-2  font-poppins">
               <div className="relative ">
                 <select
                   className="block appearance-none font-poppins rounded-xl text-[18px] bg-secondary/20 shadow-xl text-gray-700 w-[220px] py-4 px-[18px]  leading-tight focus:outline-none focus:ring"
                   onChange={handleOptionChange}
                   value={selectedFlat}
                 >
                   <option value="" disabled>
                     {options ? "Select Office Size" : "Select Flat Options"}
                   </option>
                   {options
                     ? options.map((size, index) => (
                       <option key={index} value={size}>
                         {size}
                       </option>
                     ))
                     : flatOptions.map((flat, index) => (
                       <option key={index} value={flat}>
                         {flat}
                       </option>
                     ))}
                 </select>
                 <div className="pointer-events-none absolute inset-y-0 right-4 flex items-center px-1 text-gray-700">
                   <FaAngleDown />
                 </div>
               </div>
             </div>

             <div className="flex flex-row justify-center items-center gap-5">
               <div className="whatsapp">
                 <Link to="https://api.whatsapp.com/send?phone=+916363617916&text=Hi,%20I%20would%20like%20to%20discuss%20further%20on%20the%20services%20offered%20by%20KBS%20Home%20Services...%20Kindly%20connect.%20Thanks"><button className="bg-[#65B741] shadow-[#65B741]/60 shadow-lg mt-2 font-poppins flex flex-row gap-2 justify-center items-center cursor-pointer text-white px-2 py-3 text-sm rounded-lg hover:bg-[#FB8B24]  hover:text-white">
                   <FaWhatsapp />
                   Book on whatsapp
                 </button>
                 </Link>
               </div>

               <div className="callback">
                 <Link to="tel:6363617916">     <button className="bg-black font-poppins button7  justify-center items-center cursor-pointer text-white text-sm px-4 py-3 rounded-md   flex flex-row gap-2">
                   {" "}
                   <FaPhone />
                   Tap to Dial
                 </button></Link>
               </div>
             </div>
           </div>
           <div className="deepimagehouse py-5    flex-col justify-center items-center hidden  lg:flex">
             <img
               loading="lazy"
               className="w-[350px] h-[300px]  rounded-xl shadow-lg"
               src={interior1}
               alt="deephouse" />
           </div>
         </div>

         {/*------------- Description section-------------- */}
         <div className=" w-auto justify-start items-center sm:mt-0  pl-5 pt-4">
           <div className="text-xl font-poppins font-semibold mb-4 mt-4">
             Our Interior Designing Services
           </div>
           <ul className="list-disc pl-5 font-poppins leading-[25px]">
             {description.map((item, index) => (
               <li key={index}>{item}</li>
             ))}
           </ul>

           <div className="pt-5">
             <h2 className="text-xl font-poppins font-semibold mb-4">
               Service Time
             </h2>
             <ul className="list-disc pl-5 font-poppins leading-[25px]">
               <li>Elevate your space in a mere 8 to 12 hours</li>
             </ul>
             <p className="pl-4 text-dimWhite">
               (Depending upon size of flat )
             </p>
           </div>

           <div className="pt-5">
             <h2 className="text-xl font-poppins font-semibold mb-4">
               No. of Servicemen
             </h2>
             <ul className="list-disc pl-5 font-poppins leading-[25px]">
               <li>
                 2-6{" "}
                 <span className="pl-2 text-dimWhite">
                   (Depending upon size of flat)
                 </span>
               </li>
             </ul>
           </div>
         </div>
       </div>

     </div>
     <FaqServices faqData={InteriorDesigningFAQ} />
     <Description {...descriptionDataInteriorDesign} />
     <Benefits />
     <TestimonialCarousel />
     <Footer />


   </div></>
 );
};

export default InteriorDesign;
