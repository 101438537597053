import React from 'react'

import {  flower } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';

import DecorationModal from './DecorationModal';
import FaqServices from '../../Components/FaqServices';
import Description from '../../Components/Description';

const FlowerDecoration = () => {

  const data = {
    headingFirst: "Flower",
    headingSecond: "Flower",
    price: "₹2499",
    description: [
      "Customized floral arrangements for various events and occasions",
      "Creation of stunning centerpieces and table decorations",
      "Design and installation of flower arches and backdrops",
      "Bouquet arrangements for weddings, parties, and special celebrations",
      "Seasonal and thematic floral decor options",
      "Incorporation of client preferences and color schemes",
      "Fresh flower garlands for events and religious ceremonies",
      "Indoor and outdoor floral installations",
      "Professional consultation on flower selection and design concepts",
      "Coordination with event themes and aesthetics",
      "Delivery, set-up, and removal of floral arrangements as per client requirements",
    ],
    image: flower,
    space: "living space",
    size: "place",
    descriptionMeta: "Enhance the beauty of any occasion with our enchanting Flower Decoration Service. From elegant centerpieces to stunning floral arrangements, we'll infuse your event with the fragrance and charm of fresh blooms. Let us create a floral masterpiece for your special day—schedule your decoration service now!",
  
  }
  const flowerDecorationFAQ = [
    {
      question: 'What occasions or events do you provide flower decoration services for?',
      answer: 'We provide flower decoration services for a wide range of occasions and events, including weddings, birthdays, anniversaries, corporate events, and more.',
    },
    {
      question: 'Can I choose specific flowers and colors for the decoration?',
      answer: 'Yes, you can customize the flower decoration by choosing specific flowers and colors that match your theme or preferences. Our florists will work with you to create a personalized arrangement.',
    },
    {
      question: 'Do you offer both indoor and outdoor flower decoration services?',
      answer: "Yes, we offer flower decoration services for both indoor and outdoor venues. Whether it's a banquet hall, garden, or any other location, we can create beautiful floral arrangements to enhance the ambiance.",
    },
    {
      question: 'How far in advance should I book flower decoration services?',
      answer: 'It is recommended to book flower decoration services well in advance, especially for popular dates and peak seasons. This ensures that our team can plan and execute your floral arrangements seamlessly.',
    },
    {
      question: 'Do you provide floral arrangements for specific themes or cultural preferences?',
      answer: 'Absolutely! We can create floral arrangements tailored to specific themes, cultural traditions, or religious ceremonies. Our goal is to bring your vision to life through stunning flower decorations.',
    },
    {
      question: 'Can I request special arrangements like bouquets  or flower walls?',
      answer: 'Yes, you can request special arrangements such as bouquets, centerpieces, flower walls, and more for your event. Our skilled florists can create stunning floral displays to suit your preferences and venue.',
    },
    {
      question: 'Do you provide consultation services to help with flower selection and design?',
      answer: 'Yes, we offer consultation services to assist you with flower selection, design ideas, and overall floral theme planning. Our experienced florists will guide you through the process to create the perfect floral arrangements for your event.',
    },
    {
      question: 'Are there any additional services  available with the flower decoration packages?',
      answer: 'Yes, we offer additional services and add-ons such as vase rentals, delivery services, floral preservation, and more to complement your flower decoration packages. You can discuss your requirements with our team during the consultation.',
    },
    {
      question: 'Can I view samples of your previous flower decoration work?',
      answer: 'Yes, we can provide samples of our previous flower decoration work to give you an idea of our style and craftsmanship. You can also browse our portfolio or visit our showroom to see our floral arrangements firsthand.',
    },
    {
      question: 'What is the process for booking flower decoration services?',
      answer: "To book our flower decoration services, simply contact us to discuss your event details, preferences, and requirements. We'll provide you with a quote and guide you through the booking process to ensure a seamless experience.",
    },
  ];

  const descriptionDataFlowerDecoration = {
    headingDescription_1: "Why Professional Flower Decoration Service is Essential",
    paraDescription_1: "Transforming an event or space into a visually stunning and memorable experience often hinges on the quality of floral arrangements and decorations. Flowers have the remarkable ability to evoke emotions, set the tone, and enhance the ambiance of any occasion. Whether it's a wedding, corporate event, or special celebration, the right floral decor can elevate the atmosphere and leave a lasting impression on guests. Professional flower decoration service plays a pivotal role in bringing your vision to life, from conceptualizing designs to executing intricate arrangements. With meticulous attention to detail and a passion for creativity, our team ensures that every floral element complements the theme, style, and aesthetic of your event, creating an enchanting and immersive experience for all.",
    headingDescription_2: "What We Do in Professional Flower Decoration Service",
    paraDescription_2: [
        "Collaboration with clients to understand their vision, preferences, and event requirements.",
        "Selection of fresh, high-quality flowers in a variety of colors, textures, and varieties.",
        "Customized floral design concepts tailored to suit the theme, style, and ambiance of the event.",
        "Creation of stunning floral arrangements, including centerpieces, bouquets, garlands, and installations.",
        "Coordination with event planners, venues, and vendors to ensure seamless execution of floral decor.",
        "On-site setup and installation of floral arrangements, paying attention to every detail and arrangement.",
        "Use of innovative techniques and materials to enhance the longevity and freshness of floral displays.",
        "Flexible delivery and installation schedules to accommodate the timing and logistics of the event.",
        "Collaboration with photographers and videographers to capture the beauty of floral decor for lasting memories.",
        "Prompt teardown and cleanup of floral arrangements post-event, leaving the venue immaculate."
    ]
};

  
  return (
    <div className='main'>
       <DecorationModal {...data} />
       <FaqServices faqData={flowerDecorationFAQ} />
       <Description {...descriptionDataFlowerDecoration} />
       <Benefits />
       <TestimonialCarousel />
       <Footer />
    </div>
  )
}

export default FlowerDecoration
