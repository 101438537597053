import React from 'react'

import {  rodent } from '../../assets';

import TestimonialCarousel from '../Testimonials';
import Footer from '../../Components/Footer';
import Benefits from '../Benefits';
import PestControlModal from './PestControlModal';
import FaqServices from '../../Components/FaqServices';
import Description from '../../Components/Description';

const RodentControl = () => {

  const data = {
    headingFirst: "Rodent Pest",
    headingSecond: "Rodent Pest",
    price: "₹2599",
    description: [
      "Thorough inspection for signs of rodent infestation",
      "Identification of entry points for rodents",
      "Placement of traps and bait stations strategically",
      "Extermination of existing rodent population",
      "Sealing off entry points to prevent future infestations",
      "Sanitization of affected areas to eliminate odors and contaminants",
      "Monitoring and follow-up visits to ensure long-term effectiveness",
      "Educating clients on preventive measures against rodent infestations",
      "Safe and environmentally friendly pest control methods",
      "Disposal of rodent carcasses in a hygienic manner",
      "Application of rodent repellents in targeted areas",
    ],
    image: rodent,
    space: "living space",
    size: "house",
    descriptionMeta: "Keep rodents at bay with our effective Rodent Pest Control Service. From mice to rats, we'll exterminate these pests and prevent future invasions. Protect your property—schedule your rodent control service today!",
    
  
  }
  const rodentPestControlFAQ = [
    {
      question: 'How do I know if I have a rodent infestation?',
      answer: "Signs of a rodent infestation include droppings, gnawed items, nests, and unusual noises in walls or ceilings. If you notice these signs, it's advisable to seek professional pest control assistance.",
    },
    {
      question: 'Are rodent control treatments safe for my family and pets?',
      answer: 'Yes, our rodent pest control treatments prioritize safety. We use pet-friendly and child-safe methods, ensuring the well-being of your family and pets during and after the pest control process.',
    },
    {
      question: 'How quickly can I expect results after rodent pest control treatment?',
      answer: 'The time it takes to see results can vary based on the severity of the infestation. In many cases, you may notice a reduction in rodent activity within a few days after the initial treatment.',
    },
    {
      question: 'Do I need to vacate my home during rodent pest control?',
      answer: 'In most cases, it is not necessary to vacate your home during rodent pest control treatments. Our professionals will provide guidance on any specific precautions or temporary measures that may be needed.',
    },
    {
      question: 'Is rodent pest control a one-time service, or do I need regular treatments?',
      answer: 'The need for regular treatments depends on the extent of the infestation and preventive measures taken. Our experts will assess your situation and recommend a treatment plan tailored to your needs.',
    },
    {
      question: 'What are some common entry points for rodents in homes?',
      answer: 'Common entry points for rodents include gaps around doors and windows, cracks in walls, openings in the roof or attic, vents, pipes, and utility lines entering the home.',
    },
    {
      question: 'Can rodent pest control treatments also address outdoor rodent populations?',
      answer: 'While indoor treatments primarily target rodents inside the home, outdoor treatments can help reduce outdoor rodent populations by targeting nesting areas and entry points around the exterior of the property.',
    },
    {
      question: 'What preventive measures can I take to discourage rodent infestations?',
      answer: 'Preventive measures include sealing cracks and gaps, keeping food stored in airtight containers, maintaining cleanliness, trimming vegetation away from the home, and securing outdoor garbage bins.',
    },
    {
      question: 'Do rodents pose health risks to my family?',
      answer: 'Yes, rodents can pose health risks by spreading diseases through their droppings, urine, and saliva. They can also cause property damage by gnawing on wires, insulation, and structural elements.',
    },
    {
      question: 'Can I use DIY methods to control rodent infestations?',
      answer: 'While DIY methods like traps and baits can be effective for small infestations, larger or persistent infestations may require professional pest control assistance to ensure thorough eradication and prevent future problems.',
    },
  ];
  const rodentPestControlDescriptionData = {
    headingDescription_1: "Why Rodent Pest Control Service is Essential",
    paraDescription_1: "Rodents pose a significant threat to both property and health, making professional pest control essential. These pests can cause extensive damage to structures, electrical wiring, and insulation, leading to costly repairs and safety hazards. Moreover, rodents carry diseases and parasites that can be transmitted to humans through contact with contaminated surfaces or inhalation of droppings and urine. Neglecting proper pest control measures can result in infestations that quickly spiral out of control, posing serious health risks to occupants. Professional rodent pest control service plays a crucial role in effectively eliminating rodent infestations, preventing future invasions, and safeguarding your home or business against the detrimental effects of these pests.",
    headingDescription_2: "What We Do in Rodent Pest Control Service",
    paraDescription_2: [
        "Thorough inspection of the property to identify rodent entry points, nesting sites, and conducive conditions.",
        "Implementation of targeted treatment strategies tailored to the specific type of rodents and severity of infestation.",
        "Placement of bait stations, traps, and exclusion devices to capture or deter rodents from accessing indoor areas.",
        "Sealing of entry points and exclusion measures to prevent rodents from re-entering the premises.",
        "Sanitization and cleanup of rodent-infested areas to remove droppings, urine, and nesting materials.",
        "Application of rodent repellents and deterrents to discourage future rodent activity.",
        "Education and recommendations on preventive measures to minimize the risk of rodent infestations.",
        "Follow-up visits and monitoring to ensure the effectiveness of treatment and address any ongoing issues.",
        "Emergency response services available for urgent rodent infestations or outbreaks."
    ]
};

  
  return (
    <div className='main'>
      <PestControlModal {...data} />
      <FaqServices faqData={rodentPestControlFAQ} />
      <Description {...rodentPestControlDescriptionData} />
      <Benefits />
      <TestimonialCarousel />
      <Footer />
    </div>
  )
}

export default RodentControl
